import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-loading-screen',
  templateUrl: './editor-loading-screen.component.html',
  styleUrls: ['./editor-loading-screen.component.scss']
})
export class EditorLoadingScreenComponent implements OnInit {
  @Input() size: string = 'default';
  constructor() { }

  ngOnInit() {
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {

  }
}
