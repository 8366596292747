import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    private permissions: any;

    constructor() {
        // Assuming you have the JSON structure stored in a variable named 'permissionsData'
        this.permissions = environment.permissions?.roles;
    }

    checkPermission(role: string, section: string, topic: string, operation: string): boolean {
        return (
            this.permissions[role] &&
            this.permissions[role][section] &&
            this.permissions[role][section][topic] &&
            this.permissions[role][section][topic].includes(operation)
        );
    }
    getTopicPermission(role: string, section: string, topic: string): string[] {
        if (this.permissions[role] &&
            this.permissions[role][section] &&
            this.permissions[role][section][topic]) {
            return this.permissions[role][section][topic];
        }
        return [];
    }
    getRoleSectionOperationPermissions(role: string, section: string, operation: string): any {
        if (this.permissions[role] && this.permissions[role][section]) {
            let perms = {};
            for (let key of Object.keys(this.permissions[role][section])) {
                perms[key] = this.permissions[role][section][key].includes(operation);
            }
            return perms;
        }
        return undefined;
    }
}