import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent } from '../block-organizer';


@Component({
  selector: 'endWrapper-block',
  templateUrl: './endWrapper-block.component.html',
  styleUrls: ['../tileblocks.component.scss', './endWrapper-block.component.scss']
})
export class EndWrapperBlockComponent implements BlockComponent {
  @Input() block: any;
  @Output() endWrapperView = new EventEmitter<any>();
  constructor() { }

  public onChange(e: any) {
    // e.preventDefault();
    this.endWrapperView.emit({ 'action': 'endWrapperView', block: this.block });
  }
}
