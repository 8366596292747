<div class="ili-panel pain_level_panel">
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field class="inputInside">
        <span matPrefix>* &nbsp;</span>
        <mat-checkbox value="true" [checked]="block.data.mandatory" [(ngModel)]="block.data.mandatory"
          (change)="onChange($event)" title='Field is mandatory'>
          <input matInput type="text" [(ngModel)]="block.data.question" class="form-control" (change)="onChange($event)"
            placeholder="{{'Enter Question here' | translate}}">
        </mat-checkbox>
      </mat-form-field>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="block.data.level"
        (change)="controlChange($event.value)">
        <mat-radio-button value="image">{{'Numeric Rating Scale with Emoticons' | translate}}</mat-radio-button>
        <mat-radio-button value="numeric">{{'Numeric Rating Scale' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex class="row survey_add_alert" *ngIf="block.data.confirmation && block.data.alerts && block.data.popup">
      <button mat-raised-button color="{{block.data.confirmation.length > 0 ? 'success' : 'primary'}}"
        (click)="addConfirm($event)" class="btn btn-info btn-xs survey-confirmation" style="display: block;">
        <mat-icon>check_circle</mat-icon> Add Confirmation
      </button>
      <button mat-raised-button color="{{block.data.alerts.length > 0 ? 'success' : 'primary'}}"
        (click)="addAlert($event)" class="btn btn-ques-alert btn-info btn-xs">
        <mat-icon>notification_important</mat-icon> Add Alert
      </button>
      <button mat-raised-button color="{{block.data.popup.length > 0 ? 'success' : 'primary'}}"
        (click)="addPopup($event)" class="btn btn-popup-alert btn-info btn-xs">
        <mat-icon>web_asset</mat-icon> Pop Up
      </button>
    </div>
  </div>
</div>