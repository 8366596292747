import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent } from '../block-organizer';
import { ConfirmCkeditorEntityDialogComponent } from '../../../modals';
import { ConfirmInputEntityDialogComponent } from '../../../modals';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'painLevel-block',
  templateUrl: './painLevel-block.component.html',
  styleUrls: ['../tileblocks.component.scss', './painLevel-block.component.scss']
})
export class PainLevelComponent implements BlockComponent {
  @Input() block: any;
  @Output() painLevelView = new EventEmitter<any>();
  constructor(public dialog: MatDialog) { }

  public onChange(e: any) {
    this.painLevelView.emit({ 'action': 'painLevelView', block: this.block });
  }
  controlChange(value: any) {
    this.painLevelView.emit({ 'action': 'painLevelView', block: this.block });
  };

  addPopup(e: any) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Pop Up',
        data: JSON.parse(JSON.stringify(this.block.data.popup)),
        arraySize: 11,
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.popup = result;
        this.painLevelView.emit({ 'action': 'painLevelView', block: this.block });
      }
    });
  };

  addAlert(e: any) {
    const dialogRef = this.dialog.open(ConfirmInputEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Alert',
        data: JSON.parse(JSON.stringify(this.block.data.alerts)),
        placeholder: 'Type email address to receive an alert when corresponding option is selected',
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
        arraySize: 11,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.alerts = result;
        this.painLevelView.emit({ 'action': 'painLevelView', block: this.block });
      }
    });
  };
  addConfirm(e: any) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Confirmation',
        data: JSON.parse(JSON.stringify(this.block.data.confirmation)),
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
        arraySize: 11,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.confirmation = result;
        this.painLevelView.emit({ 'action': 'painLevelView', block: this.block });
      }
    });
  };
  addWeight(e: any) {
    const dialogRef = this.dialog.open(ConfirmInputEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Weights',
        data: JSON.parse(JSON.stringify(this.block.data.weight)),
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
        arraySize: 11,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.weight = result;
        this.painLevelView.emit({ 'action': 'painLevelView', block: this.block });
      }
    });
  };
}
