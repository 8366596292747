<div class='ili-panel survey_panel'>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <p class='text_header_content'>This widget allows you to add one question, with one or more possible replies, with
        the choice of showing results in the app in real time. It can be used for Polls, Surveys, Votes, Requests to
        volunteer, etc.</p>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field class="inputInside">
        <!-- <span matPrefix>* &nbsp;</span> -->
        <mat-checkbox value="true" [checked]="block.data.mandatory" [(ngModel)]="block.data.mandatory"
          (change)="onChange($event)" title='Field is mandatory'>
          <input matInput type="text" [(ngModel)]="block.data.questionText" class="form-control"
            (change)="onChange($event)" placeholder="{{'Type question or text here' | translate}}">
        </mat-checkbox>

        <button mat-icon-button
          [class.containsData]="block.data.flag && block.data.flag.character && block.data.flag.character.length > 0"
          matSuffix (click)="editFlag($event)" [attr.aria-label]="'Flag'" title="{{'Flag' | translate}}">
          <mat-icon>flag</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="decription-survey">User can select</div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="block.data.multiple"
        (change)="controlChange($event.value)">
        <mat-radio-button value="false">{{'One Answer' | translate}}</mat-radio-button>
        <mat-radio-button value="true">{{'Multiple Answers' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div fxFlex *ngIf="block.data.multiple === 'false'">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="block.data.controls"
        (change)="controlChange($event.value)">
        <mat-radio-button value="radio">Radio Button</mat-radio-button>
        <mat-radio-button value="dropdown">Dropdown</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-checkbox value="true" [checked]="block.data.showInApp" [(ngModel)]="block.data.showInApp"
        (change)="onChange($event)">Show results in app</mat-checkbox>
    </div>
    <!-- <div fxFlex>
      <mat-checkbox value="true" [checked]="block.data.isNote" [(ngModel)]="block.data.isNote"
        (change)="onChange($event)">Take Notes</mat-checkbox>
    </div> -->
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      In app possible replies:
    </div>
    <div fxFlex>
      <button mat-raised-button color="primary" (click)="addOption($event)" class="btn-info btn-xs btn-option-box">Add
        Option</button>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field class="example-full-width"
        *ngFor="let opt of block.data.questions; let i = index; trackBy:trackByIndex">
        <span matPrefix>{{i + 1}}.</span>
        <input matInput [(ngModel)]="block.data.questions[i]" placeholder="{{'Type option here' | translate}}"
          (change)="onChange($event)">
        <button mat-icon-button matSuffix
          [class.containsData]="block.data.confirmation[i] && block.data.confirmation[i].length > 0"
          *ngIf="block.data.confirmation.length > 0" (click)="editConfirm($event, i)"
          [attr.aria-label]="'Add Confirmation'">
          <mat-icon>check_circle</mat-icon>
        </button>
        <button mat-icon-button matSuffix [class.containsData]="block.data.alerts[i] && block.data.alerts[i].length > 0"
          *ngIf="block.data.alerts.length > 0" (click)="editAlert($event, i)" [attr.aria-label]="'Add Alert'">
          <mat-icon>notification_important</mat-icon>
        </button>
        <button mat-icon-button matSuffix [class.containsData]="block.data.popup[i] && block.data.popup[i].length > 0"
          *ngIf="block.data.popup.length > 0" (click)="editPopup($event, i)" [attr.aria-label]="'Pop Up'">
          <mat-icon>web_asset</mat-icon>
        </button>
        <button mat-icon-button matSuffix (click)="removeOption($event, i)" [attr.aria-label]="'Remove option'">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex class="row survey_add_alert">
      <button mat-raised-button color="{{block.data.confirmation.length > 0 ? 'success' : 'primary'}}"
        (click)="addConfirm($event, 0, block.data.confirmation.length > 0)"
        class="btn btn-info btn-xs survey-confirmation" style="display: block;">
        <mat-icon>check_circle</mat-icon> Add Confirmation
      </button>
      <button mat-raised-button color="{{block.data.alerts.length > 0 ? 'success' : 'primary'}}"
        (click)="addAlert($event, 0, block.data.alerts.length > 0)" class="btn btn-ques-alert btn-info btn-xs">
        <mat-icon>notification_important</mat-icon> Add Alert
      </button>
      <button mat-raised-button color="{{block.data.popup.length > 0 ? 'success' : 'primary'}}"
        (click)="addPopup($event, 0, block.data.popup.length > 0)" class="btn btn-popup-alert btn-info btn-xs">
        <mat-icon>web_asset</mat-icon> Pop Up
      </button>
    </div>
  </div>
</div>