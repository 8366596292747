import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RequestService } from '../shared/services/request.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private requestService: RequestService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const requiredRole = route.data['role'];
        const user = this.requestService.currentUserSubject.getValue();
        let currentUserRole = this.requestService.getRoleByUserData(user);
        if (user) {
            if ((requiredRole && requiredRole.includes(currentUserRole)) || !requiredRole)
                return true;
            else {
                this.router.navigate(['/']);
                return false;
            }
        }

        this.router.navigate(['auth/login']);
        return false;
    }
}
