// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { FormLayoutUtilsService } from '../../services/form-layout-utils.service';

import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'kt-form-confirm-input-entity-dialog',
	templateUrl: './confirm-input-entity-dialog.component.html',
	styleUrls: ['../modal.scss']
})
export class ConfirmInputEntityDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	arraySize = 1;
	constructor(private translate: TranslateService, private layoutUtilsService: FormLayoutUtilsService,
		public dialogRef: MatDialogRef<ConfirmInputEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (data.hasOwnProperty('arraySize')) {
			this.arraySize = data.arraySize || 1;
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		if (this.data.hasOwnProperty('validation')) {
			if (this.data.validation.nullable === false) {
				if (this.data.data === '' || this.data.data === undefined) {
					this.layoutUtilsService.showNotification(this.translate.instant('You need to fill in the ' + this.data.placeholder + ' field'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (this.data.validation.regex) {
				if (!this.isRegexValid(this.data.validation.regex, this.data.data)) {
					this.layoutUtilsService.showNotification(this.data.validation.regexMessage, this.translate.instant('Dismiss'));
					return;
				}
			}
			else if (this.data.validation.range) {
				if (!(this.data.data >= this.data.validation.range.min && this.data.data <= this.data.validation.range.max && this.data.data % 1 === 0)) {
					this.layoutUtilsService.showNotification(this.data.validation.range.errorMessage, this.translate.instant('Dismiss'));
					return;
				}
			}
		}
		this.dialogRef.close(this.data.data);
	}
	private isRegexValid(regex, data) {
		var re = new RegExp(regex);
		return re.test(String(data).toLowerCase());
	}
	public onChange(data) {
		this.data.data = data;
	}
	getNumberRange(end: number): number[] {
		return Array.from({ length: end }, (_, index) => index + 1);
	}
}
