<h1 mat-dialog-title *ngIf="data.title">
	{{ data.title }}
</h1>
<div mat-dialog-content id="container">
	<p *ngIf="!data.isHtml">{{ data.description }}</p>
	<div *ngIf="data.isHtml" [innerHTML]="data.description | safeHtml"></div>
</div>
<div mat-dialog-actions>
	<button mat-button class="primary" (click)="onNoClick()">
		{{'OK' | translate}}
	</button>
</div>