<!-- <select id="{{dataType}}" class="form-control" [disabled]="loading" (change)="setAttribute($event.target.value)">
  <option value="" [selected]="value === undefined">{{placeholder | translate}}</option>
  <option value="{{item._id}}" *ngFor="let item of selectData" [selected]="value === item._id">{{item.name}}</option>
</select> -->
<form #dataForm="ngForm" (ngSubmit)="onSubmit()" >
  <div *ngIf="!hideBecauseOne">
    <div *ngIf="apiTarget !== 'search'">
      <mat-form-field >
        <input [style.visibility]="'hidden'" matInput name="{{itemName}}" [(ngModel)]="value" [required]="required" [errorStateMatcher]="esMatcher" >
        <mat-label>{{placeholder | translate}}</mat-label>
        <mat-select ng-model="itemName" [required]="required" (selectionChange)="setAttribute($event)" [value]="value" multiple [disabled]="conditionalDisable">
          <mat-option *ngFor="let itm of selectData" [value]="itm._id">
            <span *ngFor="let itmDn of displayName">{{itm[itmDn]}} </span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="required && (value === undefined || value.length === 0)">
          {{placeholder | translate}} is required.
        </mat-error>
      </mat-form-field>
      <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
    <div *ngIf="apiTarget === 'search'" class="searchClassMultiple">
      <!-- <app-custom-select [clearable]="true" [value]="value" [itemName]="itemName"  [dataType]="dataType" [apiTarget]="apiTarget" [placeholder]="placeholder" (onSelectReturn)="setAttribute($event)"></app-custom-select> -->
      <ng-select class="custom" ng-model="itemName" name="{{itemName}}"
                 [items]="filteredData | async"
                 bindLabel="text"
                 autofocus
                 bindValue="_id"
                 [multiple]="true"
                 [loading]="loading"
                 [clearable]="clearable"
                 (search)="termSearch($event)"
                 (clear)="termSearch('')"
                 (change)="setAttribute($event)"
                 [(ngModel)]="value"
                 [disabled]="conditionalDisable"
                 placeholder="{{placeholder | translate}}">
      </ng-select>
      <!-- <div class="my-error mat-error mat-form-field-subscript-wrapper" *ngIf="required && (value === undefined || value.length === 0)">
        {{placeholder | translate}} is required.
      </div> -->
    </div>
  </div>
</form>
