import { Component, Inject, NgZone, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from "@ngx-translate/core";
import { FormLayoutUtilsService } from '../../services';

interface ViewDialogData {
	data: any;
	title: string;
	confirmbtn: string;
	cancelbtn: string;
	isAdmin: boolean;
	isSuperAdmin: any;
}

@Component({
	selector: 'app-question-flag-dialog-modal',
	templateUrl: './custom-question-flag-dialog.component.html',
	styleUrls: ['./custom-question-flag-dialog.component.scss']
})
export class ModalQuestionFlagDialogComponent {
	public errorMessage: string = '';
	public loading: boolean = false;
	public selectedData: any = { character: '', tooltip: '' };

	constructor(private layoutUtilsService: FormLayoutUtilsService,
		public dialogRef: MatDialogRef<ModalQuestionFlagDialogComponent>,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		console.log('UserViewDialogData', data);
		let returningData = this.data.data || { character: '', tooltip: '' }
		if (!returningData.hasOwnProperty('character')) {
			returningData = { character: '', tooltip: '' };
		}
		this.selectedData = JSON.parse(JSON.stringify(returningData));
	}
	ngOnInit() {
	}
	ngOnDestroy() {
	}
	closeModal(data): void {
		this.dialogRef.close(undefined);
	}
	submitData(): void {
		if (this.selectedData.tooltip.length > 0 && this.selectedData.character.length === 0) {
			this.layoutUtilsService.showNotification(this.translate.instant('You should fill the character field to continue'), this.translate.instant('Dismiss'));
			return;
		}
		this.dialogRef.close(this.selectedData);
	}
}
