import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from './shared/services/request.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService } from './shared/services/layout-utils.service';
import { FirebaseHandlersService } from './shared/services/firebase-handlers.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertEntityDialogComponent } from './shared/components';
import { NotificationService } from './shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: any[] = <any>[];
  private dataSeenOnce: boolean = false;

  protected activityDialog: any = undefined;
  protected idleState = 'Not started.';
  protected aliveAppIntervalMinutes: number = environment.aliveAppIntervalMinutes;
  protected aliveCheckAppIntervalMinutes: number = environment.aliveCheckAppIntervalMinutes;

  constructor(private requestService: RequestService, protected idle: Idle, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private firebaseService: FirebaseHandlersService, private dialog: MatDialog, private notificationService: NotificationService) {
    this.getMe();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.authenticatedUser.subscribe((event: boolean) => {
        if (event) {
          let rememberMe = false;
          if (localStorage.getItem('rememberMe')) {
            rememberMe = JSON.parse(localStorage.getItem('rememberMe') || '');
          }
          if (!rememberMe) {
            this.resetActivity();
          } else {
            this.idle.stop();
            console.log('Idle Activity Cancelled');
          }
          // console.log('authenticatedUser' ,event);
          // console.log('this.dataSeenOnce' ,this.dataSeenOnce);
          if (!this.dataSeenOnce) {
            this.getMe();
          }
        } else {
          this.idle.stop();
        }
      }
      ));

    this.subscriptions.push(
      this.requestService.appStatusSubject.subscribe((data: any) => {
        if (data) {
          if (data === 'login') {
            this.firebaseService.logout(true);
          }
        }
      }));

    this.subscribeActivity();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  resetActivity() {
    this.idle.watch();
    this.idleState = 'Running.';
    console.log('Idle Activity', this.idleState);
  }

  subscribeActivity() {
    console.log('Idle Activity initialized');
    this.idle.setIdle(this.aliveCheckAppIntervalMinutes * 60);
    this.idle.setTimeout(this.aliveAppIntervalMinutes * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.subscriptions.push(
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.'
        console.log(this.idleState);
        this.resetActivity();
      })
    );
    this.subscriptions.push(
      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        console.log(this.idleState);
        this.activityDialog?.close();
        this.firebaseService.logout();
        // this.requestService.logOutApi();
        // this.router.navigate(['/login']);

      })
    );
    this.subscriptions.push(
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
        this.noActivity(this.translate.instant('No Activity'), this.translate.instant('There was no activity for over') + ' ' + this.aliveCheckAppIntervalMinutes + ' ' + this.translate.instant('minutes. Would you like to keep the session active?'));
      })
    );
    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown: any) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        // console.log(this.idleState);
      })
    );
  }

  noActivity(title: string, msg: string) {
    if (!this.activityDialog) {
      let alertSetting: any = {};
      alertSetting['overlayClickToClose'] = false;
      alertSetting['showCloseButton'] = false;
      alertSetting['confirmText'] = this.translate.instant('Yes');
      alertSetting['declineText'] = this.translate.instant('No');
      // alertSetting['timerEvent'] = 120;

      this.activityDialog = this.layoutUtilsService.alertActionElement(title, msg, alertSetting);
      this.activityDialog.afterClosed().subscribe((res: any) => {
        if (res) {
          // if(res.action === 'declineText'){
          //   // this.subscribeActivity(); // do nothing
          // }else
          if (res.action === 'confirmText') {
            this.activityDialog.close();
            this.resetActivity();
          } else {
            // this.requestService.logOutApi();
            this.firebaseService.logout();
          }
          this.activityDialog = undefined;
        }
      });
    }
  }

  public getMe() {
    if (!localStorage.getItem('uid')) {
      localStorage.setItem('uid', JSON.stringify(Date.now()));
    }
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('a') && localStorage.getItem('l')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
      let orgId = JSON.parse(localStorage.getItem('o') || '');
      let appId = JSON.parse(localStorage.getItem('a') || '');
      let locId = JSON.parse(localStorage.getItem('l') || '');
      let orgData = JSON.parse(localStorage.getItem('org') || '');
      this.requestService.orgId = orgId;
      this.requestService.appId = appId;
      this.requestService.orgName = orgData.name;
      this.requestService.locId = locId;
      // if (orgData.hasOwnProperty('name')) {
      //   this.title.setTitle('Stellar - ' + orgData.name);
      // }
      this.requestService.pageOrganization.next(orgData);
      this.refreshOrg(orgId);
      this.requestService.currentUser = currentUser;
      let resource = this.requestService.getItemFromListContains(currentUser.resources, orgId, 'organizationId');
      if (resource) {
        this.dataSeenOnce = true;
        this.validateMeByApi();

        if (!currentUser.dnd) {
          if (Notification.permission === "granted") {
            this.firebaseService.registerDevice(currentUser._id);
          }
          else {
            const dialog = this.dialog.open(AlertEntityDialogComponent, {
              data: {
                description: this.translate.instant(
                  'Notifications were blocked by the browser. Enable to receive notifications.'
                ),
                title: '',
              },
              width: 'fit-content',
            });
            dialog.afterClosed().subscribe((result) => {
              // if (result) {
              Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                  // console.log('Notifica÷tion permission granted.');
                  this.firebaseService.registerDevice(currentUser._id);
                }
                // else {
                //   this.layoutUtilsService.showNotification(this.translate.instant('Notifications were blocked by the browser. Enable to receive notifications.'), 'Dismiss');
                // }
              });
              // }
              // else {
              //   this.layoutUtilsService.showNotification(this.translate.instant('Notifications were blocked by the browser. Enable to receive notifications.'), 'Dismiss');
              // }
            });
          }
        }
      }
    }
    else {
      this.dataSeenOnce = false;
    }
  }

  public validateMeByApi() {
    this.requestService.getMe((data, error) => {
      if (error) {
        // this.requestService.logout();
        //console.log(error);
      }
      if (data) {
        sessionStorage.setItem('live', JSON.stringify(true));
        this.notificationService.dnd.next(data.dnd);
        this.requestService.authenticatedUser.next(true);
        if (localStorage.getItem('v')) {
          let versionData = JSON.parse(localStorage.getItem('v') || '');
          if (this.requestService.version !== versionData) {
            this.requestService.newAppVersion.next(true);
          }
        }
        // valid
      } else {
        this.dataSeenOnce = false;
        // this.requestService.logout();
      }
    });
  }

  public refreshOrg(orgId: any) {
    this.requestService.getSingleDataOriginal('organization', this.requestService.orgId, (data: any, error: any) => {
      if (error) {
        // do nothing
      }
      if (data && data.results) {
        let orgData = data.results;
        // if (orgData.hasOwnProperty('name')) {
        //   this.title.setTitle('Stellar - ' + orgData.name);
        // }
        localStorage.setItem('org', JSON.stringify(orgData));
        this.requestService.pageOrganization.next(orgData);
        // this.featuresPermissionService.setUpOrg(orgData);
      }
    });
  }
}
