import { Component, Inject, NgZone, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomTileViewComponent } from '../custom-tile-view/custom-tile-view.component';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { FormRequestService } from '../../services';

interface ViewDialogData {
	title: string;
	data: any;
	readonly: boolean;
	isAdmin: boolean;
	isSuperAdmin: any;
}

@Component({
	selector: 'app-tile-dialog-modal',
	templateUrl: './custom-tile-dialog.component.html',
	styleUrls: ['./custom-tile-dialog.component.scss']
})
export class ModalTileDialogComponent {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public title: string = '';
	public readonly: boolean = true;
	public loading: boolean = false;

	@ViewChild('customtileview') customtileview: CustomTileViewComponent;
	constructor(private zone: NgZone,
		private formRequestService: FormRequestService,
		public dialogRef: MatDialogRef<ModalTileDialogComponent>,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('UserViewDialogData', data);
		this.title = this.data.title;
		this.readonly = this.data.readonly;
	}
	ngOnInit() {
		this.subscriptions.push(
			this.formRequestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
				}
			})
		);
	}
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	setAction(data): void {
		if (data.action === 'hideSubmit') {
			this.readonly = true;
		}
	}
	setTitle(data): void {
		this.title = data;
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	submitData(): void {
		this.customtileview.submitData();
	}
}
