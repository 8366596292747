<mat-form-field class="example-full-width">
  <input #inputName [(ngModel)]="dataText" matInput type="text" placeholder="{{placeholder}}" aria-label="Number"
    [matAutocomplete]="auto" (input)="termSearch($event.target.value)" style="height: 0gitpx;"
    (focusout)="onBlurMethod($event.target.value)">
  <mat-autocomplete class="autocomplete" #auto="matAutocomplete" [displayWith]="displayFn"
    (optionSelected)="setAttribute($event, $event.option.value)">
    <mat-option *ngFor="let option of filteredData | async" [value]="option" title="{{option.text}}"
      class="autocomplete-option">{{option.text}} <i *ngIf="canDelete" class="material-icons deleteItem"
        (click)="deleteItem($event, option)">cancel</i>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>