<mat-card *ngIf="!showRaw" class="example-card" [class.invisible]="iframeLink=== undefined || iframeLink === ''"
	style="overflow-y: auto;" [style.height]="_iframeParentHeight" [style.padding]="iframePadding">
	<mat-card-content>
		<div class="fieldItem" [style.height]="iframeHeight" [style.min-height]="_iframeParentHeight">
			<span *ngIf="loading">{{'Loading...' | translate}}</span>
			<iframe id="iframeMain" #iframeMain width="100%" frameBorder="0" [src]="iframe_html" (load)="onload($event)"
				[height]="_iframeParentHeight" [class.invisible]="loading"></iframe>
		</div>
	</mat-card-content>
</mat-card>
<div class="fieldItem" *ngIf="showRaw" [style.height]="iframeHeight" [style.min-height]="iframeMinHeight"
	[style.min-height]="_iframeParentHeight">
	<span *ngIf="loading">{{'Loading...' | translate}}</span>
	<iframe id="iframeMain" #iframeMain width="100%" frameBorder="0" [src]="iframe_html" (load)="onload($event)"
		[height]="_iframeParentHeight" [class.invisible]="loading"></iframe>
</div>