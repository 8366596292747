import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { LimitToPipe } from './pipes/limit-to.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import {
  FromNowPipe,
  CapitalizePipe,
  FirstLetterPipe,
  SafePipe,
  TruncatePipe,
  CountDown,
  CountDownInSec,
} from './pipes';
import {
  UpdateStatusDialogComponent,
  FetchEntityDialogComponent,
  DeleteEntityDialogComponent,
  ErrorEntityDialogComponent,
  AlertEntityDialogComponent,
  AlertActionEntityDialogComponent,
  ActionNotificationComponent,
  ConfirmSelectEntityDialogComponent,
  ModalPasswordDialogComponent,
  ConfirmInputEntityDialogComponent,
} from './components/modals';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { ConfirmFileEntityDialogComponent } from './components/modals/confirm-file-entity-dialog/confirm-file-entity-dialog.component';
import { EditProfileDialogComponent } from './components/modals/edit-profile-dialog/edit-profile-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MaterialModule } from './material.module';
import { LayoutComponent } from './layout/layout.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ConfirmDialogComponent } from './components/modals/confirm-dialog/confirm-dialog.component';
import { EnumPipe } from './pipes/enum.pipe';
import { ModalUploaderDialogComponent } from './components/modals/custom-uploader-dialog/custom-uploader-dialog.component';
import { AddGenericNoteDialogComponent } from './components/modals/add-generic-note-modal/add-generic-note-modal.component';
import { RiskLogsDialogComponent } from '../features/risks-strengths/risk-logs-modal/risk-logs-modal.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { ModalViewDialogComponent, EnumViewComponent, CustomMetadataTableComponent, CustomMultipleSelectTreeComponent, CustomSelectDialogComponent, CustomSelectComponent, CustomSelectAutocompleteComponent, ModalDialogComponent, CustomMultipleSelectComponent, CustomTagsComponent, CustomDateTimeComponent, RulesViewDialogComponent, EvaluationViewDialogComponent } from './components';
import { ClickOutSideDirective } from './directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { VisibilityDirective } from './modules/forms-libraries/directives';
@NgModule({
  imports: [
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    TranslateModule,
    FlexLayoutModule.withConfig({ addFlexToParent: false }),
    NgSelectModule
  ],
  declarations: [
    LimitToPipe,
    FromNowPipe,
    CapitalizePipe,
    FirstLetterPipe,
    SafePipe,
    TruncatePipe,
    CountDown,
    CountDownInSec,
    EnumPipe,
    LocalDatePipe,
    YesNoPipe,
    SafeHtmlPipe,
    UpdateStatusDialogComponent,
    FetchEntityDialogComponent,
    DeleteEntityDialogComponent,
    ErrorEntityDialogComponent,
    AlertEntityDialogComponent,
    AlertActionEntityDialogComponent,
    ActionNotificationComponent,
    ConfirmSelectEntityDialogComponent,
    ModalPasswordDialogComponent,
    ConfirmInputEntityDialogComponent,
    LoadingScreenComponent,
    ConfirmFileEntityDialogComponent,
    EditProfileDialogComponent,
    LayoutComponent,
    ConfirmDialogComponent,
    ModalUploaderDialogComponent,
    AddGenericNoteDialogComponent,
    RiskLogsDialogComponent,
    ModalViewDialogComponent,
    EnumViewComponent,
    CustomMetadataTableComponent,
    CustomMultipleSelectTreeComponent,
    CustomSelectComponent,
    CustomSelectDialogComponent,
    ClickOutSideDirective,
    CustomSelectAutocompleteComponent,
    ModalDialogComponent,
    CustomMultipleSelectComponent,
    CustomTagsComponent,
    CustomDateTimeComponent,
    RulesViewDialogComponent,
    EvaluationViewDialogComponent,
    VisibilityDirective
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LimitToPipe,
    EnumPipe,
    FromNowPipe,
    CapitalizePipe,
    FirstLetterPipe,
    SafePipe,
    TruncatePipe,
    CountDown,
    CountDownInSec,
    LocalDatePipe,
    YesNoPipe,
    SafeHtmlPipe,
    UpdateStatusDialogComponent,
    FetchEntityDialogComponent,
    DeleteEntityDialogComponent,
    ErrorEntityDialogComponent,
    AlertEntityDialogComponent,
    AlertActionEntityDialogComponent,
    ActionNotificationComponent,
    ConfirmSelectEntityDialogComponent,
    ModalPasswordDialogComponent,
    ConfirmInputEntityDialogComponent,
    LoadingScreenComponent,
    ConfirmFileEntityDialogComponent,
    MatRadioModule,
    FlexLayoutModule,
    ConfirmDialogComponent,
    ModalUploaderDialogComponent,
    AddGenericNoteDialogComponent,
    RiskLogsDialogComponent,
    ModalViewDialogComponent,
    EnumViewComponent,
    CustomMetadataTableComponent,
    CustomMultipleSelectTreeComponent,
    CustomSelectComponent,
    CustomSelectDialogComponent,
    ClickOutSideDirective,
    CustomSelectAutocompleteComponent,
    ModalDialogComponent,
    CustomMultipleSelectComponent,
    CustomTagsComponent,
    CustomDateTimeComponent,
    RulesViewDialogComponent,
    EvaluationViewDialogComponent,
    VisibilityDirective
  ],
})
export class SharedModule { }
