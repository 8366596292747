<div class="col-xl-12 tilePopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
		</div>
	</div>
	<div mat-dialog-content class="dialog-content">
		<mat-form-field>
			<!-- <mat-label>{{ "Character" | translate }}</mat-label> -->
			<span matPrefix *ngIf="selectedData.tooltip && selectedData.tooltip !== ''">* &nbsp;</span>
			<input matInput type="text" maxlength="5" [(ngModel)]="selectedData.character" class="form-control"
				placeholder="{{'Character' | translate}}">
		</mat-form-field>
		<mat-form-field>
			<!-- <mat-label>{{ "Tooltip" | translate }}</mat-label> -->
			<!-- <span matPrefix>* &nbsp;</span> -->
			<input matInput type="text" [(ngModel)]="selectedData.tooltip" class="form-control"
				placeholder="{{'Tooltip' | translate}}">
		</mat-form-field>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{data.cancelbtn}}</button>
			<button mat-raised-button (click)="submitData()">{{data.confirmbtn}}</button>
		</div>
	</div>
	<br />
</div>