import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { RequestService } from '../request.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private requestService: RequestService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = this.requestService.currentUserSubject.getValue();

    if (user && user.token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', user.token),
      });

      return next.handle(cloned).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.dialog.closeAll();
                this.router.navigate(['/auth/login']);
              }
            }
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
