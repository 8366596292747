import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RisksService {

  riskLevelSubject: BehaviorSubject<String> = new BehaviorSubject<String>("");

  constructor(
    private requestService: RequestService,
  ) { }

  getRiskLogs(assessmentId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postFormRequest({
        page: 1,
        term: undefined,
        orderDir: 'desc',
        orderBy: 'createdAt',
        fields: ['note', 'createdBy', 'createdAt', 'risk', 'submissionId', 'formId', 'createdName'],
        filter: {
          $and: [
            { submissionId: { $eq: assessmentId } },
          ],
        },
      }, 'risklogs/search', (data, error) => {
        if (data)
          resolve(data.results);
        else if (error)
          reject(error);
      });
    });
  }

  saveLog(risk: string, note: string, submissionId: string, formId: string, clientId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postFormRequest({
        risk: risk,
        note: note,
        submissionId: submissionId,
        userId: clientId,
        formId: formId
      }, 'risklogs', (data, error) => {
        if (data)
          resolve(data.results);
        else if (error)
          reject(error);
      });
    });
  }

  getRiskLogsMetadata(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.getFormRecord(
        '',
        'risklogs/metadata',
        (data, error) => {
          if (data) resolve(data.results.fields);
          if (error) reject(error);
        }
      );
    });
  }

  // getLatestAssessment(clientId: string): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     this.requestService.postFormRequest(
  //       {
  //         page: 1,
  //         count: 1,
  //         term: undefined,
  //         orderDir: 'desc',
  //         orderBy: 'submission_date',
  //         fields: ['status', 'risk', 'formId', 'total_score', 'total_strength_scale', 'total_risk_scale'],
  //         filter: {
  //           $and: [
  //             { 'organizationId._id': { $eq: this.requestService.orgId } },
  //             { clientId: { $eq: clientId } },
  //           ],
  //         },
  //       },
  //       'assessments/search',
  //       (data, error) => {
  //         if (data) {
  //           if (data.results.length)
  //             resolve(data.results[0]);
  //           else
  //             resolve(data.results);
  //         } else if (error) {
  //           reject(error);
  //         }
  //       }
  //     );
  //   });
  // }
}