<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{data.description}}
				</div>
			</div>
			<div class="form-group kt-form__group row" style="overflow-y: auto;
			max-height: 50vh;">
				<ng-container *ngIf="arraySize == 1">
					<div class="form-group kt-form__group row">
						<div class="col-lg-12">
							<mat-form-field class="example-full-width">
								<input matInput *ngIf="data.type && data.type === 'number'" type="number"
									[min]="data.min" [max]="data.max" [(ngModel)]="data.data"
									placeholder="{{data.placeholder}}">
								<input matInput *ngIf="!(data.type && data.type === 'number')" [(ngModel)]="data.data"
									placeholder="{{data.placeholder}}">
							</mat-form-field>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="arraySize > 1">
					<div class="form-group kt-form__group row" *ngFor="let number of getNumberRange(arraySize)">
						<div class="col-lg-2">
							<span matPrefix>{{number -1}}.</span>
						</div>
						<div class="col-lg-10">
							<mat-form-field class="example-full-width">
								<input matInput *ngIf="data.type && data.type === 'number'" type="number"
									[min]="data.min" [max]="data.max" [(ngModel)]="data.data[number-1]"
									placeholder="{{data.placeholder}}">
								<input matInput *ngIf="!(data.type && data.type === 'number')"
									[(ngModel)]="data.data[number-1]" placeholder="{{data.placeholder}}">
							</mat-form-field>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br />
				<button mat-raised-button (click)="onNoClick()" color="danger">{{data.cancelbtn}}</button>&nbsp;
				<button mat-raised-button (click)="onYesClick()" cdkFocusInitial>{{data.confirmbtn}}</button>
			</div>
		</div>
	</div>
</div>