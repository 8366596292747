<h1 mat-dialog-title style="line-height: 10px;">
	<div *ngIf="data.alertSetting.hasOwnProperty('titleIsTemplate') && data.alertSetting.titleIsTemplate">
		<div [innerHTML]="data.title"></div>
	</div>
	<div
		*ngIf="!data.alertSetting.hasOwnProperty('titleIsTemplate') || data.alertSetting.hasOwnProperty('titleIsTemplate') && !data.alertSetting.titleIsTemplate">
		<mat-icon *ngIf="data.alertSetting.icon">{{data.alertSetting.icon}}</mat-icon>
		<span [class.title-with-icon]="data.alertSetting.icon">{{data.title}}</span>
	</div>
</h1>

<div mat-dialog-content>
	<div *ngIf="data.alertSetting.hasOwnProperty('messageIsTemplate') && data.alertSetting.messageIsTemplate">
		<div [innerHTML]="data.description"></div>
	</div>
	<div *ngIf="!data.alertSetting.hasOwnProperty('messageIsTemplate') || data.alertSetting.hasOwnProperty('messageIsTemplate') && !data.alertSetting.messageIsTemplate"
		style="line-height: 25px;">
		{{data.description}}
	</div>
</div>

<div mat-dialog-actions>
	<button *ngIf="data.alertSetting.hasOwnProperty('btnText1')" mat-button
		(click)="onYesClick('btnText1', data.alertSetting.btnText1)"
		style="float:right; margin: 2px 2px;">{{data.alertSetting.btnText1}}</button>&nbsp;
	<button *ngIf="data.alertSetting.hasOwnProperty('btnText2')" mat-button
		(click)="onYesClick('btnText2', data.alertSetting.btnText2)"
		style="float:right; margin: 2px 2px;">{{data.alertSetting.btnText2}}</button>&nbsp;
	<button *ngIf="data.alertSetting.hasOwnProperty('btnText3')" mat-button
		(click)="onYesClick('btnText3', data.alertSetting.btnText3)"
		style="float:right; margin: 2px 2px;">{{data.alertSetting.btnText3}}</button>&nbsp;

	<button *ngIf="data.alertSetting.hasOwnProperty('confirmText')" mat-button [color]="data.alertSetting?.buttonColor"
		(click)="onYesClick('confirmText', data.alertSetting.confirmText)"
		style="float:right; margin: 2px 2px;">{{data.alertSetting.confirmText}}</button>&nbsp;
	<button *ngIf="data.alertSetting.hasOwnProperty('declineText')" mat-button
		(click)="onYesClick('declineText', data.alertSetting.declineText)"
		style="float:right; margin: 2px 2px;">{{data.alertSetting.declineText}}</button>&nbsp;
	<button *ngIf="data.alertSetting.showCloseButton" mat-button (click)="onNoClick()"
		style="float:right; margin: 2px 2px;">Close</button>
	<button *ngIf="data.alertSetting.showCancelButton" mat-button (click)="onNoClick()"
		style="float:right; margin: 2px 2px;">{{'Close' | translate }}</button>
</div>