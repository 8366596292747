<div class='ili-panel cc_ques_panel'>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <p class='text_header_content'>{{'This widget allows you to add one or multiple questions, each with a text box
        with answers. It can be used for collecting contact info, feedback, etc.' | translate}}</p>
    </div>
    <div fxFlex>
      <app-custom-select-create-autocomplete [dataType]="'category'" [dataTypeDisplay]="'Category'"
        [placeholder]="'Select / Add Category'" [useOrg]="true" [canDelete]="false"
        (onSelectReturn)="onSelectCat($event)" [type]="'tileblock'" [onBlur]="true" [value]="block.data.category">
      </app-custom-select-create-autocomplete>
    </div>
  </div>

  <div class="cc-questions-block" *ngFor="let subOption of block.data.mandatory; let index = index">
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex="75">
        <mat-form-field class="inputInside">
          <!-- <span matPrefix>* &nbsp;</span> -->
          <mat-checkbox value="true" [checked]="block.data.mandatory[index]" [(ngModel)]="block.data.mandatory[index]"
            (change)="onChange($event)" title='Field is mandatory'>
            <input matInput type="text" [(ngModel)]="block.data.questions[index]" class="form-control"
              (change)="onChange($event)" placeholder="{{'Type question here' | translate}}">
          </mat-checkbox>
        </mat-form-field>
      </div>
      <div fxFlex="25">
        <button (click)="addQuestions($event)" *ngIf="index===0" class="btn btn-info btn-xs cc-btn-add-text-box">{{'+
          Text Box' | translate}}</button>
      </div>
    </div>
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <mat-form-field style="width: 100%;">
          <input [style.display]="'none'" matInput name="answertype" [(ngModel)]="block.data.answerTypes[index]">
          <mat-label>{{'Answer Type' | translate}}</mat-label>
          <mat-select ng-model="answertype" (selectionChange)="block.data.answerTypes[index] = $event.value"
            [value]="block.data.answerTypes[index]">
            <mat-option *ngFor="let opt of answerTypes" value="{{opt.value}}">{{opt.text}}</mat-option>
          </mat-select>
          <!-- <button mat-icon-button matSuffix (click)="deleteSubLevel($event)" [attr.aria-label]="'Remove option'">
              <mat-icon>close</mat-icon>
            </button> -->
        </mat-form-field>
      </div>
      <!-- <div fxFlex="25">
        <mat-checkbox value="true" [checked]="block.data.notes[index]" [(ngModel)]="block.data.notes[index]"
          (change)="onChange($event)">{{'Take Notes' | translate}}</mat-checkbox>
      </div> -->
    </div>
  </div>
</div>