<div class="row cc-options-sub-row cc_desc_opt">
  <!-- <span class="cc-sub-question-no">{{getLevelIndex()}}.{{getAlphaLetter(currentIndex)}}</span> -->
  <mat-form-field>
    <span matPrefix fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
      <span fxFlex>{{getLevelIndex()}}.{{getAlphaLetter(currentIndex)}}</span>
      <mat-checkbox fxFlex value="true" [checked]="subOption.mandatory" [(ngModel)]="subOption.mandatory"
        title='Field is mandatory'>

      </mat-checkbox>
    </span>
    <input [style.display]="'none'" matInput name="answertype" [(ngModel)]="subOption.controlType">
    <mat-label>{{'Answer Type' | translate}}</mat-label>
    <mat-select ng-model="answertype" (selectionChange)="subOption.controlType = $event.value"
      [value]="subOption.controlType">
      <mat-option *ngFor="let opt of answerTypes" value="{{opt.value}}">{{opt.text}}</mat-option>
    </mat-select>

    <button mat-icon-button
      [class.containsData]="subOption.flag && subOption.flag.character && subOption.flag.character.length > 0" matSuffix
      (click)="editFlag($event)" [attr.aria-label]="'Flag'" title="{{'Flag' | translate}}">
      <mat-icon>flag</mat-icon>
    </button>
    <button mat-icon-button matSuffix (click)="deleteSubLevel($event)" [attr.aria-label]="'Remove option'">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <textarea matInput maxlength="1000" [(ngModel)]="subOption.questionText" cdkTextareaAutosize cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="5" (change)="onChange($event)">
   </textarea>
  </mat-form-field>
</div>