<div class="col-xl-12 tilePopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{title}}
			</div>
		</div>
	</div>
	<div mat-dialog-content class="dialog-content">
		<app-custom-tile-view #customtileview *ngIf="data.data && data.data['_id']" [isAdmin]="data.isAdmin"
			[isSuperAdmin]="data.isSuperAdmin" [tileId]="data.data['_id']" [readonly]="readonly"
			(returnTitle)="setTitle($event)" (returnAction)="setAction($event)">
		</app-custom-tile-view>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' }}</button>
			<button mat-raised-button *ngIf="readonly" (click)="submitData()" [disabled]="loading">{{'Submit Readonly'
				}}</button>
			<button *ngIf="!readonly" mat-raised-button (click)="submitData()"
				[disabled]="loading">{{'Submit'}}</button>
		</div>
	</div>
	<br />
</div>