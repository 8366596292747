import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { guid } from '../../helpers/guid';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public Loading: boolean = false;

  public _isVisible: boolean = true;
  @Input()
  set isVisible(value: boolean) {
    this._isVisible = value;
    setTimeout(() => {
      this.cd.detectChanges();
    }, 300);
  }
  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input() data: any;
  @Input() title: string;
  @Input() type: string;
  @Output() onActionData = new EventEmitter<any>();
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  setData(block) {
    this.onActionData.emit(block);
  }
  onElementVisible(isVisible: any) {
    if (isVisible) {

      this.isVisible = isVisible;
    }
    this.cd.detectChanges();
  }
}
