import { Component, OnInit } from '@angular/core';
import { FormLoaderService } from '../../services';

@Component({
  selector: 'app-form-loading-screen',
  templateUrl: './form-loading-screen.component.html',
  styleUrls: ['./form-loading-screen.component.scss']
})
export class FormLoadingScreenComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public showLoader: boolean = false;

  constructor(private loaderService: FormLoaderService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.loaderService.status.subscribe((val: boolean) => {
        this.showLoader = val;
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
