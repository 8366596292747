import { Directive, ElementRef, Output, EventEmitter, NgZone, Input } from '@angular/core';

@Directive({
    selector: '[appVisibility]'
})
export class VisibilityDirective {
    @Output() elementVisible = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef, private ngZone: NgZone) { }

    ngAfterViewInit() {
        const options = {
            root: null, // viewport
            rootMargin: '0px',
            threshold: 0.5, // when 50% of the element is visible
        };

        this.ngZone.runOutsideAngular(() => {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.elementVisible.emit(true); // Element is visible
                    } else {
                        this.elementVisible.emit(false); // Element is not visible
                    }
                });
            }, options);

            observer.observe(this.elementRef.nativeElement);
        });
    }
}