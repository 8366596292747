import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public showNotificationUnRead: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public dnd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showShelterNotification: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(private snackBar: MatSnackBar, private requestService: RequestService) { }

    public openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000
        });
    }

    getNotifications(term: string, pageIndex: number, pageSize: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getDataList('notify', {
                term: term || '', termfields: ['client.name', 'user.name', 'risk'], page: pageIndex + 1, perpage: pageSize, filter: { "$and": [{ "organizationId._id": { "$eq": this.requestService.orgId } }] }, orderBy: 'updatedAt', orderDir: 'desc', fieldKeys: ['updatedAt', 'createdAt', 'assessmentId', 'risk', 'client', 'user', 'type']
            }, (data: any, error: any) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }
}
