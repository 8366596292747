import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material.module';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { GlobalErrorHandler, RollbarService, rollbarFactory } from './shared/services/globar-error.handler';
import { AuthInterceptor } from './shared/services/interceptors/auth.interceptor';
// import { SpinnerInterceptor } from './shared/services/interceptors/spinner.interceptor';
import { LayoutUtilsService } from './shared/services/layout-utils.service';
import { RequestService } from './shared/services/request.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthGuard } from './guards/auth.guard';
import { LoaderService, StoreService } from './shared/services';
import { FormLibrariesComponentModule } from './shared/modules/forms-libraries/form-libraries.module';
import { FirebaseHandlersService } from './shared/services/firebase-handlers.service';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

// Function to initialize Firebase before app starts
export function initializeFirebase(firebaseService: FirebaseHandlersService) {
  return () => firebaseService.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    FormLibrariesComponentModule,
    HttpClientModule,
    MatNativeDateModule,
    MaterialModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
  ],
  providers: [
    AuthGuard,
    RequestService,
    LoaderService,
    StoreService,
    LayoutUtilsService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: SpinnerInterceptor,
    //   multi: true,
    // },
    { provide: 'LOCALSTORAGE', useValue: window.localStorage },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    FirebaseHandlersService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFirebase,
      multi: true,
      deps: [FirebaseHandlersService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
