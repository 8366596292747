import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService } from '../../services';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'rules-view-dialog',
    templateUrl: './rules-view-dialog.component.html',
    styleUrls: ['./rules-view-dialog.component.scss']
})
export class RulesViewDialogComponent implements OnInit {
    private subscriptions: Subscription[] = [];
    public errorMessage: string = '';
    public scopes: string[] = [];
    public orgType = environment.orgType;
    public loading: boolean = false;
    public hasFormErrors: boolean = false;
    public pictureLink: string = 'assets/images/profile.png';
    public fieldsDetails: any = undefined;
    public permission: any[] = [];
    public metaFieldTabsSetting = [];
    public tableDataTemp: any = undefined;
    public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea'];
    public selectedUser: any;
    public showEdit: boolean = false;

    constructor(
        public dialog: MatDialog, private translate: TranslateService,
        private requestService: RequestService,
        private layoutUtilsService: LayoutUtilsService,
        public dialogRef: MatDialogRef<RulesViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        // console.log('UserViewDialogData', data);
    }

    ngOnInit() {
        this.buildSetting();
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.selectedUser = data;
                    let currentUserRole = this.requestService.getUserRoleByUserData(this.selectedUser);
                    if (currentUserRole == "admin") {
                        this.showEdit = true;
                    }
                }
            })
        );

    }
    private buildSetting() {
        if (!this.loading) {
            this.loading = true;
            this.errorMessage = '';
            this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
                if (error) {
                    this.errorMessage = error;
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                }
                this.loading = false;
                if (data) {
                    this.data.modalSetting = data.results;
                    this.fieldsDetails = this.buildMetaSetting(data.results);
                    console.log('this.fieldsDetails', this.fieldsDetails);
                    if (this.data.data.hasOwnProperty('_id')) {
                        this.loadData();
                    }
                } else {
                    this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
                }
            });
        }
    }
    public loadData() {
        console.log('loadData');
        if (!this.loading) {
            this.loading = true;
            this.errorMessage = '';
            let dataId = this.data.data['_id'];
            this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
                if (error) {
                    this.errorMessage = error;
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                }
                if (data) {
                    this.tableDataTemp = Object.assign({}, data.results);
                    data.results['description'] = data.results['description'].replace(/(?:\r\n|\r|\n)/gm, '<br>');
                    this.data.confirmData = Object.assign({}, data.results);
                    if (data.results['pictureLink']) {
                        this.pictureLink = data.results['pictureLink'];
                    }
                }
                this.loading = false;
            });
        }
    }
    private returnObjectOfArray(_id) {
        for (let cmp of this.tableDataTemp['competencies']) {
            if (cmp.benchmarkId._id === _id) {
                return cmp;
            }
        }
        return undefined;
    }
    convertArrayToObject(arr: any[]) {
        let obj = {};
        for (let a of arr) {
            obj[a._id] = a;
        }
        return obj;
    }
    closeModal(data): void {
        this.dialogRef.close(data);
    }
    buildMetaSetting(data, parent = undefined) {
        let dataObject = {};
        let tabObject = [];
        for (let col of data.fields) {
            if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
                if (parent) {
                    col['inputName'] = parent + col['name'];
                }
                dataObject[col.name] = col;
            } else if (col.type === 'object') {
                dataObject[col.name] = this.buildMetaSetting(col);
                tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
            } else if (col.type === 'table') {
                dataObject[col.name] = this.buildMetaSetting(col);
            }
        }
        this.metaFieldTabsSetting = tabObject;
        return dataObject;
    }
    public buildTabsMetaSetting() {
        if (this.data.confirmData) {
            for (let tab of this.metaFieldTabsSetting) {
                if (!this.tableDataTemp.hasOwnProperty(tab.name)) {
                    if (this.data.confirmData.hasOwnProperty(tab.name)) {
                        delete this.data.confirmData[tab.name];
                    }
                }
                if (this.data.confirmData.hasOwnProperty(tab.name) || this.data.confirmData['role']['targetfield'] === tab.name) {
                    if (!this.data.confirmData.hasOwnProperty(tab.name)) {
                        this.data.confirmData[tab.name] = {}
                    }
                }
            }
        }
    }
    public viewTranscript(transcript: any) {
        window.open(transcript.fileLink, '_blank');
    }
}
