import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { GoogleMapsModule } from "@angular/google-maps";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';

import { CkeditorBlockComponent, TextBlockComponent, LinkDescriptionBlockComponent, PictureBlockComponent, DisqusBlockComponent, SocialFeedBlockComponent, CalendarBlockComponent, PatientsBlockComponent, InquiryBlockComponent, SurveyBlockComponent, QuestionnaireBlockComponent, QuestionnaireSubOptionComponent, DescriptionSubOptionComponent, StartWrapperBlockComponent, AddSpaceComponent, FormTitleBlockComponent, QuestionsBlockComponent, ConfirmationBlockComponent, PasswordBlockComponent, NextBlockComponent, FormPhotoComponent, FormDocumentComponent, PainLevelComponent, DrawToolBlockComponent, PhysicianBlockComponent, EndWrapperBlockComponent, FillBlockComponent, NotesBlockComponent, ButtonsBlockComponent, ContactUsBlockComponent, PlacefullBlockComponent, AddToCartBlockComponent, CartBlockComponent, BlanksFormBlockComponent, ExclusiveUrlBlockComponent, FileUploadBlockComponent, PushpayBlockComponent, ThreedCartBlockComponent, BlogsBlockComponent, ChatBlockComponent, AccountBlockComponent, ProfileBlockComponent, TileSelectDialogComponent, TileDataComponent, TilesListComponent, CustomTileViewComponent, WidgetComponent, ModalPreviewDialogComponent, CustomSelectCreateAutocompleteComponent, FormLoadingScreenComponent, ScoreTileDataComponent, DataListComponent, DataAssignDialogComponent, FormIframeUrlComponent, ModalTileDialogComponent, ModalQuestionFlagDialogComponent, VideoInputEntityDialogComponent, XEditorBlockComponent } from './components';

import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertEntityDialogComponent,
    AlertActionEntityDialogComponent,
    UpdateEntityDialogComponent,
    ErrorEntityDialogComponent,
    ConfirmSelectEntityDialogComponent,
    ConfirmCkeditorEntityDialogComponent,
    ConfirmInputEntityDialogComponent
} from './modals';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { DeviceDetectorModule } from 'ngx-device-detector';

import { FormUtils } from './helpers/utils';
import { FormLayoutUtilsService, FormLoaderService, FormRequestService } from './services';
import { RestrictInputDirective, ResizeableDirective } from './directives';
import { AngularEditorModule } from './lib/x-editor';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        // GoogleMapsModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        AngularEditorModule,
        ImageCropperModule,
        FlexLayoutModule.withConfig({ addFlexToParent: false }),
        MatTooltipModule,
        // DeviceDetectorModule
    ],
    providers: [
        // GlobalService
        FormUtils,
        FormLayoutUtilsService,
        FormLoaderService,
        FormRequestService
    ],
    declarations: [
        ConfirmCkeditorEntityDialogComponent,
        WidgetComponent,
        PictureBlockComponent,
        DisqusBlockComponent,
        SocialFeedBlockComponent,
        CalendarBlockComponent,
        PatientsBlockComponent,
        InquiryBlockComponent,
        SurveyBlockComponent,
        QuestionnaireBlockComponent,
        QuestionnaireSubOptionComponent,
        DescriptionSubOptionComponent,
        StartWrapperBlockComponent,
        AddSpaceComponent,
        FormTitleBlockComponent,
        QuestionsBlockComponent,
        ConfirmationBlockComponent,
        PasswordBlockComponent,
        NextBlockComponent,
        FormPhotoComponent,
        FormDocumentComponent,
        PainLevelComponent,
        DrawToolBlockComponent,
        PhysicianBlockComponent,
        EndWrapperBlockComponent,
        FillBlockComponent,
        NotesBlockComponent,
        ButtonsBlockComponent,
        ContactUsBlockComponent,
        PlacefullBlockComponent,
        AddToCartBlockComponent,
        CartBlockComponent,
        BlanksFormBlockComponent,
        ExclusiveUrlBlockComponent,
        FileUploadBlockComponent,
        PushpayBlockComponent,
        ThreedCartBlockComponent,
        BlogsBlockComponent,
        ChatBlockComponent,
        AccountBlockComponent,
        ProfileBlockComponent,
        TextBlockComponent,
        LinkDescriptionBlockComponent,
        CkeditorBlockComponent,
        ModalPreviewDialogComponent,
        TileSelectDialogComponent,
        TileDataComponent,
        TilesListComponent,
        CustomTileViewComponent,
        CustomSelectCreateAutocompleteComponent,
        ConfirmInputEntityDialogComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        UpdateEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        FormLoadingScreenComponent,
        DataListComponent,
        ScoreTileDataComponent,
        DataAssignDialogComponent,
        FormIframeUrlComponent,
        ModalTileDialogComponent,
        ModalQuestionFlagDialogComponent,
        RestrictInputDirective,
        VideoInputEntityDialogComponent,
        XEditorBlockComponent,
        ResizeableDirective
    ],
    exports: [
        ConfirmCkeditorEntityDialogComponent,
        WidgetComponent,
        PictureBlockComponent,
        DisqusBlockComponent,
        SocialFeedBlockComponent,
        CalendarBlockComponent,
        PatientsBlockComponent,
        InquiryBlockComponent,
        SurveyBlockComponent,
        QuestionnaireBlockComponent,
        QuestionnaireSubOptionComponent,
        DescriptionSubOptionComponent,
        StartWrapperBlockComponent,
        AddSpaceComponent,
        FormTitleBlockComponent,
        QuestionsBlockComponent,
        ConfirmationBlockComponent,
        PasswordBlockComponent,
        NextBlockComponent,
        FormPhotoComponent,
        FormDocumentComponent,
        PainLevelComponent,
        DrawToolBlockComponent,
        PhysicianBlockComponent,
        EndWrapperBlockComponent,
        FillBlockComponent,
        NotesBlockComponent,
        ButtonsBlockComponent,
        ContactUsBlockComponent,
        PlacefullBlockComponent,
        AddToCartBlockComponent,
        CartBlockComponent,
        BlanksFormBlockComponent,
        ExclusiveUrlBlockComponent,
        FileUploadBlockComponent,
        PushpayBlockComponent,
        ThreedCartBlockComponent,
        BlogsBlockComponent,
        ChatBlockComponent,
        AccountBlockComponent,
        ProfileBlockComponent,
        TextBlockComponent,
        LinkDescriptionBlockComponent,
        CkeditorBlockComponent,
        ModalPreviewDialogComponent,
        TileSelectDialogComponent,
        TileDataComponent,
        TilesListComponent,
        CustomTileViewComponent,
        CustomSelectCreateAutocompleteComponent,
        ConfirmInputEntityDialogComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        UpdateEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        FormLoadingScreenComponent,
        DataListComponent,
        ScoreTileDataComponent,
        DataAssignDialogComponent,
        FormIframeUrlComponent,
        ModalTileDialogComponent,
        ModalQuestionFlagDialogComponent,
        RestrictInputDirective,
        VideoInputEntityDialogComponent,
        XEditorBlockComponent,
        ResizeableDirective
    ]
})
export class FormLibrariesComponentModule { }
