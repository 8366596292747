<div class='ili-panel linkDescription_panel'>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.name" class="form-control input-sm" type="text"
      placeholder="{{'Name' | translate}}">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.description" class="form-control input-sm" type="text"
      placeholder="{{'Description' | translate}}">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.link" class="form-control input-sm" type="text"
      placeholder="{{'Link' | translate}}" (input)="onChangeLink($event.target.value)">
  </mat-form-field>
  <mat-checkbox value="true" [checked]="block.data.openLink" [(ngModel)]="block.data.openLink"
    (change)="onChangeOpenLink($event.checked)" [disabled]="block.data.link == ''"
    title="If checked, the link content will be displayed directly. If unchecked, the above fields will be displayed.">
    {{'Open Link' | translate}}</mat-checkbox>
  <mat-form-field style="width: 100%;" *ngIf="block.data.openLink">
    <input [style.display]="'none'" matInput name="openLinkMobile" [(ngModel)]="block.data.openLinkMobile">
    <mat-label>{{'Mobile' | translate}}</mat-label>
    <mat-select ng-model="openLinkMobile" (selectionChange)="block.data.openLinkMobile = $event.value"
      [value]="block.data.openLinkMobile">
      <mat-option *ngFor="let opt of openLinkMobileTypes" value="{{opt.value}}">{{opt.text}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="width: 100%;" *ngIf="block.data.openLink">
    <input [style.display]="'none'" matInput name="openLinkWeb" [(ngModel)]="block.data.openLinkWeb">
    <mat-label>{{'Web' | translate}}</mat-label>
    <mat-select ng-model="openLinkWeb" (selectionChange)="block.data.openLinkWeb = $event.value"
      [value]="block.data.openLinkWeb">
      <mat-option *ngFor="let opt of openLinkWebTypes" value="{{opt.value}}">{{opt.text}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>