<app-form-loading-screen *ngIf="showSelfLoader"></app-form-loading-screen>
<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
  <div fxFlex class="contentBox">
    <span *ngIf="loading">{{ 'Loading' | translate }}...</span>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
          <div fxFlex="25">
            <mat-form-field>
              <mat-label>{{ 'Title' | translate }}</mat-label>
              <input matInput #title name="title" [(ngModel)]="selectedData.title" [required]="true"
                placeholder="{{ 'Title' | translate }}" [errorStateMatcher]="esMatcher" max="200" maxlength="200">
              <mat-error
                *ngIf="title.invalid && title.touched && selectedData.title === undefined || selectedData.title === ''">
                {{ 'Title' | translate }} is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="25">
          </div>
          <div fxFlex="50" class="topBtns">
            <button color="primary" mat-raised-button (click)="toggleClick('delete')"
              [disabled]="!selectedData.hasOwnProperty('_id')">{{ 'Delete' | translate }}</button>
            <button color="primary" mat-raised-button (click)="toggleClick('save')">{{ 'Save' |
              translate }}</button>
            <button color="primary" mat-raised-button (click)="toggleClick('new')">{{ 'New' |
              translate }}</button>
          </div>
        </div>
        <!-- </mat-card-content>
            </mat-card> -->
      </div>
    </div>
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex="25">
        <app-data-list #dataList [dataType]="dataType" [UIDisplay]="UIDisplay" [selectedData]="selectedData"
          (dataListAction)="dataActionReturn($event)" (selectItem)="selectItemCheck($event)"></app-data-list>
      </div>
      <div fxFlex="50">
        <mat-card>
          <mat-card-header>
            <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
              <div fxFlex="80">
                <mat-card-title>{{UIDisplay | translate}} {{'Options' | translate}} </mat-card-title>
              </div>
              <div fxFlex="20">
                <button mat-icon-button align="end" style="visibility: hidden;">
                  Temp
                </button>
              </div>
            </div>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <mat-card class="tileData" *ngFor="let tile of tileStructure">
              <mat-card-header>
                <mat-card-title>Tile: {{tile.name}}</mat-card-title>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                  <button mat-icon-button (click)="tile['collapse'] = !tile['collapse']">
                    <mat-icon *ngIf="tile['collapse']" title="Expand">unfold_more</mat-icon>
                    <mat-icon *ngIf="!tile['collapse']" title="Collapse">unfold_less</mat-icon>
                  </button>
                </div>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content>
                <ng-container *ngIf="tile.widgets">
                  <!-- <mat-card class="widgetData" *ngFor="let widget of tile.widgets" [hidden]="tile['collapse']">
                    <mat-card-header>
                      <mat-card-title>{{questionsType[widget.type]}}</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content> -->
                  <div *ngFor="let widget of tile.widgets" [hidden]="tile['collapse']">
                    <ng-container *ngIf="widget.questions">
                      <ng-container *ngIf="isDataArray(widget.questions)">
                        <ng-container *ngFor="let question of widget.questions">
                          <ng-container [ngTemplateOutlet]="questionData"
                            [ngTemplateOutletContext]="{ question: question, tileId: tile.id, widgetId: widget._id }">
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!isDataArray(widget.questions)">
                        <ng-container [ngTemplateOutlet]="questionData"
                          [ngTemplateOutletContext]="{ question: widget.questions, tileId: tile.id, widgetId: widget._id }">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>

                  <!-- </mat-card-content>
                  </mat-card> -->
                </ng-container>
              </mat-card-content>
            </mat-card>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="25" class="content-list">
        <mat-card class="tileData">
          <mat-card-header>
            <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
              <div fxFlex="80">
                <mat-card-title>{{'Added Tiles' | translate}} </mat-card-title>
              </div>
              <div fxFlex="10">
                <button mat-icon-button align="end" (click)="selectTiles()">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>

          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div *ngIf="selectedData && selectedData.selectedTiles">
              <div class="data-box" *ngFor="let itm of selectedData.selectedTiles">
                {{itm.name}}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<ng-template #questionData let-question="question" let-tileId="tileId" let-widgetId="widgetId">
  <mat-checkbox class="example-margin" name="{{question.id}}" [(ngModel)]="question['include']"
    (change)="onChangeInclude($event.checked, tileId, widgetId, question.id)" title="{{'Include in calculation'}}">
    {{question.name}}</mat-checkbox>
  <ng-container *ngIf="question.options">
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px"
      *ngFor="let opt of question.options; let index = index" fxLayoutAlign="center center">
      <div fxFlex>
        <mat-form-field class="example-full-width">
          <input type="number" matInput id="{{opt.id}}" placeholder="" [(ngModel)]="opt['weight']"
            (change)="onChange($event.target.value, tileId, widgetId, question.id, opt.id, question.options.length)">
        </mat-form-field>
      </div>
      <div fxFlex>
        {{opt.name}}
      </div>
    </div>
    <mat-divider class="tileDivider"></mat-divider>
  </ng-container>
</ng-template>