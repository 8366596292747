// Angular
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorService } from '../angular-editor.service';
import { validateURL } from '../../../helpers';

@Component({
	selector: 'image-insert-dialog',
	templateUrl: './image-insert-dialog.component.html',
	styleUrls: ['./image-insert-dialog.component.scss']
})
export class ImageInsertDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	public allowedExtensions: string[] = ['jpeg', 'jpg', 'png', 'gif'];

	public allowedAcceptExtensions: string = 'image/jpg,image/jpeg,image/png,image/gif';
	public anchorTargets: any[] = [
		{ value: '_blank', display: 'Open in new tab/window' },
		{ value: '_self', display: 'Open in the same tab/window' }
	];
	public loading: boolean = false;
	public returnData: any = { imageUrl: '', width: '100%', height: '100%', targetUrl: '', target: '_blank' };
	constructor(private translate: TranslateService, private editorService: AngularEditorService,
		public dialogRef: MatDialogRef<ImageInsertDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (this.data.data) {
			this.returnData = this.data.data;
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		if (this.returnData['imageUrl'] === '') {
			this.editorService.showNotification(this.translate.instant('Please select an image'), this.translate.instant('Dismiss'));
			return;
		}
		if (this.returnData['width'] === '' || this.returnData['height'] === '') {
			this.editorService.showNotification(this.translate.instant('Please fill the Width and Height.'), this.translate.instant('Dismiss'));
			return;
		}
		if (this.returnData['targetUrl'] !== '') {
			if (!validateURL(this.returnData['targetUrl'])) {
				this.editorService.showNotification(this.translate.instant('Please insert a proper target url structure'), this.translate.instant('Dismiss'));
				return;
			}
		}
		this.dialogRef.close(this.returnData);
	}
	public onChange(id, value) {
		this.returnData[id] = value;
	}
	/**
   * Upload image when file is selected.
   */
	onFileChanged(event) {
		const file = event.target.files[0];
		this.loading = true;
		if (file) {
			let fileExt = file.name.split('.').pop();
			// let imageExtension = fileExt.toLowerCase();
			const max_size = 5000000;
			const max_height = 400;
			const max_width = 400;
			if (file.size > max_size) {
				this.loading = false;
				this.editorService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB', this.translate.instant('Dismiss'));
			} else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
				this.loading = false;
				this.editorService.showNotification(this.translate.instant('The file type is not allowed'), this.translate.instant('Dismiss'));
			} else {
				let reader = new FileReader();
				this.readFile(file, reader, (event) => {
					var image = new Image();
					this.readImage(event, image, (imgresult) => {
						// console.log('readImage', imgresult);
						var canvas = document.createElement("canvas");
						var context = canvas.getContext("2d");

						var ratio = Math.min(max_width / imgresult.width, max_height / imgresult.height);
						if (imgresult.width < imgresult.width * ratio && imgresult.height < imgresult.height * ratio) {
							canvas.width = imgresult.width;
							canvas.height = imgresult.height;
						} else {
							canvas.width = imgresult.width * ratio;
							canvas.height = imgresult.height * ratio;
						}
						context.drawImage(image,
							0,
							0,
							image.width,
							image.height,
							0,
							0,
							canvas.width,
							canvas.height
						);
						// console.log(canvas.toDataURL());
						// console.log(image);
						if (canvas.width <= max_width && canvas.height <= max_height) {
							this.returnData['imageUrl'] = canvas.toDataURL();
						} else {
							this.editorService.showNotification(this.translate.instant('The image is not well formatted'), this.translate.instant('Dismiss'));
						}
						this.loading = false;
						// this.continueUpload(currentFile);
					});
				});
			}
		} else {
			this.loading = false;
		}
	}
	readFile(file, reader, callback): void {
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
	readImage(file, image, callback): void {
		image.onload = () => {
			callback(image);
		}
		image.src = file;
	}
}
