import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/modals/confirm-dialog/confirm-dialog.component';
import { LayoutUtilsService } from 'src/app/shared/services/layout-utils.service';
import { RisksService } from 'src/app/shared/services/risks.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-risk-logs-modal',
  templateUrl: './risk-logs-modal.component.html',
  styleUrls: ['./risk-logs-modal.component.scss'],
})
export class RiskLogsDialogComponent implements OnInit {

  private assessmentId: string = undefined;
  private formId: string = undefined;

  // public notes: string = '';
  // public riskLevel: string = '';
  public logs: any = [];
  public form: FormGroup;
  public riskLevels: any = [];

  constructor(
    public dialogRef: MatDialogRef<RiskLogsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private risksService: RisksService, private spinnerService: SpinnerService,
    private layoutUtilsService: LayoutUtilsService, private dialog: MatDialog,
    private translate: TranslateService, private formBuilder: FormBuilder,
  ) {
    this.assessmentId = data.assessmentId;
    this.formId = data.formId;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      riskLevel: ['', [Validators.required]],
      notes: ['', [Validators.required]],
    });

    this.spinnerService.show();
    let riskLogsPromise = this.risksService.getRiskLogs(this.assessmentId).then(logs => {
      this.logs = logs;
    });

    let riskLogsMetadataPromise = this.risksService.getRiskLogsMetadata().then((metadata: any) => {
      this.riskLevels = metadata.find(i => i.name === 'risk')?.enum;
    })

    Promise.all([riskLogsPromise, riskLogsMetadataPromise]).then(_ => this.spinnerService.hide());
  }

  saveLog() {
    if (this.form.valid) {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: this.translate.instant('Are you sure you want to update the Risk Level of ' + this.data.clientName + ' to ' + this.form.get('riskLevel').value.value + '?'),
          title: '',
          yesButton: this.translate.instant('Update'),
          noButton: this.translate.instant('Cancel')
        },
        width: 'fit-content',
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.spinnerService.show();
          this.risksService.saveLog(this.form.get('riskLevel').value.value, this.form.get('notes').value, this.assessmentId, this.formId, this.data.clientId).then(async _ => {
            // await this.assessmentService.updateAssessmentRisk(this.form.get('riskLevel').value, this.assessmentId);
            this.risksService.getRiskLogs(this.assessmentId).then(logs => {
              this.logs = logs;
              this.risksService.riskLevelSubject.next(logs[0].risk.name);
              this.spinnerService.hide();
            });
            this.layoutUtilsService.showNotification(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
          }).catch(error => {
            this.layoutUtilsService.showNotification(
              this.translate.instant('An error has occurred. Please try again later.'), this.translate.instant('Dismiss'));
            this.spinnerService.hide();
          }).finally(() => {
            this.form.controls["notes"].setValue("");
            this.form.controls["riskLevel"].setValue("");
            this.form.markAsUntouched();
          });
        }
      });
    }
  }
}
