import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/shared/services/auth.service';
import { LayoutUtilsService } from 'src/app/shared/services/layout-utils.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { ModalPasswordDialogComponent } from '../custom-password-dialog/custom-password-dialog.component';
import { ModalCropDialogComponent } from '../custom-crop-dialog/custom-crop-dialog.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FirebaseHandlersService } from 'src/app/shared/services/firebase-handlers.service';

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss'],
})
export class EditProfileDialogComponent implements OnInit, OnDestroy {
  private currentUser: any;
  public imageExtension: string = 'png';
  public allowedExtensions: string[] = ['jpeg', 'jpg', 'png', 'gif'];
  public defaultPictureLink: string = 'assets/images/profile.png';
  public pictureLink: string = JSON.parse(
    JSON.stringify(this.defaultPictureLink)
  );
  public originalData: any = undefined;
  public imageChanged: any = undefined;

  public firstNameFormControl = new FormControl({ value: '', disabled: true }, Validators.required);
  public lastNameFormControl = new FormControl({ value: '', disabled: true }, Validators.required);
  public organizationControl = new FormControl({ value: '', disabled: true });
  public occupationControl = new FormControl({ value: '', disabled: true });
  public dnd: boolean = false;
  public emailControl = new FormControl({ value: '', disabled: true }, Validators.required);
  public userObj: any = {};

  private subscriptions: any[] = <any>[];

  constructor(
    private authService: AuthenticationService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService, private firebaseHandler: FirebaseHandlersService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EditProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('LOCALSTORAGE') private localStorage: Storage, private spinnerService: SpinnerService, private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((value) => {
        if (value) {
          this.currentUser = value;
          this.getUser();
        }
      })
    );
    // console.log(this.currentUser)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private validate(): boolean {
    this.firstNameFormControl.markAsTouched();
    this.lastNameFormControl.markAsTouched();
    return (
      this.firstNameFormControl.hasError('required') ||
      this.lastNameFormControl.hasError('required')
    );
  }

  getUser() {
    this.spinnerService.show();
    this.requestService.getRecord(
      this.currentUser._id + '/' + this.requestService.orgId,
      'user',
      (data: any, error: any) => {
        if (data) {
          this.userObj = data.results;
          this.userObj.role = data.results.resources[0].name;
          this.firstNameFormControl.setValue(data.results.firstName);
          this.lastNameFormControl.setValue(data.results.lastName);
          this.pictureLink =
            data.results.pictureLink || this.defaultPictureLink;
          this.emailControl.setValue(data.results.email);
          this.occupationControl.setValue(data.results.occupation || '');
          this.organizationControl.setValue(data.results.company_name || '');
          this.dnd = data.results.dnd;
        }
        if (error)
          this.layoutUtilsService.showNotification(
            this.translate.instant('An error has occurred. Please try again later.'),
            this.translate.instant('Dismiss')
          );
        this.spinnerService.hide();
      }
    );
  }

  resetPassword() {
    this.requestService.forgotPassword(
      this.currentUser.email,
      (data: any, error: any, returnIt: any) => {
        if (error) {
          this.layoutUtilsService.showNotification(error, 'Dismiss');
        }
        if (data) {
          this.changePasswordWithVerify();
          this.layoutUtilsService.showNotification(
            this.translate.instant(
              'Please check your email for a verification code to change your password.'
            ),
            'Dismiss'
          );
        }
      }
    );
  }

  private changePasswordWithVerify() {
    const dialogRef = this.dialog.open(ModalPasswordDialogComponent, {
      width: '300px',
      disableClose: true,
      data: {
        title: this.translate.instant('Change Password'),
        toVerify: true,
        email: this.currentUser.email,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('afterClosed', result);
      if (result) {
        // do nothing
        this.layoutUtilsService.showNotification(
          this.translate.instant('Your password has been reset successfully.'),
          'Dismiss'
        );
      }
    });
  }

  updateUser() {
    if (!this.validate() && this.firstNameFormControl.enabled) {
      this.spinnerService.show();
      this.requestService.updateRecord(
        {
          firstName: this.firstNameFormControl.value,
          lastName: this.lastNameFormControl.value,
          occupation: this.occupationControl.value,
          // email: this.emailControl.value,
          company_name: this.organizationControl.value,
          // dnd: this.dnd,
        },
        'user',
        this.currentUser._id + '/' + this.requestService.orgId,
        (data: any, error: any) => {
          if (data) {
            if (this.imageChanged && this.imageChanged != this.defaultPictureLink) {
              const img: any = this.dataURLtoFile(
                this.imageChanged,
                this.currentUser._id + '.' + this.imageExtension
              );
              this.uploadImage(img, this.currentUser._id, (data) => {
                this.authService.userImageSubject.next(
                  data.results.pictureLink
                );
                const user: any = JSON.parse(
                  this.localStorage.getItem('currentUser')
                );
                user.pictureLink = data.results.pictureLink;
                this.localStorage.setItem('currentUser', JSON.stringify(user));

                this.dialogRef.close();
                this.layoutUtilsService.showNotification(
                  this.translate.instant('Updated Successfully'),
                  this.translate.instant('Dismiss')
                );
              });
            } else {
              this.dialogRef.close();
              this.layoutUtilsService.showNotification(
                this.translate.instant('Updated Successfully'),
                this.translate.instant('Dismiss')
              );
            }
          }
          if (error)
            this.layoutUtilsService.showNotification(
              this.translate.instant('An error has occurred. Please try again later.'),
              this.translate.instant('Dismiss')
            );
          this.spinnerService.hide();
        }
      );
    }
  }

  saveDnd() {
    this.spinnerService.show();
    this.requestService.updateRecord(
      {
        dnd: this.dnd,
      },
      'user',
      this.currentUser._id + '/' + this.requestService.orgId,
      async (data: any, error: any) => {
        this.notificationService.dnd.next(this.dnd);
        if (data) {
          if (this.dnd) {
            await this.firebaseHandler.unregisterDevice(this.currentUser._id, this.firebaseHandler.token, () => {
              this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
            }).catch((e) => {
              this.layoutUtilsService.showNotification('Error: ' + e, this.translate.instant('Dismiss'));
            }).finally(() => this.spinnerService.hide());
          }
          else {
            this.firebaseHandler.registerDevice(this.currentUser._id, () => {
              this.spinnerService.hide();
              this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
            });
          }
        }
        if (error)
          this.layoutUtilsService.showNotification(
            this.translate.instant('An error has occurred. Please try again later.'),
            this.translate.instant('Dismiss')
          );
        this.spinnerService.hide();
      }
    );
  }

  public showImageCropper() {
    if (this.firstNameFormControl.enabled) {
      const dialog = this.dialog.open(ModalCropDialogComponent, {
        disableClose: true,
        autoFocus: true,
        width: '70vw',
        data: {
          title: this.translate.instant('Crop Image'),
          image: this.pictureLink,
          clientId: this.currentUser._id,
          isUser: true
        },
      });
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.imageChanged = result.base64Image;
          this.pictureLink = result.base64Image;
        }
      });
    }
  }

  public uploadImage(
    file: any,
    id: string = this.currentUser._id,
    callback?: any
  ) {
    let fileObj = {
      originalFile: file,
      text: file.name,
    };
    this.requestService.onUploadUserImage(fileObj, id).subscribe(
      (pictureLink: any) => {
        this.pictureLink = pictureLink;
        this.layoutUtilsService.showNotification(
          this.translate.instant('Image uploaded successfully'),
          this.translate.instant('Dismiss')
        );

        if (callback) callback(this.pictureLink);
      },
      (error: any) => {
        this.pictureLink = JSON.parse(JSON.stringify(this.defaultPictureLink));
        this.layoutUtilsService.showNotification(this.translate.instant('An error has occurred. Please try again later.'), 'Dismiss');
      }
    );
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  edit() {
    if (this.firstNameFormControl.enabled) {
      this.firstNameFormControl.disable();
      this.lastNameFormControl.disable();
      this.emailControl.disable();
      this.organizationControl.disable();
      this.occupationControl.disable();
    }
    else {
      this.firstNameFormControl.enable();
      this.lastNameFormControl.enable();
      this.emailControl.enable();
      this.organizationControl.enable();
      this.occupationControl.enable();
    }
  }
}
