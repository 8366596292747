import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent, DefaultSettings } from '../block-organizer';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'linkdescription-block',
  templateUrl: './linkdescription-block.component.html',
  styleUrls: ['../tileblocks.component.scss', './linkdescription-block.component.scss']
})
export class LinkDescriptionBlockComponent implements BlockComponent {
  @Input() block: any;

  openLinkMobileTypes: any[] = DefaultSettings.openLinkMobileTypes;
  openLinkWebTypes: any[] = DefaultSettings.openLinkWebTypes;
  @Output() linkDescriptionView = new EventEmitter<any>();
  constructor(public sanitizer: DomSanitizer,
    public dialog: MatDialog) { }

  public onChangeOpenLink(data) {
    // const data = editor.getData();
    this.block.data['openLink'] = data;
    if (data) {
      this.block.data['openLinkMobile'] = '1';
      this.block.data['openLinkWeb'] = '1';
    }
    this.linkDescriptionView.emit({ 'action': 'linkDescriptionView', block: this.block });
  }
  public onChangeLink(data) {
    if (data == undefined || data == '') {
      this.block.data['openLink'] = false;
      this.block.data['openLinkMobile'] = '1';
      this.block.data['openLinkWeb'] = '1';
    }
  }
}
