<h1 mat-dialog-title>{{ 'Risk Level Log' | translate }}</h1>
<div mat-dialog-content class="container">
  <div fxLayout="column" fxLayoutGap="2vh">
    <ul class="logs" fxFlex>
      <li *ngFor="let log of logs; let i=index">
        <ng-container *ngIf="log.note">
          <span [class.highlighted]="i == 0">{{'Risk Level updated to' | translate}} <span
              class="risk">{{log.risk.name}}</span> {{'by' | translate}} {{log.createdName}} {{'on' | translate}}
            {{log.createdAt | date: 'MM/dd/YYYY h:mm a'}}. Note: '{{log.note}}'</span>
        </ng-container>
        <ng-container *ngIf="!log.note">
          <span [class.highlighted]="i == 0">{{'Risk Level set to' | translate}} <span
              class="risk">{{log.risk.name}}</span>
            {{'based on' | translate}}
            {{log.form?.title}}
            {{'submission by' | translate}}
            {{log.createdName}} {{'on' | translate}} {{log.createdAt | date: 'MM/dd/YYYY h:mm a'}}.</span>
        </ng-container>
      </li>
    </ul>
    <div [formGroup]="form" fxLayoutGap="2vw" fxLayoutAlign="space-between center" fxFlex>
      <mat-form-field fxFlex="25">
        <mat-label>{{ "Update Risk Level" | translate }}</mat-label>
        <mat-select formControlName="riskLevel">
          <mat-option [value]="level" *ngFor="let level of riskLevels">{{ level.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>{{ "Why are you updating the Risk Level?" | translate }}</mat-label>
        <input matInput formControlName="notes" maxlength="300" />
      </mat-form-field>
      <button mat-raised-button [class.primary]="form.valid" fxFlex fxGrow="0" fxLayoutAlign="center center"
        [disabled]="!form.valid" (click)="saveLog()">
        {{ "Save" | translate }}
      </button>
    </div>
  </div>
</div>
<div mat-dialog-actions align="start">
  <button mat-raised-button mat-dialog-close class="primary">
    {{ "Close" | translate }}
  </button>
</div>