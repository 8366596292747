import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent } from '../block-organizer';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmCkeditorEntityDialogComponent } from '../../../modals';
import { ConfirmInputEntityDialogComponent } from '../../../modals';
import { ModalQuestionFlagDialogComponent } from '../../custom-question-flag-dialog/custom-question-flag-dialog.component';

@Component({
  selector: 'survey-block',
  templateUrl: './survey-block.component.html',
  styleUrls: ['../tileblocks.component.scss', './survey-block.component.scss']
})
export class SurveyBlockComponent implements BlockComponent {
  @Input() block: any;
  @Output() surveyView = new EventEmitter<any>();
  constructor(public dialog: MatDialog) { }

  public onChange(e: any) {
    this.surveyView.emit({ 'action': 'surveyView', block: this.block });
  }

  public onChangePopup(idx, data) {
    this.block.data.popup[idx] = data;
    this.surveyView.emit({ 'action': 'surveyView', block: this.block });
  }
  public onChangeConfirmation(idx, data) {
    this.block.data.confirmation[idx] = data;
    this.surveyView.emit({ 'action': 'surveyView', block: this.block });
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  };
  addOption(e: any) {
    this.block.data.questions.push("");

    var popLength = this.block.data.popup.length;
    var alertsLength = this.block.data.alerts.length;
    var confirmLength = this.block.data.confirmation.length;

    if (popLength > 0) {
      this.addPopup("", 0);
    }

    if (alertsLength > 0) {
      this.addAlert("", 0);
    }

    if (confirmLength > 0) {
      this.addConfirm("", 0);
    }
  };

  controlChange(isMultiple: any) {
    if (isMultiple == "true") {
      this.block.data.showInApp = false;
      this.block.data.isNote = false;
    }
    this.surveyView.emit({ 'action': 'surveyView', block: this.block });
  };

  addPopup(e: any, index: number, deleteIt: boolean = false) {
    var quesLength = this.block.data.questions.length;
    var popLength = this.block.data.popup.length;

    if (!deleteIt) {
      if (quesLength > popLength) {
        var popToAppend = quesLength - popLength;
        var contentValue = new String("");

        for (let i = 1; i <= popToAppend; i++) {
          this.block.data.popup.push(contentValue);
        }
      }
    } else {
      this.block.data.popup = [];
    }
  };
  editPopup(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Pop Up',
        data: this.block.data.popup[index],
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.popup[index] = result;
        this.surveyView.emit({ 'action': 'surveyView', block: this.block });
      }
    });
  };

  addAlert(e: any, index: number, deleteIt: boolean = false) {
    var quesLength = this.block.data.questions.length;
    var alertsLength = this.block.data.alerts.length;
    if (!deleteIt) {
      if (quesLength > alertsLength) {
        var alertsToAppend = quesLength - alertsLength;

        for (let i = 1; i <= alertsToAppend; i++) {
          this.block.data.alerts.push("");
        }
      }
    } else {
      this.block.data.alerts = [];
    }
  };
  editAlert(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmInputEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Alert',
        data: this.block.data.alerts[index],
        placeholder: 'Type email address to receive an alert when corresponding option is selected',
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.alerts[index] = result;
        this.surveyView.emit({ 'action': 'surveyView', block: this.block });
      }
    });
  };
  addConfirm(e: any, index: number, deleteIt: boolean = false) {
    var quesLength = this.block.data.questions.length;
    var confirmLength = this.block.data.confirmation.length;
    if (!deleteIt) {
      if (quesLength > confirmLength) {
        var confirmToAppend = quesLength - confirmLength;

        for (let i = 1; i <= confirmToAppend; i++) {
          this.block.data.confirmation.push("");
        }
      }
    } else {
      this.block.data.confirmation = [];
    }
  };
  editConfirm(e: any, index: number) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Confirmation',
        data: this.block.data.confirmation[index],
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data.confirmation[index] = result;
        this.surveyView.emit({ 'action': 'surveyView', block: this.block });
      }
    });
  };
  editFlag(e: any) {
    const dialogRef = this.dialog.open(ModalQuestionFlagDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: 'Edit Flag',
        data: this.block.data.flag,
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.block.data['flag'] = result;
        this.surveyView.emit({ 'action': 'surveyView', block: this.block });
      }
    });
  };
  removeOption(e: any, index) {
    if (index !== -1 && this.block.data.questions.length > 1) {
      this.block.data.questions.splice(index, 1);
      this.block.data.popup.splice(index, 1);
      this.block.data.alerts.splice(index, 1);
      this.block.data.confirmation.splice(index, 1);
    }
    this.surveyView.emit({ 'action': 'surveyView', block: this.block });
  };
}
