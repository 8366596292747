import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalQuestionFlagDialogComponent } from '../../custom-question-flag-dialog/custom-question-flag-dialog.component';
import { BlockComponent, DefaultSettings } from '../block-organizer';

@Component({
  selector: 'description-sub-option',
  templateUrl: './description-sub-option.component.html',
  styleUrls: ['../tileblocks.component.scss', './description-sub-option.component.scss']
})
export class DescriptionSubOptionComponent implements BlockComponent {
  @Input() block: any;
  answerTypes: any[] = DefaultSettings.answerTypes;
  @Input('subOption') subOption: any;
  @Input('parentIndex') parentIndex: number;
  @Input('currentIndex') currentIndex: number;
  @Input('selectWidth') selectWidth: any;
  @Input('descriptionWidth') descriptionWidth: any;
  @Input('levelIndex') levelIndex: number;
  @Output() removeSubLevel = new EventEmitter<any>();
  @Output() changeSubLevel = new EventEmitter<any>();
  public config = {
    uiColor: '#F0F3F4',
    height: '250',
    allowedContent: true,
    removeButtons: 'Image'
  };
  constructor(public dialog: MatDialog, private translate: TranslateService) { }

  public onChange(e: any = undefined) {
    // e.preventDefault();
    var level = { "parentIndex": this.parentIndex, "currentIndex": this.currentIndex };
    this.changeSubLevel.emit(level);
  }


  getAlphaLetter(i: number) {
    return (i >= 26 ? this.getAlphaLetter((i / 26 >> 0) - 1) : '') + 'abcdefghijklmnopqrstuvwxyz'[i % 26 >> 0];
  };

  trackByIndex(index: number, obj: any): any {
    return index;
  };

  getLevelIndex() {
    var idx = "i";

    return this.levelIndex == 2 ? idx = idx + "i" : idx;
  };
  editFlag(e: any) {
    const dialogRef = this.dialog.open(ModalQuestionFlagDialogComponent, {
      width: '1200px',
      disableClose: false,
      data: {
        title: this.translate.instant('Edit Flag'),
        data: this.subOption.flag,
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.subOption['flag'] = result;
        this.onChange();
      }
    });
  };
  deleteSubLevel(e: any) {
    var level = { "parentIndex": this.parentIndex, "currentIndex": this.currentIndex };
    this.removeSubLevel.emit(level);
  };
}
