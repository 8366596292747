import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent } from '../block-organizer';

@Component({
  selector: 'text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['../tileblocks.component.scss', './text-block.component.scss']
})
export class TextBlockComponent implements BlockComponent {
  public tempText: string = '';
  _block: any;
  @Input()
  set block(block: any | undefined) {
    this._block = block;
    if (block && block.data && block.data.hasOwnProperty('text')) {
      this.tempText = block.data.text;
    } else {
      this.tempText = '';
    }
  }
  get block(): any | undefined {
    return this._block;
  }


  @Output() textView = new EventEmitter<any>();
  widget_Buttons_map = ['tile_open', 'tile_close', 'dial', 'email'];
  constructor() {

  }
  public onChange(data) {
    // const data = editor.getData();
    let block = JSON.parse(JSON.stringify(this.block));
    block.data.text = data;
    this._block.data.text = data;
    // this.block = block;
    this.textView.emit({ 'action': 'textView', block: block });
  }
}
