<app-form-iframe-url *ngIf="inqueryRedirect" [iframeLink]="inqueryRedirect" [iframeParentHeight]="'100vh'"
	iframeParentWidth="'100%'">
</app-form-iframe-url>
<div class="customTileView" *ngIf="!inqueryRedirect">
	<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
		*ngIf="tileStructureData && confirmData">
		<div fxFlex *ngIf="tileStructureData.blocksData">
			<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
				*ngFor="let tl of tileStructureData.blocksData;let index = index">
				<div fxFlex>
					<div *ngIf="tl.activate && tl.type === 'text' && tl.data.text" style="padding-buttom:20px;"
						[innerHtml]="tl.data.text | safeHtml"></div>
					<div *ngIf="tl.activate && tl.type === 'linkdescription' && tl.data.link"
						style="padding-buttom:20px;">
						<p style="text-align: center;margin: 2px 0px;">
							{{tl.data.name}}
						</p>
						<p style="text-align: center;margin: 2px 0px;">
							<a href="{{tl.data.link}}" class="" target="_blank">{{tl.data.link}}</a>
						</p>
						<p style="text-align: justify;margin: 2px 0px;">
							{{tl.data.description}}
						</p>
					</div>

					<ng-container *ngIf="tl.activate && tl.type === 'questionnaire'"
						[ngTemplateOutlet]="questionsTemplate" [ngTemplateOutletContext]="{ col: tl, index: index }">
					</ng-container>

					<ng-container *ngIf="tl.activate && tl.type === 'inquiry'" [ngTemplateOutlet]="inqueryTemplate"
						[ngTemplateOutletContext]="{ col: tl, index: index }">
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #inqueryTemplate let-col="col" let-index="index">
	<mat-form-field class="fullWidth">
		<textarea matInput placeholder="{{col.data.inquiryText | translate}}" name="{{col.type}}{{index}}"
			[(ngModel)]="confirmData[index]['data']['inquiryText']" cdkTextareaAutosize maxlength="3000"
			cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" [errorStateMatcher]="esMatcher">
		</textarea>
		<mat-hint align="end">{{confirmData[index]['data']['inquiryText']?.length || 0}}/3000</mat-hint>
	</mat-form-field>
</ng-template>
<!-- Questionare -->
<ng-template #questionsTemplate let-col="col" let-index="index">
	<!-- <div *ngIf="col.activate && col.type === 'text' && col.data.text" style="padding:20px 0px;color: #878787;" [innerHtml]="col.data.text | safeHtml"></div> -->
	<label class="example-radio-group-label">{{col.data.questionText | translate}}</label>
	<div class="fullWidth" *ngIf="col.data.options">
		<div *ngFor="let opt of col.data.options;let layer1index=index;">
			<div *ngIf="col.data.questionType === 'single'">
				<input type="radio" id="{{opt.option}}{{layer1index}}" name="layer1" value="{{opt.option}}"
					[checked]="confirmData[index]['data']['answered']"
					[(ngModel)]="confirmData[index]['data']['answered']">
				<label for="opt.option">{{opt.option}}</label><br>
			</div>
			<div *ngIf="opt.subQuestions" class="fullWidth">
				<ng-container *ngFor="let subQuestion of opt.subQuestions;let indexSub = index"
					[ngTemplateOutlet]="questionsTemplateLevel2"
					[ngTemplateOutletContext]="{ col: subQuestion, index: index, layer1index: layer1index, indexSub: indexSub }">
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #questionsTemplateLevel2 let-col="col" let-index="index" let-layer1index="layer1index"
	let-indexSub="indexSub">
	<label class="example-radio-group-label">{{col.questionText | translate}}</label>
	<div class="fullWidth" *ngIf="col.options">
		<div *ngFor="let opt of col.options;let layer2index=index;">
			<div *ngIf="col.questionType === 'single'">
				<input type="radio" id="{{opt.option}}{{layer2index}}" name="layer2" value="{{opt.option}}"
					[checked]="confirmData[index]['data']['answers'][layer1index]['subAnswers'][indexSub]['answered']"
					[(ngModel)]="confirmData[index]['data']['answers'][layer1index]['subAnswers'][indexSub]['answered']">
				<label for="opt.option">{{opt.option}}</label><br>
			</div>
			<div *ngIf="opt.subQuestions" class="fullWidth">
				<ng-container *ngFor="let subQuestion of opt.subQuestions;let indexSubSub = index"
					[ngTemplateOutlet]="questionsTemplateLevel3"
					[ngTemplateOutletContext]="{ col: subQuestion, index: index, layer1index: layer1index, indexSub: indexSub, layer2index: layer2index, indexSubSub: indexSubSub }">
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #questionsTemplateLevel3 let-col="col" let-index="index" let-layer1index="layer1index"
	let-indexSub="indexSub" let-layer2index="layer2index" let-indexSubSub="indexSubSub">
	<label class="example-radio-group-label">{{col.questionText | translate}}</label>
	<div class="fullWidth" *ngIf="col.options">
		<div *ngFor="let opt of col.options;let layer3index=index;">
			<div *ngIf="col.questionType === 'single'">
				<input type="radio" id="{{opt.option}}{{layer3index}}" name="layer3" value="{{opt.option}}"
					[checked]="confirmData[index]['data']['answers'][layer1index]['subAnswers'][indexSub]['subAnswers'][layer2index]['optionAnswers'][indexSubSub]['answered']"
					[(ngModel)]="confirmData[index]['data']['answers'][layer1index]['subAnswers'][indexSub]['subAnswers'][layer2index]['optionAnswers'][indexSubSub]['answered']">
				<label for="opt.option">{{opt.option}}</label><br>
			</div>
		</div>
	</div>
</ng-template>