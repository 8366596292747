<div class="col-xl-12" *ngIf="data">
	<div mat-dialog-content *ngIf="data.confirmData">
		<div *ngIf="fieldsDetails">
			<div class="mb-20 profile-page-header" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
				<!-- <div fxFlex="30">
					<figure class="card-profile-image">
						<img style="max-height: 300px;" [src]="pictureLink"
							class="circle z-depth-2 responsive-img activator">
					</figure>
				</div> -->
				<div fxFlex="100" class="topData topDataName">
					<div class="dataHead">{{data.confirmData['name']}}</div>
					<div class="dataHead">{{fieldsDetails['code']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['code']}}</span></div>
					<div class="dataHead">{{fieldsDetails['description']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['description']}}</span></div>
					<!-- <div class="dataHead">{{fieldsDetails['gender']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['gender']}}</span></div> -->
					<!-- <div class="dataHead">{{fieldsDetails['ethnicity']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['ethnicity']}}</span></div> -->
					<!-- <div class="dataHead">{{fieldsDetails['primary_language']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['primary_language']}}</span></div> -->
					<!-- <div class="dataHead">{{fieldsDetails['behavior']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['behavior']}}</span></div> -->
					<!-- <div class="dataHead">{{fieldsDetails['description']['displayName'] | translate}}:
						<br/><span 
						class="inlineText" innerHTML="{{data.confirmData['description']}}"></span></div> -->
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions>
		<div class="mb-20 btnList" fxLayout="row" fxLayout.lt-md="column" fxFlex
			*ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')">
			<div fxFlex>
				<button color="danger" mat-raised-button
					(click)="closeModal(undefined)">{{'Close' | translate}}</button>
				<button *ngIf="showEdit" mat-raised-button (click)="closeModal('edit')">
					{{'Edit' | translate}}
				</button>
			</div>
		</div>
	</div>
</div>