
<div>
	<form >
		<mat-form-field >
			<input
		   onkeydown="return false"
           [placeholder]= "placeHolder"
		   value ="{{getSelectedItems()}}"
           matInput
		   style="cursor:pointer"
		   #autoCompleteTrigger="matAutocompleteTrigger"
           [matAutocomplete]="auto">
			<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
				<mat-option disabled>Select {{placeHolder | translate}}</mat-option>
				<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
					<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
						<button mat-icon-button disabled></button>
						<mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
							(change)="todoLeafItemSelectionToggle(node)">{{node.displayName}}</mat-checkbox>
					</mat-tree-node>

					<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
						<button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
						<mat-checkbox [disabled]="isPatient" [checked]="descendantsSelected(node)"
							(change)="todoItemSelectionToggle(node)">
							{{node.displayName}}</mat-checkbox>
					</mat-tree-node>
				</mat-tree>
			</mat-autocomplete>
		</mat-form-field>
	</form>
</div>