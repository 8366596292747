// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// declare var CKEDITOR;

@Component({
	selector: 'kt-form-confirm-ckeditor-entity-dialog',
	templateUrl: './confirm-ckeditor-entity-dialog.component.html',
	styleUrls: ['../modal.scss']
})
export class ConfirmCkeditorEntityDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	arraySize = 1;
	editorConfig = undefined;
	widget_Buttons_map = ['dial', 'email'];
	public currentData: any;
	constructor(
		public dialogRef: MatDialogRef<ConfirmCkeditorEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		console.log(data);
		if (data.hasOwnProperty('arraySize')) {
			this.arraySize = data.arraySize || 1;
			if (this.arraySize > 1) {
				if (this.data.data.length === 0) {
					this.data.data = new Array(this.arraySize);
				}
			}
		}
		if (data.hasOwnProperty('data')) {
			if (!this.data.data) {
				this.currentData = '';
			} else {
				this.currentData = JSON.parse(JSON.stringify(this.data.data));
			}
		}
		if (data.hasOwnProperty('config')) {
			this.editorConfig = data.config;
		} else {
			this.editorConfig = {
				toolbar: {
					items: [
						'undo', 'redo',
						'|', 'heading',
						'|', 'bold', 'italic',
						'|', 'link', 'mediaEmbed', 'blockQuote',
						'|', 'bulletedList', 'numberedList', 'outdent', 'indent'
					],
					shouldNotGroupWhenFull: false,
					height: '310px',
				}
			}

		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		this.dialogRef.close(this.data.data);
	}

	public onChange(data, idx = undefined) {
		if (idx !== undefined) {
			this.data.data[idx] = data;
		} else {
			this.data.data = data;
		}
	}
	getNumberRange(end: number): number[] {
		return Array.from({ length: end }, (_, index) => index + 1);
	}
}
