// Angular
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorService } from '../angular-editor.service';

@Component({
	selector: 'video-insert-dialog',
	templateUrl: './video-insert-dialog.component.html',
	styleUrls: ['./video-insert-dialog.component.scss']
})
export class VideoInsertDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(private translate: TranslateService, private editorService: AngularEditorService,
		public dialogRef: MatDialogRef<VideoInsertDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		var pattern = /^(https?:\/\/)/;
		if (this.data.data !== '' && !pattern.test(this.data.data)) {
			this.editorService.showNotification(this.translate.instant('Please add https:// or http:// to the beginning'), this.translate.instant('Dismiss'));
			return;
		}
		if (this.data.data.match('www.youtube.com')) {
			// do nothing
		} else if (this.data.data.match('vimeo.com')) {
			// do nothing
		} else {
			this.editorService.showNotification(this.translate.instant('Please insert only Youtube and Vimeo videos'), this.translate.instant('Dismiss'));
			return;
		}
		this.dialogRef.close(this.data.data);
	}
	public onChange(data) {
		this.data.data = data;
	}
}
