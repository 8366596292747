import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  Renderer2, TemplateRef,
  ViewChild
} from '@angular/core';
import { AngularEditorService, UploadResponse } from './angular-editor.service';
import { HttpResponse, HttpEvent } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { CustomClass } from './config';
import { SelectOption } from './ae-select/ae-select.component';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { VideoInsertDialogComponent } from './video-insert-dialog/video-insert-dialog.component';
import { ImageInsertDialogComponent } from './image-insert-dialog/image-insert-dialog.component';
import { UrlInsertDialogComponent } from './url-insert-dialog/url-insert-dialog.component';

@Component({
  selector: 'angular-editor-toolbar',
  templateUrl: './angular-editor-toolbar.component.html',
  styleUrls: ['./angular-editor-toolbar.component.scss'],
})

export class AngularEditorToolbarComponent {
  htmlMode = false;
  linkSelected = false;
  block = 'default';
  fontName = '';
  fontSize = '12px';
  foreColour;
  backColor;

  headings: SelectOption[] = [
    {
      label: 'Heading 1',
      value: 'h1',
    },
    {
      label: 'Heading 2',
      value: 'h2',
    },
    {
      label: 'Heading 3',
      value: 'h3',
    },
    {
      label: 'Heading 4',
      value: 'h4',
    },
    {
      label: 'Heading 5',
      value: 'h5',
    },
    {
      label: 'Heading 6',
      value: 'h6',
    },
    {
      label: 'Heading 7',
      value: 'h7',
    },
    {
      label: 'Paragraph',
      value: 'p',
    },
    {
      label: 'Predefined',
      value: 'pre'
    },
    {
      label: 'Standard',
      value: 'div'
    },
    {
      label: 'default',
      value: 'default'
    }
  ];
  fontSizeOptions: SelectOption[] = [
    { label: '10px', value: '1' },
    { label: '12px', value: '2' },
    { label: '14px', value: '3' },
    { label: '16px', value: '4' },
    { label: '18px', value: '5' },
    { label: '20px', value: '6' },
    { label: '24px', value: '7' },
  ];
  // fontSizeOptions: SelectOption[] = [
  //   { label: '8px', value: '8px' },
  //   { label: '10px', value: '10px' },
  //   { label: '12px', value: '12px' },
  //   { label: '14px', value: '14px' },
  //   { label: '16px', value: '16px' },
  //   { label: '18px', value: '18px' },
  //   { label: '20px', value: '20px' },
  //   { label: '24px', value: '24px' },
  //   { label: '28px', value: '28px' },
  //   { label: '32px', value: '32px' },
  //   { label: '36px', value: '36px' },
  //   { label: '40px', value: '40px' },
  //   { label: '48px', value: '48px' },
  //   { label: '56px', value: '56px' },
  //   { label: '64px', value: '64px' },
  //   { label: '72px', value: '72px' },
  //   { label: '80px', value: '80px' },
  //   { label: '96px', value: '96px' },
  //   // Add more sizes as needed
  // ];
  fontNames: SelectOption[] = [
    { label: 'Default Font', value: '' },
    { label: 'Arial', value: 'Arial' },
    { label: 'Times New Roman', value: 'Times New Roman' },
    { label: 'Courier New', value: 'Courier New' },
  ]
  fontSizes: SelectOption[] = [
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
    {
      label: '4',
      value: '4',
    },
    {
      label: '5',
      value: '5',
    },
    {
      label: '6',
      value: '6',
    },
    {
      label: '7',
      value: '7',
    }
  ];

  customClassId = '-1';
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  _customClasses: CustomClass[];
  customClassList: SelectOption[] = [{ label: '', value: '' }];
  // uploadUrl: string;

  tagMap = {
    BLOCKQUOTE: 'indent',
    A: 'link'
  };

  select = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'PRE', 'DIV'];

  buttons = ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'justifyLeft', 'justifyCenter',
    'justifyRight', 'justifyFull', 'indent', 'outdent', 'insertUnorderedList', 'insertOrderedList', 'link'];

  @Input() id: string;
  @Input() uploadUrl: string;
  @Input() upload: (file: File) => Observable<HttpEvent<UploadResponse>>;
  @Input() showToolbar: boolean;
  @Input() fonts: SelectOption[] = [];

  @Input()
  set customClasses(classes: CustomClass[]) {
    if (classes) {
      this._customClasses = classes;
      this.customClassList = this._customClasses.map((x, i) => ({ label: x.name, value: i.toString() }));
      this.customClassList.unshift({ label: 'Clear Class', value: '-1' });
    }
  }

  @Input()
  set defaultFontName(value: string) {
    if (value) {
      this.fontName = value;
    }
  }

  @Input()
  set defaultFontSize(value: string) {
    if (value) {
      this.fontSize = value;
    }
  }

  @Input() hiddenButtons: string[][];

  @Output() execute: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileInput', { static: true }) myInputFile: ElementRef;

  public get isLinkButtonDisabled(): boolean {
    return this.htmlMode || !Boolean(this.editorService.selectedText);
  }

  constructor(
    private r: Renderer2,
    private editorService: AngularEditorService,
    private er: ElementRef, public dialog: MatDialog,
    @Inject(DOCUMENT) private doc: any
  ) {
  }

  /**
   * Trigger command from editor header buttons
   * @param command string from toolbar buttons
   */
  triggerCommand(command: string) {
    this.execute.emit(command);
  }

  /**
   * highlight editor buttons when cursor moved or positioning
   */
  triggerButtons() {
    if (!this.showToolbar) {
      return;
    }
    this.buttons.forEach(e => {
      const result = this.doc.queryCommandState(e);
      const elementById = this.doc.getElementById(e + '-' + this.id);
      if (result) {
        this.r.addClass(elementById, 'active');
      } else {
        this.r.removeClass(elementById, 'active');
      }
    });
  }

  /**
   * trigger highlight editor buttons when cursor moved or positioning in block
   */
  triggerBlocks(nodes: Node[]) {
    if (!this.showToolbar) {
      return;
    }
    this.linkSelected = nodes.findIndex(x => x.nodeName === 'A') > -1;
    let found = false;
    this.select.forEach(y => {
      const node = nodes.find(x => x.nodeName === y);
      if (node !== undefined && y === node.nodeName) {
        if (found === false) {
          this.block = node.nodeName.toLowerCase();
          found = true;
        }
      } else if (found === false) {
        this.block = 'default';
      }
    });

    found = false;
    if (this._customClasses) {
      this._customClasses.forEach((y, index) => {
        const node = nodes.find(x => {
          if (x instanceof Element) {
            return x.className === y.class;
          }
        });
        if (node !== undefined) {
          if (found === false) {
            this.customClassId = index.toString();
            found = true;
          }
        } else if (found === false) {
          this.customClassId = '-1';
        }
      });
    }
    found = false;
    if (this.fontSizeOptions) {
      this.fontSizeOptions.forEach((y, index) => {
        const node = nodes.find(x => {
          if (x instanceof Element) {
            // let styleAttribute = x.getAttribute('style');
            // const fontSizeMatch = /font-size\s*:\s*([^;]+)/.exec(styleAttribute);
            // if (fontSizeMatch) {
            //   let fontSize = fontSizeMatch[1].trim();
            //   return fontSize === y.value;
            // } else {
            //   return false;
            // }
            let styleAttribute = x.getAttribute('size');
            if (styleAttribute) {
              return styleAttribute === y.value.replace(/"/g, '');
            } else {
              return false;
            }
          }
        });
        if (node !== undefined) {
          if (found === false) {
            this.fontSize = y.value;
            found = true;
          }
        } else if (found === false) {
          this.fontSize = '12px';
        }
      });
    }
    found = false;
    if (this.fonts) {
      this.fonts.forEach((y, index) => {
        const node = nodes.find(x => {
          if (x instanceof Element) {
            // let styleAttribute = x.getAttribute('style');
            // const fontFamilyMatch = /font-family\s*:\s*([^;]+)/.exec(styleAttribute);
            // if (fontFamilyMatch) {
            //   let fontFamily = fontFamilyMatch[1].trim();
            //   return fontFamily === y.value.replace(/"/g, '');
            // } else {
            //   return false;
            // }
            let styleAttribute = x.getAttribute('face');
            // console.log('styleAttribute', styleAttribute);
            if (styleAttribute) {
              return styleAttribute === y.value.replace(/"/g, '');
            } else {
              return false;
            }
          }
        });
        if (node !== undefined) {
          if (found === false) {
            this.fontName = y.value.replace(/"/g, '');
            found = true;
          }
        } else if (found === false) {
          this.fontName = '';
        }
      });
      if (!found) {
        this.fontName = '';
      }
    }
    found = false;

    Object.keys(this.tagMap).map(e => {
      const elementById = this.doc.getElementById(this.tagMap[e] + '-' + this.id);
      const node = nodes.find(x => x.nodeName === e);
      if (node !== undefined && e === node.nodeName) {
        this.r.addClass(elementById, 'active');
      } else {
        this.r.removeClass(elementById, 'active');
      }
    });

    this.foreColour = this.doc.queryCommandValue('ForeColor');
    this.fontSize = this.doc.queryCommandValue('FontSize');
    // this.fontName = this.doc.queryCommandValue('FontName').replace(/"/g, '');
    this.backColor = this.doc.queryCommandValue('backColor');
  }
  /**
     * insert URL link
     */
  insertUrl() {
    let url = 'https:\/\/';
    const selection = this.editorService.savedSelection;
    if (selection && selection.commonAncestorContainer.parentElement.nodeName === 'A') {
      const parent = selection.commonAncestorContainer.parentElement as HTMLAnchorElement;
      if (parent.href !== '') {
        url = parent.href;
      }
    }
    url = prompt('Insert URL link', url);
    if (url && url !== '' && url !== 'https://') {
      this.editorService.createLink(url);
    }
  }
  /**
   * insert URL link
   */
  insertPopUpUrl() {
    let url = 'https:\/\/';
    const selection = this.editorService.savedSelection;
    if (selection && selection.commonAncestorContainer.parentElement.nodeName === 'A') {
      const parent = selection.commonAncestorContainer.parentElement as HTMLAnchorElement;
      if (parent.href !== '') {
        url = parent.href;
      }
    }
    this.execute.emit('');
    const dialogRef = this.dialog.open(UrlInsertDialogComponent, {
      width: '600px',
      disableClose: false,
      data: {
        title: 'Insert Link',
        data: url,
        placeholder: 'https://',
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(url => {
      if (url) {
        if (url && url !== '' && url !== 'https://') {
          this.editorService.restoreSelection();
          this.editorService.createLink(url);
        }
      }
    });
  }
  cleanUrl(url) {
    // Remove query parameters
    let cleanUrl = url.split('?')[0];

    // Remove hash fragment
    cleanUrl = cleanUrl.split('#')[0];

    return cleanUrl;
  }
  /**
   * insert Video link
   */
  insertVideo() {
    let vidUrl = '';
    const selection = this.editorService.savedSelection;
    let selectedContainer: any = undefined
    if (selection) {
      if (selection.commonAncestorContainer.nodeName === 'IFRAME') {
        selectedContainer = selection.commonAncestorContainer;
      } else if (selection.commonAncestorContainer.firstChild && selection.commonAncestorContainer.firstChild.nodeName === 'IFRAME') {
        selectedContainer = selection.commonAncestorContainer.firstChild;
      }
    }
    if (selectedContainer) {
      if (selectedContainer.src !== '') {
        vidUrl = this.cleanUrl(selectedContainer.src);

      }
    }
    this.execute.emit('');
    const dialogRef = this.dialog.open(VideoInsertDialogComponent, {
      width: '600px',
      disableClose: false,
      data: {
        title: 'Insert Video',
        data: vidUrl,
        placeholder: 'https://',
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.editorService.restoreSelection();
        this.editorService.insertVideo(result);
      }
    });
  }
  /**
   * insert Image link
   */
  insertImage() {
    let img = { imageUrl: '', width: '100%', height: 'auto', targetUrl: '', target: '_blank' };
    const selection = this.editorService.savedSelection;
    let selectedContainer: any = undefined
    if (selection) {
      if (selection.commonAncestorContainer.nodeName === 'IMG') {
        selectedContainer = selection.commonAncestorContainer;
      } else if (selection.commonAncestorContainer.firstChild && selection.commonAncestorContainer.firstChild.nodeName === 'IMG') {
        selectedContainer = selection.commonAncestorContainer.firstChild;
      }
    }
    if (selectedContainer) {
      if (selectedContainer.src !== '') {
        img.imageUrl = selectedContainer.src;
      }
    }
    if (selection && selection.commonAncestorContainer.nodeName === 'A') {
      const parent = selection.commonAncestorContainer as HTMLAnchorElement;
      if (parent.href !== '') {
        img.targetUrl = parent.href;
      }
      if (parent.target !== '') {
        img.target = parent.target;
      }
    }
    this.execute.emit('');
    const dialogRef = this.dialog.open(ImageInsertDialogComponent, {
      width: '600px',
      disableClose: false,
      data: {
        title: 'Insert Image',
        placeholder: '',
        data: img,
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.editorService.restoreSelection();
        this.editorService.insertImageObject(result);
      }
    });
  }
  insertVideoOld() {
    this.execute.emit('');
    const url = prompt('Insert Video link', `https://`);
    if (url && url !== '' && url !== `https://`) {
      this.editorService.insertVideo(url);
    }
  }

  /** insert color */
  insertColor(color: string, where: string) {
    this.execute.emit('');
    this.editorService.insertColor(color, where);
  }

  /**
   * set font Name/family
   * @param foreName string
   */
  setFontName(foreName: string): void {
    this.execute.emit('');
    this.editorService.setFontName(foreName);
  }

  /**
   * set font Name/family
   * @param foreName string
   */
  setFontNameCss(foreName: string): void {
    this.execute.emit('');
    this.editorService.setFontNameCss(foreName);
  }

  /**
   * set font Size
   * @param fontSize string
   */
  setFontSize(fontSize: string): void {
    this.execute.emit('');
    this.editorService.setFontSize(fontSize);
  }
  /**
   * set font Size
   * @param fontSize string
   */
  setFontSizeCss(fontSize: string): void {
    this.execute.emit('');
    this.editorService.setFontSizeCss(fontSize);
  }

  /**
   * toggle editor mode (WYSIWYG or SOURCE)
   * @param m boolean
   */
  setEditorMode(m: boolean) {
    const toggleEditorModeButton = this.doc.getElementById('toggleEditorMode' + '-' + this.id);
    if (m) {
      this.r.addClass(toggleEditorModeButton, 'active');
    } else {
      this.r.removeClass(toggleEditorModeButton, 'active');
    }
    this.htmlMode = m;
  }

  /**
   * Upload image when file is selected.
   */
  onFileChanged(event) {
    const file = event.target.files[0];
    if (file && file.type.includes('image/')) {
      if (this.upload) {
        this.upload(file).subscribe((response: HttpResponse<UploadResponse>) => this.watchUploadImage(response, event));
      } else if (this.uploadUrl) {
        this.editorService.uploadImage(file).subscribe((response: HttpResponse<UploadResponse>) => this.watchUploadImage(response, event));
      } else {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent) => {
          const fr = e.currentTarget as FileReader;
          this.editorService.insertImage(fr.result.toString());
        };
        reader.readAsDataURL(file);
      }
    }
  }

  watchUploadImage(response: HttpResponse<{ imageUrl: string }>, event) {
    const { imageUrl } = response.body;
    this.editorService.insertImage(imageUrl);
    event.srcElement.value = null;
  }

  /**
   * Set custom class
   */
  setCustomClass(classId: string) {
    if (classId === '-1') {
      this.execute.emit('clear');
    } else {
      this.editorService.createCustomClass(this._customClasses[+classId]);
    }
  }

  isButtonHidden(name: string): boolean {
    if (!name) {
      return false;
    }
    if (!(this.hiddenButtons instanceof Array)) {
      return false;
    }
    let result: any;
    for (const arr of this.hiddenButtons) {
      if (arr instanceof Array) {
        result = arr.find(item => item === name);
      }
      if (result) {
        break;
      }
    }
    return result !== undefined;
  }

  focus() {
    this.execute.emit('focus');
    // console.log('focused');
  }
}
