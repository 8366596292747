import { Directive, ElementRef, Renderer2, OnInit, HostListener } from '@angular/core';

@Directive({
    selector: '[appResizeable]',
})
export class ResizeableDirective implements OnInit {
    private resizing = false;
    private startX: number;
    private startY: number;
    private resizeElement: HTMLElement;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit(): void {
        this.addResizeHandle();
    }

    makeResizable(element: HTMLElement): void {
        let specificElement = element.querySelector('.resize-handle');
        if (!specificElement) {
            this.addResizeHandle(element);
        }
    }

    private addResizeHandle(element?: HTMLElement): void {
        const resizeElement = element || this.el.nativeElement;

        const handle = this.renderer.createElement('div');
        this.renderer.addClass(handle, 'resize-handle');
        this.renderer.appendChild(resizeElement, handle);

        this.renderer.listen(handle, 'mousedown', (event: MouseEvent) => {
            event.stopPropagation();
            this.onMouseDown(event, resizeElement);
        });

        this.renderer.listen(resizeElement, 'click', (event: MouseEvent) => {
            event.stopPropagation();
            this.makeResizable(resizeElement);
        });
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent): void {
        if (this.resizing) {
            const deltaX = event.clientX - this.startX;
            const deltaY = event.clientY - this.startY;

            const newWidth = this.resizeElement.offsetWidth + deltaX;
            const newHeight = this.resizeElement.offsetHeight + deltaY;

            this.renderer.setStyle(this.resizeElement, 'width', `${newWidth}px`);
            this.renderer.setStyle(this.resizeElement, 'height', `${newHeight}px`);

            this.startX = event.clientX;
            this.startY = event.clientY;
        }
    }

    @HostListener('document:mouseup')
    onMouseUp(): void {
        if (this.resizing) {
            this.resizing = false;
        }
    }

    private onMouseDown(event: MouseEvent, element: HTMLElement): void {
        event.preventDefault();
        this.resizing = true;
        this.startX = event.clientX;
        this.startY = event.clientY;
        this.resizeElement = element;
    }
}