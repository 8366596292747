import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BlockComponent, DefaultSettings } from '../block-organizer';

@Component({
  selector: 'questions-block',
  templateUrl: './questions-block.component.html',
  styleUrls: ['../tileblocks.component.scss', './questions-block.component.scss']
})
export class QuestionsBlockComponent implements BlockComponent {
  @Input() block: any;
  @Output() questionsView = new EventEmitter<any>();

  answerTypes: any[] = DefaultSettings.answerTypes;

  constructor() { }

  trackByIndex(index: number, obj: any): any {
    return index;
  };

  addQuestions(e: any) {
    this.block.data.notes.push(false);
    this.block.data.answerTypes.push("text");
    this.block.data.mandatory.push(false);
    this.block.data.questions.push("");
    this.onChange(e);
    // this.block.data.tags.push({ tag: "", tagText: "" });
  };
  public onSelectCat(e: any) {
    this.block.data.category = e._id
    this.onChange(e);
  }
  public onChange(e: any) {
    // console.log('block', this.block);
    this.questionsView.emit({ 'action': 'questionsView', block: this.block });
  }
}
