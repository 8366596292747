import { NgModule } from '@angular/core';
import { AngularEditorComponent } from './angular-editor.component';
import { AngularEditorToolbarComponent } from './angular-editor-toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AeSelectComponent } from './ae-select/ae-select.component';
import { AeButtonComponent } from "./ae-button/ae-button.component";
import { AeToolbarSetComponent } from './ae-toolbar-set/ae-toolbar-set.component';
import { SharedModule } from '../../../../shared.module';
import { VideoInsertDialogComponent } from './video-insert-dialog/video-insert-dialog.component';
import { ImageInsertDialogComponent } from './image-insert-dialog/image-insert-dialog.component';
import { AngularEditorService } from './angular-editor.service';
import { UrlInsertDialogComponent } from './url-insert-dialog/url-insert-dialog.component';
import { EditorLoadingScreenComponent } from './editor-loading-screen/editor-loading-screen.component';

@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, SharedModule
  ],
  declarations: [AngularEditorComponent, AngularEditorToolbarComponent, AeSelectComponent, AeButtonComponent, AeToolbarSetComponent, VideoInsertDialogComponent, ImageInsertDialogComponent, UrlInsertDialogComponent, EditorLoadingScreenComponent],
  exports: [AngularEditorComponent, AngularEditorToolbarComponent, AeButtonComponent, AeToolbarSetComponent, VideoInsertDialogComponent, ImageInsertDialogComponent, UrlInsertDialogComponent, EditorLoadingScreenComponent],
  providers: [AngularEditorService]
})
export class AngularEditorModule {
}
