<div mat-dialog-content>
	<h3>{{data.title}}</h3>
	<div fxLayout="column" style="min-height: 40vh;">
		<!-- <div class="ImageTabContainer" *ngIf="!imageUrlPath">
			<img src="{{pictureLink}}">
		</div> -->
		<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="{{allowedAcceptExtensions}}"
			multiple="false" id="image_selector" (change)="onBrowseFiles($event.target)">
		<!-- pictureLink{{pictureLink}} -->
		<div *ngIf="imageUrlPath">
			<image-cropper style="height:300px" [imageBase64]="imageUrlPath"
				[disabled]="pictureLink == 'assets/images/profile.png'" [maintainAspectRatio]="!(selectedRatio === 0)"
				[aspectRatio]="selectedRatio" [format]="imageExtension" [autoCrop]="false"
				(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
				(loadImageFailed)="loadImageFailed()"></image-cropper>
		</div>
		<div style="margin: 0 auto;" *ngIf="!imageUrlPath && !loading">
			<img [src]="defaultPictureLink" style="height:300px; width: 300px;" />
		</div>
	</div>
</div>
<div mat-dialog-actions align="start">
	<button mat-raised-button class="primary" (click)="closeModal({base64Image: pictureLink})">{{'Cancel' |
		translate}}</button>
	<button mat-raised-button (click)="imageInput.click()" class="primary" [disabled]="loading">{{'Browse Files' |
		translate}}</button>
	<button mat-raised-button (click)="cropIt()" [disabled]="loading ||
		pictureLink=='assets/images/profile.png'">{{'Save' |
		translate}}</button>
	<button mat-raised-button (click)="clearIt($event)"
		[disabled]="loading || pictureLink == 'assets/images/profile.png'">{{'Clear' | translate}}</button>
</div>