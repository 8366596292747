import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormUtils } from '../../helpers/utils';
import { FormLoaderService, FormLayoutUtilsService, FormRequestService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { DataAssignDialogComponent } from '../data-assign-dialog/data-assign-dialog.component';
import { DataListComponent } from '../data-list/data-list.component';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { MatInput } from '@angular/material/input';

class MyUserErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-score-tile-data',
  templateUrl: './score-tile-data.component.html',
  styleUrls: ['./score-tile-data.component.scss']
})
export class ScoreTileDataComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public originalTableSetting: any = undefined;
  public tableSetting: any = undefined;
  public errorMessage: string = '';
  public filterObject: any = undefined;
  public scopes: string[] = [];
  public defaultFields: string[] = ['name', 'title', 'selectedTiles', 'tileId', 'weight', 'organizationId'];
  public fieldsObject: string[] = JSON.parse(JSON.stringify(this.defaultFields));

  public dataChanged: boolean = false;
  public loading: boolean = false;
  public tableName: string = 'qascore';
  public dataType: string = 'qascore';
  public dataTypeDisplay: string = this.translate.instant('Score Reports');
  public UIDisplay: string = this.translate.instant('Score Report');

  public esMatcher = new MyUserErrorStateMatcher();
  public questionsType: any = {
    survey: 'Simple Questionnaire',
    questionnaire: 'Cascading Questionnaire',
    painlevel: 'Pain Level'
  }

  public tileStructure: any[] = [];
  public selectedDataEmpty: any = {
    title: '',
    tileId: [],
    selectedTiles: [],
    weight: {},
    organizationId: ''
  };

  myTitleInputControl = new FormControl('', [Validators.required, Validators.minLength(3)]);

  public selectedOriginalData: any = JSON.parse(JSON.stringify(this.selectedDataEmpty));
  public selectedData: any = JSON.parse(JSON.stringify(this.selectedDataEmpty));
  public selectedDataKeysType: any = {
    title: { name: 'title', displayName: 'title', type: 'string', valueType: 'string', check: true },
    tileId: { name: 'tileId', displayName: 'Tile Id', type: 'array', valueType: 'string', check: false },
    selectedTiles: { name: 'selectedTiles', displayName: 'Selected Tiles', type: 'array', valueType: 'array', check: true },
  };
  public selectedDataKeysTypeList = Object.keys(this.selectedDataKeysType);
  public selectedUser: any;
  @Input() showSelfLoader: boolean = true;
  @Input() isAdmin: boolean = false;
  @Input() isSuperAdmin: boolean = false;
  @Output() onSaveReturn = new EventEmitter<any>();
  public searchVal: string = '';
  @ViewChild('dataList') data_list: DataListComponent;
  @ViewChild('title') title: ElementRef;
  constructor(
    private formRequestService: FormRequestService,
    private layoutUtilsService: FormLayoutUtilsService,
    private utils: FormUtils,
    public dialog: MatDialog, private loaderService: FormLoaderService, private translate: TranslateService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.formRequestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
        }
      })
    );
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  toggleClick(action) {
    if (action === 'new') {
      this.newDataSelect();
    } else if (action === 'delete') {
      this.data_list.delete(undefined, this.selectedData['_id']);
      this.newReport();
      // } else if (action === 'duplicate') {
      //   this.duplicate();
    } else if (action === 'save') {
      this.save(true);
    }
  }
  dataActionReturn(actions) {
    if (actions === 'dataReset') {
      this.newReport();
    }
  }
  cleanReportWeightsToSave(weight) {
    for (let tile of this.tileStructure) {
      if (tile.widgets) {
        for (let widget of tile.widgets) {
          if (widget.questions) {
            if (this.isDataArray(widget.questions)) {
              for (let question of widget.questions) {
                if (question['include'] === false) {
                  if (weight[tile.id] && weight[tile.id][widget._id] && weight[tile.id][widget._id][question.id]) {
                    delete weight[tile.id][widget._id][question.id];
                  }
                }
              }
            } else {
              if (widget.questions['include'] === false) {
                if (weight[tile.id] && weight[tile.id][widget._id] && weight[tile.id][widget._id][widget.questions.id]) {
                  delete weight[tile.id][widget._id][widget.questions.id];
                }
              }
            }
          }
        }
      }
    }
    for (let tileId of Object.keys(weight)) {
      for (let widgetId of Object.keys(weight[tileId])) {
        for (let questionId of Object.keys(weight[tileId][widgetId])) {
          let haveOption = false;
          for (let option of weight[tileId][widgetId][questionId]) {
            if (option !== '') {
              haveOption = true;
            }
          }
          if (!haveOption) {
            delete weight[tileId][widgetId][questionId];
          }
        }
        if (Object.keys(weight[tileId][widgetId]).length === 0) {
          delete weight[tileId][widgetId];
        }
      }
      if (Object.keys(weight[tileId]).length === 0) {
        delete weight[tileId];
      }
    }
    return weight;
  }

  dataSave(currentSelectedData, callback, refreshFromDb = false) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      let selectedData = JSON.parse(JSON.stringify(currentSelectedData))
      selectedData['organizationId'] = this.formRequestService.orgId;
      selectedData['tileId'] = selectedData['selectedTiles'].map((itm) => {
        return itm._id;
      });
      // selectedData['weight'] = this.cleanReportWeightsToSave(selectedData['weight']);
      selectedData['title'] = selectedData['title'].trim();
      this.formRequestService.saveDataNoPerfix(this.dataType, selectedData, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
        }
        this.loading = false;
        this.loaderService.display(false);
        if (data) {
          if (data.results && refreshFromDb) {
            this.selectItem(data.results);
          }
          this.data_list.loadData();
          this.layoutUtilsService.showNotification(this.dataTypeDisplay + this.translate.instant(" Saved Successfully"), this.translate.instant("Dismiss"));
          if (callback) {
            callback();
          }
        }
      });
    }
  }

  newTile(message: any, yesBtn: any, noBtn: any, cb: any) {
    this.utils.iAlertConfirm("confirm", "Confirm", message, yesBtn, noBtn, (res) => {
      cb(res["value"]);
    });
  };
  dataIsChanged() {
    for (let ky of this.selectedDataKeysTypeList) {
      if (this.selectedDataKeysType[ky].check === true) {
        let val1 = this.selectedOriginalData[ky];
        let val2 = this.selectedData[ky];
        if (this.selectedDataKeysType[ky].valueType === 'array') {
          if (val1.length !== val2.length) {
            return true;
          }
        } else {
          if (val1 !== val2) {
            return true;
          }
        }
      }
    }
    return this.dataChanged;
  }
  newDataSelect() {
    if (this.dataIsChanged()) {
      this.newTile('Would you like to save your previous work?', 'Save', 'Discard', (value) => {
        if (value === 'Save') {
          this.save(false, () => {
            this.newReport();
          });
        } else if (value === 'Discard') {
          this.newReport();
        }
      });
    } else {
      this.newReport();
    }
  }
  newReport() {
    this.selectedOriginalData = JSON.parse(JSON.stringify(this.selectedDataEmpty));
    this.selectedData = JSON.parse(JSON.stringify(this.selectedDataEmpty));
    this.tileStructure = [];
    this.dataChanged = false;
  }
  selectItemCheck(data) {
    let currScoreExists: any = !this.utils.isEmptyObject(this.selectedData) ? Object.assign({}, this.selectedData) : {};
    if (!this.utils.isEmptyObject(currScoreExists) && currScoreExists.hasOwnProperty("_id")) {
      if (this.dataIsChanged()) {
        this.newTile('Would you like to save your work and select?', 'Yes', 'No', (value) => {
          if (value === 'Yes') {
            this.save(false, () => {
              this.selectItem(data);
            });
          } else if (value === 'No') {
            this.selectItem(data);
          }
        });
      } else {
        this.selectItem(data);
      }
    } else {
      this.selectItem(data);
    }
  }
  selectItem(data) {
    console.log('selectItem', data);
    if (!data.hasOwnProperty('selectedTiles')) {
      let tilesIdList = JSON.parse(JSON.stringify(data['tileId']));
      data['selectedTiles'] = tilesIdList.map((itm) => {
        return { _id: itm, name: '' }
      });
    } // remove this after migration
    this.dataChanged = false;
    this.selectedData = JSON.parse(JSON.stringify(data));
    this.selectedOriginalData = JSON.parse(JSON.stringify(data));
    this.buildTilesStructure(data['tileId']);
  }
  public isValid() {
    if (this.utils.isEmptyObject(this.selectedData.title)) {
      this.title.nativeElement.focus();
      return "Title is required.";
    } else if (this.selectedData.selectedTiles.length === 0) {
      return "Tile is required.";
    }
    return undefined;
  }
  public save(refreshFromDb = false, callback = undefined) {
    // this.title.nativeElement.markAsTouched();
    let returnMessage = this.isValid();
    if (!returnMessage) {
      this.dataSave(this.selectedData, () => {
        if (callback) {
          callback();
        }
      }, refreshFromDb);
    } else {
      this.layoutUtilsService.showNotification(this.translate.instant(returnMessage), this.translate.instant('Dismiss'));
    }
  }

  selectTiles() {
    let title = 'Assign Tiles';
    let disableClose = false;
    let selectedTiles = this.selectedData.selectedTiles.map((itm) => {
      return { _id: itm._id, text: itm.name }
    });
    const dialogRef = this.dialog.open(DataAssignDialogComponent, {
      width: '1600px',
      disableClose: disableClose,
      data: {
        targetDataType: 'tile',
        targetDataTypeDisplay: this.translate.instant('Tiles'),
        dataType: 'tile',
        title: title,
        selectedData: selectedTiles,
        filters: {
          '$and': [
            { 'organizationId': { '$eq': this.formRequestService.orgId } }
          ]
        },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedData.selectedTiles = result.map((itm) => {
          return { _id: itm._id, name: itm.text }
        });
        let tilesId = result.map((itm) => {
          return itm._id
        });
        this.buildTilesStructure(tilesId);
      }
    });
  }
  public buildTilesStructure(tilesId) {
    if (!this.loading) {
      this.loading = true;

      this.loaderService.display(true);
      this.errorMessage = '';
      this.formRequestService.getTileDataStructure(tilesId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        if (data) {
          let tilesStructure = data.results;
          this.tileStructure = this.prepareTileStructure(tilesStructure.tiles || []);
          console.log('tilesStructure', this.tileStructure);
        }
        this.loading = false;
        this.loaderService.display(false);
      });
    }
  }
  prepareTileStructure(tiles) {
    for (let tile of tiles) {
      tile['collapse'] = false;
      if (tile.widgets) {
        for (let widget of tile.widgets) {
          if (widget.questions) {
            if (this.isDataArray(widget.questions)) {
              for (let question of widget.questions) {
                question['include'] = this.readIncludeDbData(tile.id, widget._id, question.id);
                if (question.options) {
                  for (let option of question.options) {
                    option['name'] = option['name'].replace(/&apos;/g, "'");
                    let weight = this.readDbData(tile.id, widget._id, question.id, option.id);
                    // if (weight !== '') {
                    //   question['include'] = true;
                    // }
                    option['weight'] = weight;
                  }
                }
              }
            } else {
              widget.questions['include'] = this.readIncludeDbData(tile.id, widget._id, widget.questions.id);
              if (widget.questions.options) {
                for (let option of widget.questions.options) {
                  if (typeof option['name'] === 'string') {
                    option['name'] = option['name'].replace(/&apos;/g, "'");
                  }
                  let weight = this.readDbData(tile.id, widget._id, widget.questions.id, option.id);
                  // if (weight !== '') {
                  //   widget.questions['include'] = true;
                  // }
                  option['weight'] = weight;
                }
              }
            }
          }
        }
      }
    }
    return tiles;
  }
  readDbData(tileId, widgetId, questionId, optionId) {
    let weight = '';
    if (this.selectedData.weight) {
      if (this.selectedData.weight.hasOwnProperty(tileId)) {
        if (this.selectedData.weight[tileId].hasOwnProperty(widgetId)) {
          if (this.selectedData.weight[tileId][widgetId].hasOwnProperty(questionId)) {
            if (this.selectedData.weight[tileId][widgetId][questionId]) {
              if (optionId >= 0 && optionId < this.selectedData.weight[tileId][widgetId][questionId].length) {
                weight = this.selectedData.weight[tileId][widgetId][questionId][optionId];
              }
            }
          }
        }
      }
    }
    return weight
  }
  readIncludeDbData(tileId, widgetId, questionId) {
    let include = false;
    if (this.selectedData.include) {
      if (this.selectedData.include.hasOwnProperty(tileId)) {
        if (this.selectedData.include[tileId].hasOwnProperty(widgetId)) {
          if (this.selectedData.include[tileId][widgetId].hasOwnProperty(questionId)) {
            include = this.selectedData.include[tileId][widgetId][questionId];
          }
        }
      }
    }
    return include
  }
  isDataArray(data): boolean {
    return Array.isArray(data);
  }
  public onChange(value, tileId, widgetId, questionId, optionId, optionsLength) {
    if (this.selectedData.weight) {
      if (!this.selectedData.weight.hasOwnProperty(tileId)) {
        this.selectedData.weight[tileId] = {};
      }
      if (!this.selectedData.weight[tileId].hasOwnProperty(widgetId)) {
        this.selectedData.weight[tileId][widgetId] = {};
      }
      if (!this.selectedData.weight[tileId][widgetId].hasOwnProperty(questionId)) {
        this.selectedData.weight[tileId][widgetId][questionId] = Array.from({ length: optionsLength }, () => '');
      }
      if (optionId >= 0 && optionId < this.selectedData.weight[tileId][widgetId][questionId].length) {
        this.dataChanged = true;
        this.selectedData.weight[tileId][widgetId][questionId][optionId] = value;
      }
    }
  }
  public onChangeInclude(value, tileId, widgetId, questionId) {
    if (!this.selectedData.hasOwnProperty('include')) {
      this.selectedData['include'] = {};
    }
    if (!this.selectedData.include.hasOwnProperty(tileId)) {
      this.selectedData.include[tileId] = {};
    }
    if (!this.selectedData.include[tileId].hasOwnProperty(widgetId)) {
      this.selectedData.include[tileId][widgetId] = {};
    }
    if (!this.selectedData.include[tileId][widgetId].hasOwnProperty(questionId)) {
      this.selectedData.include[tileId][widgetId][questionId] = false;
    }
    this.selectedData.include[tileId][widgetId][questionId] = value;
  }
}
