import { Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  public subscriptions: any[] = <any>[];
  public showLoader: boolean = false;

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.spinnerService.isLoadingSubject.subscribe((val: boolean) => {
        setTimeout(() => { this.showLoader = val; }, 0); //fix for expressionchanged error 
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
