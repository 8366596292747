import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-generic-note-modal',
  templateUrl: './add-generic-note-modal.component.html',
  styleUrls: ['./add-generic-note-modal.component.scss'],
})
export class AddGenericNoteDialogComponent implements OnInit {
  public note: string;

  constructor(
    public dialogRef: MatDialogRef<AddGenericNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() { }

  submit() {
    this.dialogRef.close({ text: this.note, status: true });
  }

  close() {
    this.dialogRef.close({ text: '', status: false });
  }
}
