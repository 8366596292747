<!-- prettier-ignore -->
<form #personForm="ngForm" *ngIf="data" class="customDialog" >
	<div class="col-xl-12" *ngIf="data" >
		<h1 mat-dialog-title>{{data.title}}</h1>
		<div mat-dialog-content (scroll)="closePanels()" #dialogWindow>
			<div *ngIf="data.modalSetting" >
				<mat-card *ngIf="data.modalSetting.fields">
					<!-- <mat-card-header>
						<mat-card-title>{{data.type}}</mat-card-title>
					</mat-card-header> -->
					<mat-card-content>
						<div class="form-group" >

							<div *ngFor="let col of data.modalSetting.fields" class="fieldItem">
								<div *ngIf="col.type !== 'action' && (col.editable || !col.generated) && col.creatable" >
									<span class="fieldInput">
										<div
										*ngIf="(col.visible || ((col.admin || false) && isAdmin)) && col.type === 'array' && col.name != 'address'">
										<div class="add-row">
											<mat-icon (click)="addMappingReference(col)" class="add-row" [matTooltip]="'Add' | translate">add_circle</mat-icon>
										</div>
										<mat-table [dataSource]="data.data[col.name]" class="mat-elevation-z8 table mapped-table">
											<ng-container *ngFor="let property of col.fields">
												<ng-container matColumnDef="{{property.name}}" *ngIf="property.name != 'action'">
													<mat-header-cell *matHeaderCellDef  width="30%">{{property.displayName}}</mat-header-cell>
													<mat-cell *matCellDef="let element; let i = index">
															<input matInput type="number" *ngIf="data.data[col.name][i]" 
															[required]="!property.nullable" name="{{property.name}}_{{i}}"
																ng-model="property.name" [(ngModel)]="data.data[col.name][i][property.name]"
																[required]="!property.nullable" class="map-input"
																[errorStateMatcher]="esMatcher">
															<mat-error
																*ngIf="!property.nullable && data.data[col.name][i] && (data.data[col.name][i][property.name] === undefined || data.data[col.name][i][property.name] === '')">
																{{property.displayName | translate}} is required.
															</mat-error>
													</mat-cell> 
												</ng-container>
											</ng-container>
											<ng-container matColumnDef="action">
												<mat-header-cell *matHeaderCellDef width="10%"></mat-header-cell>
												<mat-cell *matCellDef="let i = index"> 
													<div class="add-row">
														<mat-icon color="primary" matTooltip="Delete" (click)="deleteRow(col.name,i)" >delete</mat-icon>
													</div>
												</mat-cell>
											</ng-container>
											<mat-header-row *matHeaderRowDef="col.displayColumn"></mat-header-row>
											<mat-row *matRowDef="let row; columns: col.displayColumn;" [id]="row._id"></mat-row>
										</mat-table>
									</div>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'string' && col.name!= 'longitude' && col.name!= 'latitude'">

											<input
												[readonly]="data.dataType == 'address' && (col.name == 'street' || col.name == 'number' || col.name == 'city' || col.name == 'country' || col.name == 'state' || col.name == 'zipcode')"
												matInput [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" class='link'
												maxlength="{{col.charlimit ? col.charlimit : 1000}}"
												[ngClass]="{'noCapital': col.name == 'userName'}"
												(keydown)="blockSpaceButton($event,col.name)"
												(change)="removeSpacesFromWords($event,col.name)">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'number'">
											<input matInput type="number" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'email'">
											<input matInput type="email" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher"
												pattern="^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
												[disabled]="data.data._id && col.unique">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'url'">
											<input matInput type="text" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher"
												pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"
												[disabled]="data.data._id && col.unique">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'phone'">
											<input matInput type="phone" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'color'">
											<input matInput type="color" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="col.type === 'password' && data.subDataType != 'patient'">
											<input matInput type="password" [required]="!col.nullable"
												name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" autocomplete="new-password">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<div *ngIf="col.type === 'password' && data.subDataType == 'patient'" [formGroup]="passwordForm" style="margin-bottom:15px;" >
											<mat-form-field>
												<input matInput type="password" name="password"
													formControlName="password" placeholder="{{col.displayName | translate}}" required="true" ng-model="password" [(ngModel)]="data.data.password">
													<mat-error *ngIf="passwordForm.controls.password.errors?.required">
														Password is <strong>required</strong>
													</mat-error>
												<mat-error *ngIf="passwordForm.controls.password.errors?.pattern">
													Your password must contain at least 8 characters including lower and upper case
													letters, numbers, and special characters.
												</mat-error>
												<mat-hint>Your password must contain at least 8 characters including lower and upper case
													letters, numbers, and special characters.</mat-hint>
											</mat-form-field>
										</div>
										



										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'textarea'">
											<mat-form-field class="small-t">
												<textarea matInput placeholder="{{col['displayName'] | translate}}"
													name="{{col['name']}}" [required]="!col['nullable']"
													maxlength="3000" [(ngModel)]="data.data[col.name]"
													ng-model="col['name']" cdkTextareaAutosize cdkAutosizeMinRows="3"
													cdkAutosizeMaxRows="5" [errorStateMatcher]="esMatcher">
													</textarea>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col['displayName'] | translate}} is required.
												</mat-error>
											</mat-form-field>
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'textareaSpan'">
											<mat-form-field class="small-t">
												<span>{{col.displayName | translate}}</span>
												<br><br>
												<textarea matInput name="{{col['name']}}" [required]="!col['nullable']"
													maxlength="3000" [(ngModel)]="data.data[col.name]"
													ng-model="col['name']" cdkTextareaAutosize cdkAutosizeMinRows="3"
													cdkAutosizeMaxRows="5" [errorStateMatcher]="esMatcher">
													</textarea>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col['displayName'] | translate}} is required.
												</mat-error>
											</mat-form-field>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'boolean' && col.name !== 'privacyterm'"
											style="margin-bottom:15px;">
											<mat-checkbox class="example-margin" name="{{col.name}}" (change)="refreshData()"
												[(ngModel)]="data.data[col.name]">{{col.displayName}}</mat-checkbox>
											<!-- <mat-radio-group [value]="data.data[col.name]" name="{{col.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
									        <mat-radio-button class="example-margin" value="false">False</mat-radio-button>
									        <mat-radio-button class="example-margin" value="true">True</mat-radio-button>
									      </mat-radio-group> -->
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'tags'">
											<app-custom-tags [parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name]" [required]="!col.nullable"
												[placeholder]="col.displayName"
												(onSelectReturn)="setAttribute(col.name, $event, undefined)"
												[itemName]="col.name">
											</app-custom-tags>
										</div>
										<div
											*ngIf="(col.visible || ((col.admin || false) && isAdmin)) && col.type === 'reference'">
											<app-custom-select
												*ngIf="data.data[col.name] && col.reference.kind === 'single'"
												[parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]['_id']"
												[itemName]="col.name" [required]="!col.nullable"
												[dataType]="col.reference.to" [apiTarget]="col.reference.api"
												[placeholder]="col.displayName" [subDataType]="col.subDataType"
												(onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)">

											</app-custom-select>

											<app-custom-multiple-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple'"
												[value]="data.data[col.name]" [itemName]="col.name"
												[parentFormSubmitted]="isSubmitted"
												[subDataType]="col.subDataType" [required]="!col.nullable"
												[dataType]="col.reference.to" [RelativeTo]='RelativeTo'
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onSelectReturn)="setMultipleReferenceAttribute(col.name, $event, undefined)">
											</app-custom-multiple-select>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'picturearray'"
											class="picturearray" style="display: inline-block;">
											<label class="control-label" *ngIf="col.name">{{col.displayName}}<span
													*ngIf="!col.nullable"> *</span>:
												<mat-icon (click)="fileselect.click()" class="newRow" title="Add Image">
													add_circle_outline</mat-icon>
											</label>
											<input [hidden]="true" type="file" name="image" #fileselect
												(change)="onBrowseFiles(col.name, $event.target)" />
											<div *ngIf="data.data[col.name]">
												<mat-card class="itemView"
													*ngFor="let itm of data.data[col.name]; let index=index">
													<mat-card-header>
														<i class="material-icons"
															(click)="deletePictures(col.name, index)">delete_forever</i>
													</mat-card-header>
													<div class="imageParent">
														<img mat-card-image src="{{itm.url}}">
													</div>
													<mat-card-content class="itemData">
														<mat-select ng-model="col.name"
															(selectionChange)="setPictureArrayType(index, col.name, $event.value)"
															[value]="data.data[col.name][index]['type']">
															<mat-option value="none">{{'None' | translate}}</mat-option>
															<mat-option value="link">{{'Link' | translate}}</mat-option>
															<mat-option value="tile">{{'Tile' | translate}}</mat-option>
															<mat-option value="room">{{'Room' | translate}}</mat-option>
															<mat-option value="page">{{'Page' | translate}}</mat-option>
														</mat-select>
														<mat-form-field class="example-full-width"
															*ngIf="data.data[col.name][index]['type'] !== 'none'">
															<input name="link{{index}}" matInput ng-model="col.name"
																[readonly]="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'"
																[(ngModel)]="data.data[col.name][index]['link']">
															<mat-icon matSuffix
																*ngIf="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'"
																style="cursor:pointer"
																(click)="selectPictureLinkType(index, col.name, data.data[col.name][index]['type'])">
																mode_edit</mat-icon>
														</mat-form-field>
													</mat-card-content>
												</mat-card>
											</div>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'datetime'">
											<app-custom-datetime [value]="data.data[col.name]" [metadata]="col"
												(dateChange)="data.data[col.name] = $event"></app-custom-datetime>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'date'">
											<mat-form-field>
												<input matInput name="{{col.name}}" [required]="!col.nullable"
													[(ngModel)]="data.data[col.name]" ng-model="col.name"
													[matDatepicker]="picker"
													placeholder="{{col.displayName| translate}}" [min]="minDate"
													[max]="maxDate"
													(dateChange)="setDateAttribute(col.name, $event.value)"
													[errorStateMatcher]="esMatcher">
												<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
												<mat-datepicker #picker></mat-datepicker>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName| translate}} is required.
												</mat-error>
											</mat-form-field>
										</div>
										<mat-form-field
										*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'enum' && col.multiple === true">

										<input [readonly]="data.dataType == 'address' && col.name == 'country'"
											[style.visibility]="'hidden'" matInput name="{{col.name}}"
											[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
											[errorStateMatcher]="esMatcher">
										<mat-label>{{col.displayName | translate}}</mat-label>
										<mat-select ng-model="col.name" [required]="!col.nullable"
											(selectionChange)="setAttribute(col.name, $event.value, undefined)"
											multiple="true"
											[value]="data.data[col.name]"
											[disabled]="(data.dataType == 'address' && col.name == 'country')">
											<!-- <mat-option value="">Select {{col.displayName | translate}}</mat-option> -->
											<mat-option *ngFor="let itm of col.enum" [value]="itm.value">
												{{itm.displayName | translate}}</mat-option>
										</mat-select>

										<mat-error
											*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
											{{col.displayName | translate}} is required.
										</mat-error>
									</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'enum'  && (col.multiple === undefined || col.multiple === false)">

											<input [readonly]="data.dataType == 'address' && col.name == 'country'"
												[style.visibility]="'hidden'" matInput name="{{col.name}}"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												[errorStateMatcher]="esMatcher">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<mat-select ng-model="col.name" [required]="!col.nullable"
												(selectionChange)="setAttribute(col.name, $event.value, undefined)"
												[value]="data.data[col.name]"
												[disabled]="(data.dataType == 'address' && col.name == 'country')">
												<mat-option value="">Select {{col.displayName | translate}}</mat-option>
												<mat-option *ngFor="let itm of col.enum" [value]="itm.value">
													{{itm.displayName | translate}}</mat-option>
											</mat-select>

											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>

										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'enumEmoji'">
											<input [style.visibility]="'hidden'" matInput name="{{col.name}}"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												[errorStateMatcher]="esMatcher">
											<span>{{col.displayName | translate}}</span>
											<!-- <app-emoji-slider-field sliderName="slidername" [required]="!col.nullable"
												[nativeValue]="data.data[col.name]" [enum]="col.enum"
												(onChanged)="sliderChanged(col.name, $event)">
											</app-emoji-slider-field> -->
											<!-- <span>{{data.data[col.name]}}</span> -->
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>

									</span>

									<span class="fieldInput" *ngIf="col.type === 'object'">
										<div *ngFor="let subCol of col.fields" class="fieldItem">
											<div
												*ngIf="subCol.type !== 'action' && (subCol.editable || !subCol.generated) && subCol.creatable">
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'string' && data.data[col.name] != undefined">

													<input matInput [required]="!subCol.nullable"
														name="{{col.name + '.' + subCol.name}}" ng-model="subCol.name"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable"
														placeholder="{{subCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher"
														maxlength="{{subCol.charlimit ? subCol.charlimit : 1000}}">
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'number' && data.data[col.name] != undefined">
													<input matInput type="number" [required]="!subCol.nullable"
														name="{{col.name + '.' + subCol.name}}" ng-model="subCol.name"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable"
														placeholder="{{subCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'email' && data.data[col.name] != undefined">
													<input matInput type="email" [required]="!subCol.nullable"
														name="{{col.name + '.' + subCol.name}}" ng-model="subCol.name"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable"
														placeholder="{{subCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher"
														pattern="^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
														[disabled]="data.data._id && subCol.unique">
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'url'">
													<input matInput type="text" [required]="!subCol.nullable"
														name="{{col.name + '.' + subCol.name}}" ng-model="subCol.name"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable"
														placeholder="{{subCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher"
														pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"
														[disabled]="data.data._id && subCol.unique">
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'phone'">
													<input matInput type="phone" [required]="!subCol.nullable"
														name="{{col.name + '.' + subCol.name}}" ng-model="subCol.name"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable"
														placeholder="{{subCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'subColor'">
													<input matInput type="subColor" [required]="!subCol.nullable"
														name="{{col.name + '.' + subCol.name}}" ng-model="subCol.name"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable"
														placeholder="{{subCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field *ngIf="subCol.type === 'password'">
													<input matInput type="password" [required]="!subCol.nullable"
														name="{{col.name + '.' + subCol.name}}" ng-model="subCol.name"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable"
														placeholder="{{subCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<div
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'textarea'">
													<mat-form-field class="small-t">
														<textarea matInput
															placeholder="{{subCol['displayName'] | translate}}"
															name="{{subCol['name']}}" [required]="!subCol['nullable']"
															maxlength="3000"
															[(ngModel)]="data.data[col.name][subCol.name]"
															ng-model="subCol['name']" cdkTextareaAutosize
															cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
															[errorStateMatcher]="esMatcher">
													</textarea>
														<mat-error
															*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
															{{subCol['displayName'] | translate}} is required.
														</mat-error>
													</mat-form-field>
												</div>
												<div
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'textareaSpan'">
													<mat-form-field class="small-t">
														<span>{{subCol.displayName | translate}}</span>
														<br><br>
														<textarea matInput name="{{subCol['name']}}"
															[required]="!subCol['nullable']" maxlength="3000"
															[(ngModel)]="data.data[col.name][subCol.name]"
															ng-model="subCol['name']" cdkTextareaAutosize
															cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
															[errorStateMatcher]="esMatcher">
													</textarea>
														<mat-error
															*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
															{{subCol['displayName'] | translate}} is required.
														</mat-error>
													</mat-form-field>
												</div>
												<div *ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'boolean' && subCol.name !== 'privacyterm'"
													style="margin-bottom:15px;">
													<mat-checkbox class="example-margin"
														name="{{col.name + '.' + subCol.name}}"
														[(ngModel)]="data.data[col.name][subCol.name]">
														{{subCol.displayName}}
													</mat-checkbox>
													<!-- <mat-radio-group [value]="data.data[col.name][subCol.name]" name="{{col.name + '.' + subCol.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
													<mat-radio-button class="example-margin" value="false">False</mat-radio-button>
													<mat-radio-button class="example-margin" value="true">True</mat-radio-button>
												  </mat-radio-group> -->
												</div>
												<div
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'tags'">
													<app-custom-tags [parentFormSubmitted]="isSubmitted"
														[value]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable" [placeholder]="subCol.displayName"
														(onSelectReturn)="setAttribute(subCol.name, $event, col.name)"
														[itemName]="subCol.name">
													</app-custom-tags>
												</div>
												<div
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'reference' && data.data[col.name] != undefined">
													<app-custom-select
														*ngIf="data.data[col.name][subCol.name] && subCol.reference.kind === 'single'"
														[parentFormSubmitted]="isSubmitted"
														[value]="data.data[col.name][subCol.name]['_id']"
														[itemName]="subCol.name" [required]="!subCol.nullable"
														[dataType]="subCol.reference.to"
														[apiTarget]="subCol.reference.api"
														[placeholder]="subCol.displayName"
														(onRoleSelectReturn)="setRoleReferenceAttribute(subCol.name, $event)"
														(onSelectReturn)="setReferenceAttribute(subCol.name, '_id', $event)">
													</app-custom-select>

													<app-custom-multiple-select
														*ngIf="data.data[col.name][subCol.name] && subCol.reference.kind === 'multiple'"
														[value]="data.data[col.name][subCol.name]"
														[itemName]="subCol.name" [required]="!subCol.nullable"
														[dataType]="subCol.reference.to"
														[apiTarget]="subCol.reference.api"
														[placeholder]="subCol.displayName"
														(onSelectReturn)="setMultipleReferenceAttribute(subCol.name, $event, undefined)">
													</app-custom-multiple-select>
												</div>

												<div
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'tree' && data.data[col.name] != undefined">
												
													<app-custom-multiple-select-tree
														(onSelectReturn)="setMultipleReferenceAttribute(subCol.name, $event, col.name)"
														[ListData]="subCol.tree"
														[SelectedData]="data.data[col.name][subCol.name]"
														[placeHolder]="subCol.displayName"
														[isPatient]="data.subDataType == 'patient'"
														#customMultipleTree>
													</app-custom-multiple-select-tree>
												</div>
												<div *ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'picturearray'"
													class="picturearray" style="display: inline-block;">
													<label class="control-label"
														*ngIf="subCol.name">{{subCol.displayName}}<span
															*ngIf="!subCol.nullable"> *</span>:
														<mat-icon (click)="fileselect.click()" class="newRow"
															title="Add Image">
															add_circle_outline</mat-icon>
													</label>
													<input [hidden]="true" type="file" name="image" #fileselect
														(change)="onBrowseFiles(subCol.name, $event.target)" />
													<div *ngIf="data.data[col.name][subCol.name]">
														<mat-card class="itemView"
															*ngFor="let itm of data.data[col.name][subCol.name]; let index=index">
															<mat-card-header>
																<i class="material-icons"
																	(click)="deletePictures(subCol.name, index)">delete_forever</i>
															</mat-card-header>
															<div class="imageParent">
																<img mat-card-image src="{{itm.url}}">
															</div>
															<mat-card-content class="itemData">
																<mat-select ng-model="subCol.name"
																	(selectionChange)="setPictureArrayType(index, subCol.name, $event.value)"
																	[value]="data.data[col.name][subCol.name][index]['type']">
																	<mat-option value="none">{{'None' | translate}}
																	</mat-option>
																	<mat-option value="link">{{'Link' | translate}}
																	</mat-option>
																	<mat-option value="tile">{{'Tile' | translate}}
																	</mat-option>
																	<mat-option value="room">{{'Room' | translate}}
																	</mat-option>
																	<mat-option value="page">{{'Page' | translate}}
																	</mat-option>
																</mat-select>
																<mat-form-field class="example-full-width"
																	*ngIf="data.data[col.name][subCol.name][index]['type'] !== 'none'">
																	<input name="link{{index}}" matInput
																		ng-model="subCol.name"
																		[readonly]="data.data[col.name][subCol.name][index]['type'] !== 'link' && data.data[col.name][subCol.name][index]['type'] !== 'page'"
																		[(ngModel)]="data.data[col.name][subCol.name][index]['link']">
																	<mat-icon matSuffix
																		*ngIf="data.data[col.name][subCol.name][index]['type'] !== 'link' && data.data[col.name][subCol.name][index]['type'] !== 'page'"
																		style="cursor:pointer"
																		(click)="selectPictureLinkType(index, subCol.name, data.data[col.name][subCol.name][index]['type'])">
																		mode_edit</mat-icon>
																</mat-form-field>
															</mat-card-content>
														</mat-card>
													</div>
												</div>
												<div
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'datetime'">
													<app-custom-datetime [value]="data.data[col.name][subCol.name]"
														[metadata]="subCol"
														(dateChange)="data.data[col.name][subCol.name] = $event">
													</app-custom-datetime>
												</div>
												<div
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'date'">
													<mat-form-field>
														<input matInput name="{{col.name + '.' + subCol.name}}"
															[required]="!subCol.nullable"
															[(ngModel)]="data.data[col.name][subCol.name]"
															ng-model="subCol.name" [matDatepicker]="picker"
															[min]="minDate" [max]="maxDate"
															placeholder="{{subCol.displayName| translate}}"
															(dateChange)="setDateAttribute(subCol.name, $event.value)"
															[errorStateMatcher]="esMatcher">
														<mat-datepicker-toggle matSuffix [for]="picker">
														</mat-datepicker-toggle>
														<mat-datepicker #picker></mat-datepicker>
														<mat-error
															*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
															{{subCol.displayName| translate}} is required.
														</mat-error>
													</mat-form-field>
												</div>

												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'enum' && subCol.multiple === true && data.data[col.name] != undefined">
													<input [style.visibility]="'hidden'" matInput
														name="{{col.name + '.' + subCol.name}}"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable" [errorStateMatcher]="esMatcher">
													<mat-label>{{subCol.displayName | translate}}</mat-label>
													<mat-select ng-model="col.name[subCol.name]"
														[required]="!subCol.nullable" multiple=true
														(selectionChange)="setAttribute(subCol.name, $event.value, col.name)"
														[value]="data.data[col.name][subCol.name]"
														placeholder="{{subCol.displayName | translate}}">
														<!-- <mat-option value="">Select {{subCol.displayName | translate}}
														</mat-option> -->
														<mat-option *ngFor="let itm of subCol.enum" [value]="itm.value">
															{{itm.displayName | translate}}</mat-option>
													</mat-select>
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'enum' && (subCol.multiple === undefined || subCol.multiple === false) && data.data[col.name] != undefined">
													<input [style.visibility]="'hidden'" matInput
														name="{{col.name + '.' + subCol.name}}"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable" [errorStateMatcher]="esMatcher">
													<mat-label>{{subCol.displayName | translate}}</mat-label>
													<mat-select ng-model="col.name[subCol.name]"
														[required]="!subCol.nullable"
														(selectionChange)="setAttribute(subCol.name, $event.value, col.name)"
														[value]="data.data[col.name][subCol.name]">
														<mat-option value="">Select {{subCol.displayName | translate}}
														</mat-option>
														<mat-option *ngFor="let itm of subCol.enum" [value]="itm.value">
															{{itm.displayName | translate}}</mat-option>
													</mat-select>
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(subCol.visible || (subCol.admin && isAdmin)) && subCol.type === 'enumEmoji'">
													<input [style.visibility]="'hidden'" matInput
														name="{{col.name + '.' + subCol.name}}"
														[(ngModel)]="data.data[col.name][subCol.name]"
														[required]="!subCol.nullable" [errorStateMatcher]="esMatcher">
													<span>{{subCol.displayName | translate}}</span>
													<!-- <app-emoji-slider-field sliderName="slidername"
														[required]="!subCol.nullable"
														[nativeValue]="data.data[col.name][subCol.name]"
														[enum]="subCol.enum"
														(onChanged)="sliderChanged(subCol.name, $event)">
													</app-emoji-slider-field> -->
													<!-- <span>{{data.data[col.name][subCol.name]}}</span> -->
													<mat-error
														*ngIf="!subCol.nullable && (data.data[col.name][subCol.name] === undefined || data.data[col.name][subCol.name] === '')">
														{{subCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>


											</div>
										</div>


									</span>

									<span class="fieldInput"
										*ngIf="col.type === 'array' && data.subDataType != 'practitioner' ">

										<div *ngFor="let arrCol of col.fields" class="fieldItem">
											<div
												*ngIf="arrCol.type !== 'action' && (arrCol.editable || !arrCol.generated) && arrCol.creatable">

												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'string' && data.data[col.name] != undefined && arrCol.name!= 'longitude' && arrCol.name!= 'latitude' ">

													<input
														[readonly]="col.name == 'address' && (arrCol.name == 'street' || arrCol.name == 'number' || arrCol.name == 'city' || arrCol.name == 'country' || arrCol.name == 'state' || arrCol.name == 'zipcode')"
														matInput [required]="!arrCol.nullable"
														name="{{col.name + '.' + arrCol.name}}" ng-model="arrCol.name"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable"
														placeholder="{{arrCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'number' && data.data[col.name] != undefined">
													<input matInput type="number" [required]="!arrCol.nullable"
														name="{{col.name + '.' + arrCol.name}}" ng-model="arrCol.name"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable"
														placeholder="{{arrCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'email'">
													<input matInput type="email" [required]="!arrCol.nullable"
														name="{{col.name + '.' + arrCol.name}}" ng-model="arrCol.name"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable"
														placeholder="{{arrCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher"
														pattern="^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
														[disabled]="data.data._id && arrCol.unique">
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'url'">
													<input matInput type="text" [required]="!arrCol.nullable"
														name="{{col.name + '.' + arrCol.name}}" ng-model="arrCol.name"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable"
														placeholder="{{arrCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher"
														pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"
														[disabled]="data.data._id && arrCol.unique">
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'phone'">
													<input matInput type="phone" [required]="!arrCol.nullable"
														name="{{col.name + '.' + arrCol.name}}" ng-model="arrCol.name"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable"
														placeholder="{{arrCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'arrColor'">
													<input matInput type="arrColor" [required]="!arrCol.nullable"
														name="{{col.name + '.' + arrCol.name}}" ng-model="arrCol.name"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable"
														placeholder="{{arrCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<mat-form-field *ngIf="arrCol.type === 'password'">
													<input matInput type="password" [required]="!arrCol.nullable"
														name="{{col.name + '.' + arrCol.name}}" ng-model="arrCol.name"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable"
														placeholder="{{arrCol.displayName | translate}}"
														[errorStateMatcher]="esMatcher">
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<div
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'textarea'">
													<mat-form-field class="small-t">
														<textarea matInput
															placeholder="{{arrCol['displayName'] | translate}}"
															name="{{arrCol['name']}}" [required]="!arrCol['nullable']"
															maxlength="3000"
															[(ngModel)]="data.data[col.name][0][arrCol.name]"
															ng-model="arrCol['name']" cdkTextareaAutosize
															cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
															[errorStateMatcher]="esMatcher">
													</textarea>
														<mat-error
															*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
															{{arrCol['displayName'] | translate}} is required.
														</mat-error>
													</mat-form-field>
												</div>
												<div
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'textareaSpan'">
													<mat-form-field class="small-t">
														<span>{{arrCol.displayName | translate}}</span>
														<br><br>
														<textarea matInput name="{{arrCol['name']}}"
															[required]="!arrCol['nullable']" maxlength="3000"
															[(ngModel)]="data.data[col.name][0][arrCol.name]"
															ng-model="arrCol['name']" cdkTextareaAutosize
															cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
															[errorStateMatcher]="esMatcher">
													</textarea>
														<mat-error
															*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
															{{arrCol['displayName'] | translate}} is required.
														</mat-error>
													</mat-form-field>
												</div>
												<div *ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'boolean' && arrCol.name !== 'privacyterm'"
													style="margin-bottom:15px;">
													<mat-checkbox class="example-margin"
														name="{{col.name + '.' + arrCol.name}}"
														[(ngModel)]="data.data[col.name][0][arrCol.name]">
														{{arrCol.displayName}}
													</mat-checkbox>
													<!-- <mat-radio-group [value]="data.data[col.name][0][arrCol.name]" name="{{col.name + '.' + arrCol.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
													<mat-radio-button class="example-margin" value="false">False</mat-radio-button>
													<mat-radio-button class="example-margin" value="true">True</mat-radio-button>
												  </mat-radio-group> -->
												</div>
												<div
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'tags'">
													<app-custom-tags [parentFormSubmitted]="isSubmitted"
														[value]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable" [placeholder]="arrCol.displayName"
														(onSelectReturn)="setAttribute(arrCol.name, $event, col.name)"
														[itemName]="arrCol.name">
													</app-custom-tags>
												</div>
												<div
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'reference'">

													<app-custom-select
														*ngIf="data.data[col.name][0][arrCol.name] && arrCol.reference.kind === 'single'"
														[parentFormSubmitted]="isSubmitted"
														[value]="data.data[col.name][0][arrCol.name]['_id']"
														[itemName]="arrCol.name" [required]="!arrCol.nullable"
														[dataType]="arrCol.reference.to"
														[apiTarget]="arrCol.reference.api"
														[placeholder]="arrCol.displayName"
														(onRoleSelectReturn)="setRoleReferenceAttribute(arrCol.name, $event)"
														(onSelectReturn)="setReferenceAttribute(arrCol.name, '_id', $event)">
													</app-custom-select>

													<app-custom-multiple-select
														*ngIf="data.data[col.name][0][arrCol.name] && arrCol.reference.kind === 'multiple'"
														[value]="data.data[col.name][0][arrCol.name]"
														[itemName]="arrCol.name" [required]="!arrCol.nullable"
														[dataType]="arrCol.reference.to"
														[apiTarget]="arrCol.reference.api"
														[placeholder]="arrCol.displayName"
														(onSelectReturn)="setMultipleReferenceAttribute(arrCol.name, $event, undefined)">
													</app-custom-multiple-select>
												</div>
												<div *ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'picturearray'"
													class="picturearray" style="display: inline-block;">
													<label class="control-label"
														*ngIf="arrCol.name">{{arrCol.displayName}}<span
															*ngIf="!arrCol.nullable"> *</span>:
														<mat-icon (click)="fileselect.click()" class="newRow"
															title="Add Image">
															add_circle_outline</mat-icon>
													</label>
													<input [hidden]="true" type="file" name="image" #fileselect
														(change)="onBrowseFiles(arrCol.name, $event.target)" />
													<div *ngIf="data.data[col.name][0][arrCol.name]">
														<mat-card class="itemView"
															*ngFor="let itm of data.data[col.name][0][arrCol.name]; let index=index">
															<mat-card-header>
																<i class="material-icons"
																	(click)="deletePictures(arrCol.name, index)">delete_forever</i>
															</mat-card-header>
															<div class="imageParent">
																<img mat-card-image src="{{itm.url}}">
															</div>
															<mat-card-content class="itemData">
																<mat-select ng-model="arrCol.name"
																	(selectionChange)="setPictureArrayType(index, arrCol.name, $event.value)"
																	[value]="data.data[col.name][0][arrCol.name][index]['type']">
																	<mat-option value="none">{{'None' | translate}}
																	</mat-option>
																	<mat-option value="link">{{'Link' | translate}}
																	</mat-option>
																	<mat-option value="tile">{{'Tile' | translate}}
																	</mat-option>
																	<mat-option value="room">{{'Room' | translate}}
																	</mat-option>
																	<mat-option value="page">{{'Page' | translate}}
																	</mat-option>
																</mat-select>
																<mat-form-field class="example-full-width"
																	*ngIf="data.data[col.name][0][arrCol.name][index]['type'] !== 'none'">
																	<input name="link{{index}}" matInput
																		ng-model="arrCol.name"
																		[readonly]="data.data[col.name][0][arrCol.name][index]['type'] !== 'link' && data.data[col.name][0][arrCol.name][index]['type'] !== 'page'"
																		[(ngModel)]="data.data[col.name][0][arrCol.name][index]['link']">
																	<mat-icon matSuffix
																		*ngIf="data.data[col.name][0][arrCol.name][index]['type'] !== 'link' && data.data[col.name][0][arrCol.name][index]['type'] !== 'page'"
																		style="cursor:pointer"
																		(click)="selectPictureLinkType(index, arrCol.name, data.data[col.name][0][arrCol.name][index]['type'])">
																		mode_edit</mat-icon>
																</mat-form-field>
															</mat-card-content>
														</mat-card>
													</div>
												</div>
												<div
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'datetime'">
													<app-custom-datetime [value]="data.data[col.name][0][arrCol.name]"
														[metadata]="arrCol"
														(dateChange)="data.data[col.name][0][arrCol.name] = $event">
													</app-custom-datetime>
												</div>
												<div
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'date'">
													<mat-form-field>
														<input matInput name="{{col.name + '.' + arrCol.name}}"
															[required]="!arrCol.nullable"
															[(ngModel)]="data.data[col.name][0][arrCol.name]"
															ng-model="arrCol.name" [matDatepicker]="picker"
															[min]="minDate" [max]="maxDate"
															placeholder="{{arrCol.displayName| translate}}"
															(dateChange)="setDateAttribute(arrCol.name, $event.value)"
															[errorStateMatcher]="esMatcher">
														<mat-datepicker-toggle matSuffix [for]="picker">
														</mat-datepicker-toggle>
														<mat-datepicker #picker></mat-datepicker>
														<mat-error
															*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
															{{arrCol.displayName| translate}} is required.
														</mat-error>
													</mat-form-field>
												</div>

												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'enum' && data.data[col.name] != undefined">
													<input
														[readonly]="col.name == 'address' && arrCol.name == 'country'"
														[style.visibility]="'hidden'" matInput
														name="{{col.name + '.' + arrCol.name}}"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable" [errorStateMatcher]="esMatcher">
													<mat-label>{{arrCol.displayName | translate}}</mat-label>
													<mat-select
														[disabled]="col.name == 'address' && arrCol.name == 'country'"
														ng-model="col.name[arrCol.name]" [required]="!arrCol.nullable"
														(selectionChange)="setAttribute(arrCol.name, $event.value, col.name)"
														[value]="data.data[col.name][0][arrCol.name]">
														<mat-option value="">Select {{arrCol.displayName | translate}}
														</mat-option>
														<mat-option *ngFor="let itm of arrCol.enum" [value]="itm.value">
															{{itm.displayName | translate}}</mat-option>
													</mat-select>
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>

												<mat-form-field
													*ngIf="(arrCol.visible || (arrCol.admin && isAdmin)) && arrCol.type === 'enumEmoji'">
													<input [style.visibility]="'hidden'" matInput
														name="{{col.name + '.' + arrCol.name}}"
														[(ngModel)]="data.data[col.name][0][arrCol.name]"
														[required]="!arrCol.nullable" [errorStateMatcher]="esMatcher">
													<span>{{arrCol.displayName | translate}}</span>
													<!-- <app-emoji-slider-field sliderName="slidername"
														[required]="!arrCol.nullable"
														[nativeValue]="data.data[col.name][0][arrCol.name]"
														[enum]="arrCol.enum"
														(onChanged)="sliderChanged(arrCol.name, $event)">
													</app-emoji-slider-field> -->
													<!-- <span>{{data.data[col.name][0][arrCol.name]}}</span> -->
													<mat-error
														*ngIf="!arrCol.nullable && (data.data[col.name][0][arrCol.name] === undefined || data.data[col.name][0][arrCol.name] === '')">
														{{arrCol.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>

											</div>
										</div>
									</span>
								</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div mat-dialog-actions>
			<!-- <span *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')"> -->
			<button color="danger" mat-raised-button (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')"
				[disabled]="loading">{{'Submit' | translate}}</button>
			<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading">{{'Save' |
				translate}}</button>
		</div>
		<br />
	</div>
</form>