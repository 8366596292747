<form #csvUpdateForm="ngForm" class="csvUpdateForm" *ngIf="data">
  <div class="col-xl-12">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <mat-card>
        <mat-card-content>
          <!-- <h2 class="example-h2">{{'Files'}}</h2> -->

          <section
            *ngIf="errorMessage && errorMessage !== ''"
            class="example-section"
          >
            {{ errorMessage }}
          </section>
          <ng-container *ngIf="progressInfos.length === 0">
            <table>
              <tr>
                <th style="width: 30%">File Name</th>
                <th style="width: 50%">Status</th>
                <th style="width: 20%">Action</th>
              </tr>
              <tr *ngFor="let progressInfo of currentFiles; let index = index">
                <td>{{ progressInfo.name }}</td>
                <td>
                  {{ progressInfo.message }}
                  <ng-container
                    *ngIf="
                      progressInfo.hasOwnProperty('messages') &&
                      progressInfo.messages.length > 0
                    "
                  >
                    <div
                      class="fieldsError"
                      *ngFor="let msg of progressInfo.messages"
                    >
                      {{ msg }}
                    </div>
                  </ng-container>
                </td>
                <td class="actionClass">
                  <button
                    mat-raised-button
                    (click)="uploadFile(index)"
                    [disabled]="progressInfo.error"
                  >
                    {{ "Confirm" | translate }}
                  </button>
                  <button
                    mat-raised-button
                    color="danger"
                    (click)="deleteFile(index)"
                  >
                    {{ "Remove" | translate }}
                  </button>
                </td>
              </tr>
            </table>
          </ng-container>
          <ng-container *ngIf="progressInfos.length > 0">
            <table>
              <tr>
                <th style="width: 30%">File Name</th>
                <th style="width: 50%">Status</th>
                <th style="width: 20%">Action</th>
              </tr>
              <tr *ngFor="let progressInfo of progressInfos; let index = index">
                <td>{{ progressInfo.name }}</td>
                <td>
                  <mat-progress-bar
                    *ngIf="!progressInfo.error && !progressInfo.message"
                    class="example-margin"
                    [color]="color"
                    [mode]="mode"
                    [value]="progressInfo.value"
                  >
                  </mat-progress-bar>
                  <span *ngIf="progressInfo.message">
                    {{ progressInfo.message }}
                    <div *ngIf="results" class="resultData">
                      <div>
                        <span>Success Count: </span> {{ results.successCount }}
                      </div>
                      <div>
                        <span>Failure Count: </span> {{ results.failureCount }}
                      </div>
                      <div *ngIf="progressInfo['hasPrivacyTerm']">
                        <span style="font-weight: normal"
                          >- Users should agree to Terms of Use</span
                        >
                      </div>
                      <div class="showMoreInfo" *ngIf="showMoreInfo">
                        <div class="infoTitle">Row:</div>
                        <div class="info">
                          <div *ngFor="let itm of progressInfo['uploaded']">
                            {{ itm.index + 2 }}
                            -
                            {{ itm.message }}
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="progressInfo['uploaded'].length > 0"
                        class="showMore"
                        (click)="showMoreInfo = !showMoreInfo"
                      >
                        Show <span *ngIf="!showMoreInfo">More</span
                        ><span *ngIf="showMoreInfo">Less</span>
                      </div>
                    </div>
                  </span>
                </td>
                <td>
                  <button
                    mat-raised-button
                    color="danger"
                    (click)="cancelFile(index)"
                    *ngIf="!progressInfo.error && progressInfo.value < 100"
                  >
                    {{ "Cancel" | translate }}
                  </button>
                </td>
              </tr>
            </table>
          </ng-container>
          <!-- <div *ngIf="results" class="resultData">
						<h3 style="margin-bottom: 5px;">Results:</h3>
						<div>
							<span>Success Count: </span> {{results.successCount}}
						</div>
						<div><span>Failure Count: </span> {{results.failureCount}}
						</div>
					</div> -->
          <div class="noteUploader">
            {{ "Note" | translate }}:
            {{ "Only first 1000 rows will be inserted" | translate }}
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex>
        <div fxFlex>
          <button
            color="danger"
            mat-raised-button
            color="primary"
            (click)="closeModal()"
          >
            {{ "Close" | translate }}
          </button>
          <button
            mat-raised-button
            (click)="fileCsvInput.value = ''; fileCsvInput.click()"
            [disabled]="loading"
          >
            {{ "Upload CSV File" | translate }}
          </button>
          <button
            mat-raised-button
            *ngIf="currentFiles.length > 0"
            (click)="uploadFiles()"
            [disabled]="loading"
          >
            {{ "Confirm Upload" | translate }}
          </button>
          <button
            mat-raised-button
            (click)="downloadSample()"
            [disabled]="loading"
          >
            {{ "Download Sample" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<input
  #fileCsvInput
  [hidden]="true"
  type="file"
  name="fileCsvInput"
  accept=".csv"
  multiple="false"
  id="image_selector"
  (change)="onBrowseFiles($event.target)"
/>
