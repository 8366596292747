<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field class="example-chip-list">
    <mat-chip-listbox #chipList aria-label="placeholder">
      <mat-chip *ngFor="let tg of value" [removable]="removable" (removed)="remove(tg)">
        {{tg.name}}
        <mat-icon class="closeBtn" matChipRemove *ngIf="removable">{{'Close' | translate}}</mat-icon>
      </mat-chip>
      <input placeholder="{{'New Tag...'  | translate}}" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-listbox>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let tg of filteredTags | async" [value]="tg.uid">
        {{tg.name}}
      </mat-option>
    </mat-autocomplete>
    <div class="my-error mat-error mat-form-field-subscript-wrapper"
      *ngIf="required && (value === undefined || value.length === 0)">
      {{placeholder | translate}} is required.
    </div>
  </mat-form-field>
</form>