import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FormRequestService, FormLayoutUtilsService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { CustomSelectCreateAutocompleteComponent } from '../custom-select-create-autocomplete/custom-select-create-autocomplete.component';

@Component({
  selector: 'app-tiles-list',
  templateUrl: './tiles-list.component.html',
  styleUrls: ['./tiles-list.component.scss']
})
export class TilesListComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;

  public errorMessage: string = '';
  public selectedCategory: string = '-1';
  public tableName: string = 'tile';
  public dataType: string = 'tile';
  public dataTypeDisplay: string = this.translate.instant('Tile');
  public UIDisplay: string = this.translate.instant('Content');
  public loading: boolean = false;
  public selectedOrganization: string = "-1";

  public targetName: string = 'tiles';
  public pushedTile: any = undefined;
  public tilesList: any[] = [];
  public dataList: any[] = [];
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  pageNumber = 1;

  orderDir = 'desc';
  orderBy = 'createdAt'; // uid

  public searchVal: string = '';
  @Input() hasCategories: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() pageSize: number = 10;
  @Input() lectureData: any = undefined;
  @Input() selectedData: any = undefined;
  @Output() tileAction = new EventEmitter<any>();
  @Output() selectItem = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('subTileList') sub_tile_list: CustomSelectCreateAutocompleteComponent;
  constructor(
    private formRequestService: FormRequestService,
    private layoutUtilsService: FormLayoutUtilsService,
    public dialog: MatDialog, private translate: TranslateService) { }
  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.paginator.pageIndex = 0;
            this.getTableVariables()
            this.loadData();
          }
        } catch (e) { }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadData();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.formRequestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.selectedOrganization = this.formRequestService.orgId;
          this.loadData();
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  public selectCategory(data) {
    // console.log('selectCategory', data);
    if ((!data._id && data.text === '') || data._id) {
      this.selectedCategory = data._id;
    }
    this.loadData();
  }
  public selectItemCheck(data) {
    this.selectItem.emit(data);
  }
  public getTableVariables() {
    // this.orderBy = this.sort.active || 'uid';
    // this.orderDir = this.sort.direction || 'asc';
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  loadData() {
    this.loadDataLibrary();
  }
  reloadTileData() {
    this.sub_tile_list.loadDataSearch();
  }
  loadDataLibrary() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();

      let filterData: any = {
        '$and': [
          { 'organizationId': { '$eq': this.selectedOrganization } },
          // {'createdBy': { '$eq': this.selectedUser._id }}
        ]
      };

      // console.log(this.selectedCategory)

      if (this.selectedCategory !== '-1' && this.selectedCategory !== undefined) {
        filterData['$and'].push({ '$or': [{ 'category': { '$eq': this.selectedCategory } }, { 'category._id': { '$eq': this.selectedCategory } }] });
      }
      let filterObj = { perpage: this.pageSize, page: this.pageNumber, orderBy: this.orderBy, orderDir: this.orderDir, term: termConfiguration, termfields: ['title'], filter: filterData };
      this.formRequestService.getDataList(this.dataType, filterObj, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          return;
        }
        this.loading = false;
        if (data) {
          this.dataList = data.results;
        }
        this.paginatorTotal.next(data.pagination.total);
      });
    }
  }

  termConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  public delete(e, id: any) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    if (!this.loading) {
      const _title: string = this.dataTypeDisplay + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(id);
      });
    }
  }
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.formRequestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          if (id === this.selectedData._id) {
            this.tileAction.emit({ action: 'widgetTileReset', val1: true, val2: true });
          }
          this.loadData();
        }
      });
    }
  }
}
