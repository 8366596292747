// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { LayoutUtilsService } from 'src/app/shared/services/layout-utils.service';

@Component({
	selector: 'kt-confirm-file-entity-dialog',
	templateUrl: './confirm-file-entity-dialog.component.html'
})
export class ConfirmFileEntityDialogComponent implements OnInit {

	fileDisplay = new FormControl('');

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(private translate: TranslateService, private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ConfirmFileEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		this.dialogRef.close(this.data);
	}

	handleFileInputChange(file: FileList): void {
		this.fileDisplay.patchValue(file[0].name);
		this.data.file = file[0];
	}
}
