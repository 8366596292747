import { Component, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AngularEditorConfig } from '../../lib/x-editor';
import { VideoInputEntityDialogComponent } from '../video-input-entity-dialog/video-input-entity-dialog.component';



@Component({
  selector: 'xeditor-block',
  templateUrl: './xeditor-block.component.html',
  styleUrls: ['./xeditor-block.component.scss']
})
export class XEditorBlockComponent {
  public subscriptions: any[] = <any>[];
  public Editor: any = {};
  public editTemp: any;

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'Default Font', name: '' },
      { class: 'Arial', name: 'Arial' },
      { class: 'Times New Roman', name: 'Times New Roman' },
      { class: 'Courier New', name: 'Courier New' },
      // { class: 'Calibri', name: 'Calibri' },
      // { class: 'Comic Sans MS', name: 'Comic Sans MS' },
      // { class: 'Roboto', name: 'Roboto' },
      // { class: 'Verdana', name: 'Verdana' },
      // { class: 'Georgia', name: 'Georgia' }
    ],
    // customClasses: [
    //   {
    //     name: 'quote',
    //     class: 'quote',
    //   },
    //   {
    //     name: 'redText',
    //     class: 'redText'
    //   },
    //   {
    //     name: 'titleText',
    //     class: 'titleText',
    //     tag: 'h1',
    //   },
    // ],
    // uploadUrl: 'v1/image',
    // upload: (file: File) => { },
    // uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      // ['bold', 'italic'],
      // ['insertVideo']
      // ['fontSize']
    ]
  };

  @Input() height: string = '250';
  @Input() value: any;

  @Input() widget_Buttons_map: string[] = [];
  @Output() textView = new EventEmitter<any>();
  public actionCall: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  @Input() config: any = undefined;
  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog) {
    if (!this.config)
      this.config = this.editorConfig;

  }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  onReady(editor: any) {
    this.customButtonPlugin(editor)
  }
  private customButtonPlugin(editor) {
  }

  // private customButtonPlugin(editor) {
  //   editor.ui.registry.addButton('customButton', {
  //     icon: '<svg width="24" height="24"><rect width="24" height="24" rx="8" fill="#000" /></svg>',
  //     label: 'Custom Button',
  //     onExecute: () => {
  //       console.log('Custom button clicked!');
  //     },
  //   });
  // }
  public onChange(data) {
    // console.log('onChange', editor);
    // if (editor) {
    //   const data = editor.getData();
    //   console.log('onChange', data);
    this.textView.emit(data);
    // }
  }
  public gallery_Button(editor) {
    const imageUrl = './assets/img/thumbnail.png';
    editor.model.change(writer => {
      const insertPosition = editor.model.document.selection.getFirstPosition();
      const content =
        '<img src="' + imageUrl + '" >';
      const viewFragment = editor.data.processor.toView(content);
      const modelFragment = editor.data.toModel(viewFragment);

      editor.model.insertContent(modelFragment, insertPosition);
    });
  }
  public video_Button(editor) {
    const dialogRef = this.dialog.open(VideoInputEntityDialogComponent, {
      width: '600px',
      disableClose: false,
      data: {
        title: 'Insert Video',
        data: '',
        cancelbtn: 'Close',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let data = editor.getData();
        data = data + '<p><iframe height="180" src="' + result + '" width="320"></iframe></p>';
        console.log('data after', data);
        editor.setData(data);
      }
    });
  }
  public ngAfterViewInit(): void {
  }
}
