// Angular
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorService } from '../angular-editor.service';
import { validateURL } from '../../../helpers';

@Component({
	selector: 'url-insert-dialog',
	templateUrl: './url-insert-dialog.component.html',
	styleUrls: ['./url-insert-dialog.component.scss']
})
export class UrlInsertDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(private translate: TranslateService, private editorService: AngularEditorService,
		public dialogRef: MatDialogRef<UrlInsertDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		if (this.data.data !== '') {
			if (!validateURL(this.data.data)) {
				this.editorService.showNotification(this.translate.instant('Please insert a proper target url structure'), this.translate.instant('Dismiss'));
				return;
			}
		} else {
			this.editorService.showNotification(this.translate.instant('Please insert a url'), this.translate.instant('Dismiss'));
			return;
		}
		this.dialogRef.close(this.data.data);
	}
	public onChange(data) {
		this.data.data = data;
	}
}
