import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
} from '@angular/core';
import Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/version';
import { RequestService } from './request.service';

let rollbarConfig = {
  accessToken: environment.rollbar.apiKey,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.environment === 'local' ? false : true,
  payload: {
    environment: environment.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: VERSION.git,
        guess_uncaught_frames: true,
      },
    },
  },
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  currentUser: any = undefined;

  constructor(
    private requestService: RequestService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.currentUser = this.requestService.currentUserSubject.getValue();
  }

  handleError(error: Error) {
    // Obtain dependencies at the time of the error
    // This is because the GlobalErrorHandler is registered first
    // which prevents constructor dependency injection
    if (this.currentUser)
      this.rollbar.configure({
        payload: {
          person: {
            id: this.currentUser._id,
            email: this.currentUser.email,
            name: this.currentUser.name,
          },
        },
      });

    this.rollbar.error(error);

    const err = {
      message: error.message ? error.message : error.toString(),
      stack: error.stack ? error.stack : '',
    };

    // Re-throw the error
    throw error;
  }
}
