<app-editor-loading-screen *ngIf="loading"></app-editor-loading-screen>
<div class="x-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{data.description}}
				</div>
			</div>
			<div class="form-group kt-form__group" style="overflow-y: auto;
			max-height: 50vh;">
				<div class="form-group kt-form__group row">
					<div class="col col-lg-6">
						<img *ngIf="returnData['imageUrl']" style="height: 150px;" src="{{returnData['imageUrl']}}" />
						<img *ngIf="!returnData['imageUrl']" style="height: 150px;"
							src="./assets/images/error-default-image-new.png" />
					</div>
					<div class="col col-lg-6">
						<input style="display: none" accept="image/*" type="file" (change)="onFileChanged($event)"
							#fileImageInput accept="{{allowedAcceptExtensions}}">
						<button mat-icon-button (click)="fileImageInput.click()" title="Upload new image">
							<mat-icon>upload_2</mat-icon>
						</button>&nbsp;
						<button *ngIf="returnData['imageUrl']" mat-icon-button (click)="returnData['imageUrl'] = ''"
							title="Clear selected image" [disabled]="loading" [disabled]="loading">
							<mat-icon>remove</mat-icon>
						</button>
					</div>
				</div>
				<div class="form-group kt-form__group row">
					<div class="col col-lg-6">
						<mat-form-field class="example-full-width">
							<mat-label>{{ "Width" }}</mat-label>
							<input matInput [(ngModel)]="returnData.width" placeholder="Width">
						</mat-form-field>
					</div>
					<div class="col col-lg-6">
						<mat-form-field class="example-full-width">
							<mat-label>{{ "Height" }}</mat-label>
							<input matInput [(ngModel)]="returnData.height" placeholder="Height">
						</mat-form-field>
					</div>
				</div>
				<div class="form-group kt-form__group row">
					<div class="col col-lg-12">
						<mat-form-field class="example-full-width">
							<mat-label>{{ "Target Url" }}</mat-label>
							<input matInput [(ngModel)]="returnData.targetUrl" placeholder="Target Url">
						</mat-form-field>
					</div>
				</div>
				<div class="form-group kt-form__group row">
					<div class="col col-lg-12">
						<mat-form-field>
							<mat-label>Select Target</mat-label>
							<mat-select [(value)]="returnData.target" [disabled]="returnData.targetUrl === ''">
								<mat-option *ngFor="let target of anchorTargets" [value]="target.value">
									{{ target.display }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br />
				<button mat-raised-button (click)="onNoClick()" color="danger">{{data.cancelbtn}}</button>&nbsp;
				<button mat-raised-button (click)="onYesClick()" cdkFocusInitial
					[disabled]="loading">{{data.confirmbtn}}</button>
			</div>
		</div>
	</div>
</div>