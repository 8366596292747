import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList, ElementRef, OnChanges, SimpleChanges, ChangeDetectorRef, Injectable } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from "@angular/material/tree";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";


export class Node {
  children?: Node[];
  displayName: string;
  value: string;
}
export class FlatNode {
  value: string;
  displayName: string;
  level: number;
  expandable: boolean;
  parent: string;
}
const TREE_DATA: Node[] = [
  {
    displayName: 'Aetna',
    value: 'aetna',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_aetna',
      },
      {
        displayName: 'HMO',
        value: 'hmo_aetna',
      },
      {
        displayName: 'PPO',
        value: 'ppo_aetna',
      },
    ],
  },
  {
    displayName: 'AmeriHealth',
    value: 'amerihealth',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_amerihealth',
      },
      {
        displayName: 'HMO',
        value: 'hmo_amerihealth',
      },
      {
        displayName: 'PPO',
        value: 'ppo_amerihealth',
      },
    ],
  },
  {
    displayName: 'Blue Cross',
    value: 'blue_cross',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_blue_cross',
      },
      {
        displayName: 'HMO',
        value: 'hmo_blue_cross',
      },
      {
        displayName: 'PPO',
        value: 'ppo_blue_cross',
      },
    ],
  },
  {
    displayName: 'Blue Shield',
    value: 'blue_shield',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_blue_shield',
      },
      {
        displayName: 'HMO',
        value: 'hmo_blue_shield',
      },
      {
        displayName: 'PPO',
        value: 'ppo_blue_shield',
      },
    ],
  },
  {
    displayName: 'Cigna',
    value: 'cigna_blue_shield',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_cigna_blue_shield',
      },
      {
        displayName: 'HMO',
        value: 'hmo_cigna_blue_shield',
      },
      {
        displayName: 'PPO',
        value: 'ppo_cigna_blue_shield',
      },
    ],
  },
  {
    displayName: 'Coventry',
    value: 'coventry',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_coventry',
      },
      {
        displayName: 'HMO',
        value: 'hmo_coventry',
      },
      {
        displayName: 'PPO',
        value: 'ppo_coventry',
      },
    ],
  },
  {
    displayName: 'Humana',
    value: 'humana',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_humana',
      },
      {
        displayName: 'HMO',
        value: 'hmo_humana',
      },
      {
        displayName: 'PPO',
        value: 'ppo_humana',
      },
    ],
  },
  {
    displayName: 'Kaiser Permanente',
    value: 'kaiser_permanente',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_kaiser_permanente',
      },
      {
        displayName: 'HMO',
        value: 'hmo_kaiser_permanente',
      },
      {
        displayName: 'PPO',
        value: 'ppo_kaiser_permanente',
      },
    ],
  },
  {
    displayName: 'Molina Healthcare',
    value: 'molina_healthcare',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_molina_healthcare',
      },
      {
        displayName: 'HMO',
        value: 'hmo_molina_healthcare',
      },
      {
        displayName: 'PPO',
        value: 'ppo_molina_healthcare',
      },
    ],
  },
  {
    displayName: 'ObamaCare',
    value: 'obamacare',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_obamacare',
      },
      {
        displayName: 'HMO',
        value: 'hmo_obamacare',
      },
      {
        displayName: 'PPO',
        value: 'ppo_obamacare',
      },
    ],
  },
  {
    displayName: 'United Healthcare',
    value: 'united_healthcare',
    children: [
      {
        displayName: 'EPO',
        value: 'epo_united_healthcare',
      },
      {
        displayName: 'HMO',
        value: 'hmo_united_healthcare',
      },
      {
        displayName: 'PPO',
        value: 'ppo_united_healthcare',
      },
    ],
  },
];



/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do displayName or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: "root" })
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<Node[]>([]);
  treeData: any[];

  get data(): Node[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // this.treeData = TREE_DATA;
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    //const data = this.treeData;

    // Notify the change.
    // this.dataChange.next(data);
  }

  public filter(filterText: string) {
    let filteredTreeData;
    if (filterText) {
      // Filter the tree
      function filter(array, text) {
        const getChildren = (result, object) => {
          if (object.displayName.toLowerCase() === text.toLowerCase()) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.treeData, filterText);
    } else {
      // Return the initial tree
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = filteredTreeData;
    // Notify the change.
    this.dataChange.next(data);
  }
}


@Component({
  selector: 'app-custom-multiple-select-tree',
  templateUrl: './custom-multiple-select-tree.component.html',
  styleUrls: ['./custom-multiple-select-tree.component.scss'],
  providers: [ChecklistDatabase]
})





/**
 * The Json object for to-do list data.
 */





export class CustomMultipleSelectTreeComponent implements OnInit {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FlatNode, Node>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<Node, FlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: FlatNode | null = null;

  /** The new displayName's name */
  newItemName = "";

  treeControl: FlatTreeControl<FlatNode>;

  treeFlattener: MatTreeFlattener<Node, FlatNode>;

  dataSource: MatTreeFlatDataSource<Node, FlatNode>;

  metaDataList: Node[];

  /** The selection for checklist */
  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);




  /// Filtering
  myControl = new FormControl();
  options: string[] = ["One", "Two", "Three"];
  filteredOptions: Observable<string[]>;


  @Input() ListData;

  @Input() SelectedData;

  @Input() placeHolder;

  @Input() isPatient;
   
  @Output() onSelectReturn = new EventEmitter<any>();

  @ViewChildren(MatAutocompleteTrigger) autoCompleteTriggers: QueryList<MatAutocompleteTrigger>;

  constructor(private _database: ChecklistDatabase) {

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener,
    );

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {

    this._database.treeData = this.ListData;

    this._database.dataChange.next(this.ListData);

  
    this.treeControl.expandAll();
  

  }


  getLevel = (node: FlatNode) => node.level;

  isExpandable = (node: FlatNode) => node.expandable;

  getChildren = (node: Node): Node[] => node.children;

  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FlatNode) => _nodeData.displayName === "";

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: Node, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.displayName === node.displayName
        ? existingNode
        : new FlatNode();
    flatNode.displayName = node.displayName;
    flatNode.level = level;
    flatNode.value = node.value;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    if (this.SelectedData !== undefined) {

      let selectedNode = this.SelectedData.find(node => node.value === flatNode.value);

      if (selectedNode !== undefined) {

        if(selectedNode.level != 0){

          flatNode.parent = selectedNode.parent;
        }

        this.checklistSelection.select(flatNode);
      }
    }
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  descendantsSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result;
  }

  /** Toggle the to-do displayName selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);

    descendants.every(child => child.parent = node.value);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    this.onSelectReturn.emit(this.checklistSelection.selected);


  }

  /** Toggle a leaf to-do displayName selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {

    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.onSelectReturn.emit(this.checklistSelection.selected);

  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      node.parent = parent.value;
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);

    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    const someDescSelected = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !someDescSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && someDescSelected) {
      this.checklistSelection.select(node);

    }

  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    //console.log(this.checklistSelection.selected);
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getSelectedItems(): string {
    if (!this.checklistSelection.selected.length) return "";

    var str = " ";

    var tempArrayGlobal = [];

    var selectedNode = this.checklistSelection.selected.filter(function (element, index) {
      return (element.level === 0);
    });


    selectedNode.forEach(element => {
      str = element.displayName + ": ";

      const descendants = this.treeControl.getDescendants(element);

      var tempArray = []

      descendants.forEach(child => {
    
        if(this.checklistSelection.isSelected(child)){

          tempArray.push(child);
          //str += child.displayName + ",";

        }

  
      });
      str += tempArray.map(s => s.displayName).join(", ");

      tempArrayGlobal.push({element: str});
      //str += " - "

    });

    str = tempArrayGlobal.map(s => s.element).join(" - ");

    return str;
  }

  filterChanged(filterText: string) {
    console.log("filterChanged", filterText);
    // ChecklistDatabase.filter method which actually filters the tree and gives back a tree structure
    this._database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }


}

