<h1 mat-dialog-title>
  {{ "Add Note" | translate }}
</h1>
<div mat-dialog-content style="padding-top: 2vh">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{ "Note" | translate }}</mat-label>
    <textarea matInput [(ngModel)]="note" maxlength="300"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="start">
  <button mat-raised-button (click)="close()" class="primary">
    {{ "Close" | translate }}
  </button>
  <button mat-raised-button (click)="submit()">
    {{ "Submit" | translate }}
  </button>
</div>