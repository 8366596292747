import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, QueryList, ViewChildren, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { RequestService, LayoutUtilsService, LoaderService } from "../../../shared/services";
import { urlSafeBase64Encoding } from "../../../shared/helpers";
import { FormControl, FormGroupDirective, NgForm, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { ErrorStateMatcher } from "@angular/material/core";
import { TileSelectDialogComponent } from "../../modules/forms-libraries/components/tile-select-dialog/tile-select-dialog.component";
import { CustomSelectDialogComponent } from "../../../shared/components/custom-select-dialog/custom-select-dialog.component";
import { CustomMultipleSelectTreeComponent } from "../../../shared/components/custom-multiple-select-tree/custom-multiple-select-tree.component";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import moment from "moment";
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';


export class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


export interface DialogData {
  dataType: string;
  subDataType: string,
  practitionerType: string;
  dataTypeTitle: string;
  title: string;
  data: any;
  modalSetting: any;
  confirmData: any;
}

@Component({
  selector: "app-dialog-modal",
  templateUrl: "./custom-dialog.component.html",
  styleUrls: ["./custom-dialog.component.scss"],
})
export class ModalDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = "";
  public selectedUser: any;
  public isAdmin: boolean = false;
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = false;
  public metaFieldSetting = undefined;
  public RelativeTo: string = "";
  public excludemetaFieldProfile = ['disclaimer'];
  public allowedExtensions: string[] = ["jpeg", "jpg", "bmp", "png"];
  public minDate = new Date((new Date()).getFullYear() - 90, (new Date()).getDay(), (new Date()).getMonth());
  public maxDate = moment().format("YYYY-MM-DD");
  public passwordForm: FormGroup;


  @ViewChild("customMultipleTree") customMultipleTree: CustomMultipleSelectTreeComponent;
  @ViewChild("dialogWindow") dialogWindow: ElementRef;


  @ViewChildren(MatAutocompleteTrigger) autoCompleteTriggers: QueryList<MatAutocompleteTrigger>;
  public data$: BehaviorSubject<DialogData>;
  constructor(
    private translate: TranslateService,
    private changeDetectorRefs: ChangeDetectorRef,
    private requestService: RequestService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private loaderService: LoaderService,
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data$ = new BehaviorSubject<DialogData>(data);
  }
  ngOnInit() {

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]]
    });

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = true;
          this.buildSetting();
        }
      })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }
  blockSpaceButton(event, colName) {
    if (colName == 'userName') {
      return event.which != 32;
    }
  }

  removeSpacesFromWords(event, colName) {

    if (colName == 'userName') {
      event.target.value = event.target.value.toLowerCase();
      event.target.value = event.target.value.replace(/\s/g, '');
    }
  }


  private buildSetting() {

    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = "";
      this.data.modalSetting.fields = [];

      let orgId = undefined;
      let lang = undefined;

      if (this.data.subDataType) {
        this.requestService.getMetaData(
          this.data.dataType,
          undefined,
          (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(
                this.translate.instant("Error:") + error,
                this.translate.instant("Dismiss")
              );
            }
            this.loading = false;
            this.loaderService.display(false);
            if (data) {
              this.buildcustomSettings(data);

              if (this.data.subDataType == 'practitioner') {
                var removeIndex = this.data.modalSetting.fields.map(function (item) { return item.type; }).indexOf('address');
                this.data.modalSetting.fields.splice(removeIndex, 1);
                if (this.data.dataTypeTitle == 'General Practitioner') {
                  this.removeGeneralPractitionerNotNeededFields();
                }
              }
              if (this.data.data.hasOwnProperty("_id")) {

                if (this.data.subDataType == 'patient' || this.data.subDataType == 'practitioner') {

                  var removeIndex = this.data.modalSetting.fields.map(function (item) { return item.type; }).indexOf('password');

                  this.data.modalSetting.fields.splice(removeIndex, 1);

                }

                this.loadData();

              } else {
                this.data.data = this.getEmptyObject();
                this.data$.next(this.data);

              }
            } else {
              this.layoutUtilsService.showNotification(
                this.translate.instant("Something is Wrong"),
                this.translate.instant("Dismiss")
              );
            }
          },
          orgId, this.data.subDataType, this.data.practitionerType
        );
      } else {
        this.requestService.getMetaData(
          this.data.dataType,
          undefined,
          (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(
                this.translate.instant("Error:") + error,
                this.translate.instant("Dismiss")
              );
            }
            this.loading = false;
            this.loaderService.display(false);
            if (data) {
              this.buildcustomSettings(data);
              if (this.data.data.hasOwnProperty("_id")) {
                this.loadData();
              } else {
                this.data.data = this.getEmptyObject();
                this.data$.next(this.data);
              }
            } else {
              this.layoutUtilsService.showNotification(
                this.translate.instant("Something is Wrong"),
                this.translate.instant("Dismiss")
              );
            }
          },
          orgId
        );
      }
    }
  }
  compare(a: any, b: any): boolean {
    if (typeof a === 'string' && typeof b === 'string') {
      return a === b;
    } else if (typeof a === 'boolean' && typeof b === 'boolean') {
      return a === b;
    } else if (Array.isArray(a) && typeof b === 'string') {
      return a.includes(b);
    } else if (Array.isArray(a) && typeof b === 'boolean') {
      return a.includes(b);
    } else if (Array.isArray(a) && Array.isArray(b)) {
      return a.some((item) => b.includes(item));
    }
    return false;
  }
  buildcustomSettings(data) {
    // TODO: need to enable dynamic Required fields
    const newFields = data.results.fields;
    this.metaFieldSetting = this.buildMetaSetting(data.results);
    let idx = 0;
    if (this.data.modalSetting.customSettings) {
      newFields.forEach(fld => {
        if (this.data.modalSetting.customSettings[fld.name]) {
          const CustomSettingsfield = this.data.modalSetting.customSettings[fld.name];
          if (CustomSettingsfield.hasOwnProperty('visible')) {
            newFields[idx].visible = CustomSettingsfield.visible;
          }
          if (CustomSettingsfield.hasOwnProperty('dependentOn')) {
            const prop = CustomSettingsfield.dependentOn.feature;
            this.metaFieldSetting[fld.name][prop] = false;
            this.data$.subscribe(data => {
              const dependentOnValue = this.data.data[CustomSettingsfield.dependentOn.fieldName];
              // console.log(dependentOnValue);
              if (dependentOnValue && dependentOnValue.length > 0 && dependentOnValue !== "" || (typeof dependentOnValue === 'boolean' && dependentOnValue)) {
                if (((!CustomSettingsfield.dependentOn.validation && CustomSettingsfield.dependentOn.selection.length === 0) || (CustomSettingsfield.dependentOn.validation && this.validateDependOn(CustomSettingsfield.dependentOn.validation, dependentOnValue))) || this.compare(CustomSettingsfield.dependentOn.selection, dependentOnValue)) {

                  this.metaFieldSetting[fld.name][prop] = true;
                } else {
                  this.metaFieldSetting[fld.name][prop] = false;
                }
              } else {
                this.metaFieldSetting[fld.name][prop] = false;
              }
              if (this.metaFieldSetting[fld.name][prop] === false) {
                if (this.metaFieldSetting[fld.name].type === "reference") {
                  this.data.data[fld.name] = this.metaFieldSetting[fld.name].reference.kind === "multiple" ? [] : "";
                } else {
                  this.data.data[fld.name] = "";
                }
              }
            });
          }
        }
        idx++;
      });
    }
    this.data.modalSetting.fields = newFields;
  }
  validateDependOn(validation, dependentOnValue) {
    if (validation.total && validation.operator) {
      if (validation.operator === 'gte') {
        if (dependentOnValue.length >= validation.total) {
          return true;
        }
      } else if (validation.operator === 'gt') {
        if (dependentOnValue.length > validation.total) {
          return true;
        }
      } else if (validation.operator === 'lte') {
        if (dependentOnValue.length < validation.total) {
          return true;
        }
      } else if (validation.operator === 'lt') {
        if (dependentOnValue.length <= validation.total) {
          return true;
        }
      }
    }
    return false;
  }
  addMappingReference(col) {
    let arrayObj = {}
    if (col.fields) {
      for (let subcol of col.fields) {
        if ((subcol.editable || !subcol.generated) && subcol.type !== "action") {
          arrayObj[subcol.name] = "";
        }
      }
    }
    if (this.data.data[col.name]) {
      this.data.data[col.name] = [...this.data.data[col.name], arrayObj];
    }
    else {
      this.data.data[col.name] = [arrayObj];
    }
  }
  removeGeneralPractitionerNotNeededFields() {
    var practitionerObjectIndex = this.data.modalSetting.fields.map(function (item) { return item.name; }).indexOf('practitioner');
    if (practitionerObjectIndex > -1) {
      for (let col of this.excludemetaFieldProfile) {
        var removeIndex = this.data.modalSetting.fields[practitionerObjectIndex].fields.map(function (item) { return item.name; }).indexOf(col);
        if (removeIndex > -1) {
          this.data.modalSetting.fields[practitionerObjectIndex].fields.splice(removeIndex, 1);
        }
      }
    }
  }
  buildMetaSetting(data, parent = undefined) {

    let dataObject = {};
    for (let col of data.fields) {

      if ((col.editable || !col.generated) && col.type !== "object" && col.type !== "table") {
        if (parent) {
          col["inputName"] = parent + col["name"];
        }
        dataObject[col.name] = col;
      } else if (col.type === "object" && col.fields) {
        dataObject[col.name] = this.buildMetaSetting(col);
      } else if (col.type === "table") {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = "";

      let dataId = this.data.data["_id"];
      if (this.data.modalSetting.hasOwnProperty("useOrgId")) {
        dataId = dataId + "/" + this.requestService.orgId;
      }
      if (this.data.dataType == 'address') {
        dataId = this.requestService.currentUser._id + '/' + dataId;
      }

      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(
            this.translate.instant("Error:") + error,
            this.translate.instant("Dismiss")
          );
        }
        if (data) {
          if (this.data.dataType == 'rules') {
            this.RelativeTo = data.results['tileId']['_id'];
            if (!data.results.hasOwnProperty('scoreId')) {
              data.results['scoreId'] = { _id: "", name: "" };
            }
            if (data.results.hasOwnProperty('questions')) {
              if (data.results.questions.length > 0) {
                for (let qCol of data.results.questions) {
                  if (qCol.hasOwnProperty('index'))
                    qCol._id = qCol._id + '_' + qCol.index;
                }
              }
            }
          }
          this.data.data = this.getCleanDataObject(data.results);
        }
        this.data$.next(this.data);
        this.loading = false;
        this.loaderService.display(false);
      }, undefined, this.data.subDataType);
    }
  }
  changeProp(zArray, name, newpopr, value) {
    for (var i in zArray) {
      if (zArray[i].name == name) {
        zArray[i][newpopr] = value;
        break;
      }
    }
  }
  private getCleanDataObject(currentObject) {

    let newObj = currentObject;
    for (let col of this.data.modalSetting.fields) {
      if (col.type === "datetime" && newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = moment.utc(newObj[col.name]).local().format("YYYY-MM-DDTHH:mm");
      }
      if (col.type === "array") {
        if (col.fields) {
          this.changeProp(this.data.modalSetting.fields, col.name, 'displayColumn', col.fields.filter(col => col.visible).map(col => col['name']));
          col.displayColumn.push('action');
        }
      }
    }

    return newObj;
  }
  public getDateAtStamp(dt): string {
    let tempDate = new Date(dt);
    let datePipe = new DatePipe("en-US");
    let setDob = datePipe.transform(tempDate, "yyyy-MM-ddTHH:mm");
    return setDob;
  }

  public setNestedAttribute(id, val) {

    this.data.data[id] = val;

  }


  public setAttribute(id, val, parentId) {

    if (parentId) {
      this.data.data[parentId][id] = val;
    }
    else {
      this.data.data[id] = val;
    }
    this.data$.next(this.data);
  }
  public setAttributeBoolean(id, val, parentId) {
    if (parentId) {
      this.data.data[parentId][id] = JSON.parse(val);
    }
    else {
      this.data.data[id] = JSON.parse(val);
    }
    this.data$.next(this.data);
  }
  public setReferenceAttribute(parentId, id, val) {

    this.data.data[parentId][id] = val;
    if (parentId == 'tileId' && this.data.dataType == 'rules') {
      this.RelativeTo = val;
    }
    this.data$.next(this.data);
  }
  public setMultipleReferenceAttribute(id, val, parentId) {
    if (parentId) {
      this.data.data[parentId][id] = val;

    } else {
      this.data.data[id] = val;
    }
    this.data$.next(this.data);
  }


  public setRoleReferenceAttribute(parentId, val) {
    this.data.data[parentId] = [{ _id: val._id, name: val.name }];
  }

  public setDateAttribute(id, val) {

    try {
      this.data.data[id] = val.toISOString();
    } catch (e) {

    }
  }
  private getCleanObject(data, type) {
    let newObj = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {

      if ((col.editable || !col.generated) && col.type !== "action") {
        if (col.type === "object" && col.fields) {
          newObj[col.name] = data[col.name];
          for (let subcol of col.fields) {
            if ((subcol.editable || !subcol.generated) && subcol.type !== "action") {
              if (subcol.type === "password") {
                newObj[col.name][subcol.name] = urlSafeBase64Encoding(data[col.name][subcol.name]);
              } else if (subcol.type === "reference") {
                if (subcol.reference.kind === "multiple") {
                  if (data[col.name][subcol.name] && data[col.name][subcol.name].length > 0)
                    newObj[col.name][subcol.name] = data[col.name][subcol.name];
                } else {
                  if (data[col.name][subcol.name] !== "") newObj[col.name][subcol.name] = data[col.name][subcol.name];
                }
              } else if (subcol.type === "datetime") {
                newObj[col.name][subcol.name] = moment(data[col.name][subcol.name]).utc().format("YYYY-MM-DDTHH:mm");
              } else {
                newObj[col.name][subcol.name] = data[col.name][subcol.name];
              }

            }

          }
        } else if (col.type === "array") {
          newObj[col.name] = data[col.name];
          if (!newObj[col.name]) {
            newObj[col.name] = [];
          }
          console.log(newObj[col.name])
          let arrayObj = {}
          if (col.name == 'address' && this.data.subDataType == 'patient') {
            arrayObj["_id"] = data['address'][0]['_id'];
          }
          if (col.fields) {
            for (let subcol of col.fields) {
              console.log(subcol.name);
              if ((subcol.editable || !subcol.generated) && subcol.type !== "action") {
                if (data[col.name]) {
                  if (data[col.name][0]) {
                    arrayObj[subcol.name] = data[col.name][0][subcol.name];
                  } else {
                    arrayObj[subcol.name] = "";
                  }
                } else {
                  arrayObj[subcol.name] = "";
                }
              }
            }
            console.log(arrayObj);
          }
          newObj[col.name][0] = arrayObj;
        }
        else {
          if (col.type === "password") {
            newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
          } else if (col.type === "reference") {
            if (col.reference.kind === "multiple") {
              if (data[col.name] && data[col.name].length > 0) {
                if (col.name === "questions" || col.name === "questions_2") {
                  let newValuesWithIndex = [];
                  for (let qCol of data[col.name]) {
                    if (qCol.hasOwnProperty('index')) {
                      let splittedValues = qCol._id.split('_')[0];
                      newValuesWithIndex.push({ _id: splittedValues, index: qCol.index });
                    } else {
                      newValuesWithIndex.push({ _id: qCol._id });
                    }
                  }
                  newObj[col.name] = newValuesWithIndex;
                } else {
                  newObj[col.name] = data[col.name];
                }
              }
            } else {
              if (data[col.name] !== "") newObj[col.name] = data[col.name];
            }
          } else if (col.type === "datetime") {
            newObj[col.name] = moment(data[col.name]).utc().format("YYYY-MM-DDTHH:mm");
          }
          else {
            if (col.name == "userName") {

              var lowerCaseUserName = (data[col.name]).toLowerCase();
              lowerCaseUserName = lowerCaseUserName.replace(/\s/g, '');
              newObj[col.name] = lowerCaseUserName;

            }
            else {
              newObj[col.name] = data[col.name];
            }

          }
        }
      }
    }

    return newObj;
  }
  private validateObject(data: any) {
    for (let col of this.data.modalSetting.fields) {
      if (
        !col.nullable &&
        !col.generated &&
        col.type !== "action" &&
        col.name !== "privacyterm" &&
        (col.visible || (col.admin && this.isAdmin))
      ) {

        if (col.type === "reference") {
          if (col.reference.kind === "multiple") {

            if (col.name === "resources") {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
              if (data[col.name][0]["_id"] === "") {
                return false;
              }
            } else {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
            }
          } else {
            if (data && data[col.name] && data[col.name]["_id"] === "") {

              return false;
            }
          }
        } else if (col.type === "tags") {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {

            return false;
          }
        } else if (col.type === "email") {
          if (
            data &&
            (data[col.name].length === 0 ||
              data[col.name] === undefined ||
              !this.isEmailValid(data[col.name]))
          ) {

            return false;
          }
        } else if (col.type === "url") {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {

            return false;
          }
        }

        else {
          if (data && (data[col.name] === "" || data[col.name] === undefined || data[col.name] === null)) {

            return false;
          }
        }

      } else
        if (col.type === "object" && col.fields) {
          for (let subCol of col.fields) {
            if (
              !subCol.nullable &&
              !subCol.generated &&
              subCol.type !== "action" &&
              subCol.name !== "privacyterm" &&
              (subCol.visible || (subCol.admin && this.isAdmin))
            ) {
              if (data && (data[col.name][subCol.name] === "" || data[col.name][subCol.name] === undefined || data[col.name][subCol.name] === null)) {

                return false;

              }
            }
          }
        }
    }
    return true;
  }
  private validateLogic(data) {

    for (let col of this.data.modalSetting.fields) {
      if (
        !col.nullable &&
        !col.generated &&
        col.type !== "action" &&
        col.name !== "privacyterm" &&
        (col.visible || (col.admin && this.isAdmin))
      ) {

        if (col.hasOwnProperty('charlimit')) {
          if (data && data[col.name] && data[col.name].length > col.charlimit) {
            return this.translate.instant(col.displayName + ' should have a maximum characters of ' + col.charlimit);
          }
        }
        if (col.type === "password" && this.data.subDataType === "patient") {

          let form = this.passwordForm;
          let controls = form.controls;
          controls.password.markAsDirty();

          this.passwordForm.markAllAsTouched();

          if (!this.passwordForm.valid) {

            if (this.passwordForm.controls.password.errors.required) {

              return "Please fill in the required fields";

            }
            else if (this.passwordForm.controls.password.errors?.pattern) {

              return "The password you entered does not meet the minimum security requirements.";

            }
            else {

              return;

            }
          }

        }

        if (col.hasOwnProperty("validation")) {
          for (let vald of col.validation) {

            if (vald.hasOwnProperty("target")) {
              if (vald.operator === "lt") {
                if (col.type === "datetime" || col.type === "date") {
                  if (
                    !(
                      data[col.name] &&
                      data[vald.target] &&
                      moment(data[vald.target]).diff(moment(data[col.name]), "minutes") > 0
                    )
                  ) {
                    return (
                      col.displayName +
                      " should be less than " +
                      this.metaFieldSetting[vald.target]["displayName"]
                    );
                  }
                } else {
                  if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                    return (
                      col.displayName +
                      " should be less than " +
                      this.metaFieldSetting[vald.target]["displayName"]
                    );
                  }
                }
              } else if (vald.operator === "gt") {
                if (col.type === "datetime" || col.type === "date") {
                  if (
                    !(
                      data[col.name] &&
                      data[vald.target] &&
                      moment(data[col.name]).diff(moment(data[vald.target]), "minutes") > 0
                    )
                  ) {
                    return (
                      col.displayName +
                      " should be greater than " +
                      this.metaFieldSetting[vald.target]["displayName"]
                    );
                  }
                } else {
                  if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                    return (
                      col.displayName +
                      " should be greater than " +
                      this.metaFieldSetting[vald.target]["displayName"]
                    );
                  }
                }
              } else if (vald.operator === "url") {
                if (!this.isUrlValid(data[col.name])) {
                  return col.displayName + " should url format.";
                }
              }
            }
            else if (vald.hasOwnProperty("allowFutureDate")) {
              if (vald.operator === "lt" && vald.allowFutureDate === false) {
                if (col.type === "date") {
                  if (
                    !(
                      data[col.name] &&
                      moment().diff(moment(data[col.name]), "minutes") > 0
                    )
                  ) {
                    return (
                      "Please fill in the required fields"
                    );
                  }

                }

              }

            }
          }
        }
      }

    }
    return undefined;
  }

  public closePanels() {
    if (this.customMultipleTree) {
      if (this.customMultipleTree.autoCompleteTriggers) {
        this.customMultipleTree.autoCompleteTriggers.forEach(trigger => {
          if (trigger.panelOpen) {
            this.dialogWindow.nativeElement.focus();
            trigger.closePanel();
          }
        });
      }
    }

  }
  public saveData(type) {
    if (this.data.modalSetting.target === "self") {
      if (!this.loading) {
        let validateLogic = this.validateLogic(this.data.data);
        if (!validateLogic) {
          let validateObj = this.validateObject(this.data.data)
          this.isSubmitted = true;
          if (validateObj) {
            this.loading = true;
            this.loaderService.display(true);
            let useOrgId = false;
            if (this.data.modalSetting.hasOwnProperty("useOrgId")) {
              useOrgId = this.data.modalSetting["useOrgId"];
            }
            this.errorMessage = "";
            this.requestService.saveData(
              this.data.dataType,
              this.getCleanObject(this.data.data, type),
              (data, error) => {
                if (error) {
                  this.errorMessage = error;
                  this.layoutUtilsService.showNotification(
                    this.translate.instant("Error: ") + error,
                    this.translate.instant("Dismiss")
                  );
                }
                if (data) {
                  if (type === "create") {
                    this.layoutUtilsService.showNotification(
                      this.data.dataTypeTitle +
                      " " +
                      this.translate.instant("created Successfully"),
                      this.translate.instant("Dismiss")
                    );
                  } else if (type === "edit") {
                    this.layoutUtilsService.showNotification(
                      this.data.dataTypeTitle + " " + this.translate.instant("edited Successfully"),
                      this.translate.instant("Dismiss")
                    );
                  }
                  this.closeModal({ action: "refresh" });
                }
                this.loading = false;
                this.loaderService.display(false);
              }, ''
            );
          } else {
            this.layoutUtilsService.showNotification(
              this.translate.instant("Please fill all the required fields"),
              this.translate.instant("Dismiss")
            );
          }
        } else {
          this.layoutUtilsService.showNotification(
            this.translate.instant(validateLogic),
            this.translate.instant("Dismiss")
          );
        }
      }
    } else if (this.data.modalSetting.target === "parent") {
      let validateLogic = this.validateLogic(this.data.data);
      if (!validateLogic) {
        if (this.validateObject(this.data.data)) {
          this.closeModal({
            action: type,
            dataType: this.data.dataType,
            dataTypeTitle: this.data.dataTypeTitle,
            modalSetting: this.data.modalSetting,
            data: this.getCleanObject(this.data.data, type),
          });
        } else {
          this.layoutUtilsService.showNotification(
            this.translate.instant("Error: ") +
            this.translate.instant("You need to select all mandatory fields"),
            this.translate.instant("Dismiss")
          );
        }
      } else {
        this.layoutUtilsService.showNotification(
          this.translate.instant("Error: ") + this.translate.instant(validateLogic),
          this.translate.instant("Dismiss")
        );
      }
    }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  public toggleClick(action, target, data) {
    if (target === "parent") {
      if (this.validateObject(this.data.data)) {
        this.closeModal({ action: action, data: this.data.data, value: data });
      } else {
        this.layoutUtilsService.showNotification(
          this.translate.instant("Error:") +
          this.translate.instant("You need to select all mandatory fields"),
          this.translate.instant("Dismiss")
        );
      }
    } else {

      if (action === "close") {
        this.closeModal(undefined);
      }
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if (col.name == 'userId' && this.data.dataType == 'assessment') {
        col.subDataType = 'patient';
      }
      if ((col.editable || !col.generated) && col.type !== "action") {
        if (col.type === "object" && col.fields) {
          newObj[col.name] = {};
          for (let subcol of col.fields) {
            if ((subcol.editable || !subcol.generated) && subcol.type !== "action") {
              if (subcol.type === "reference") {
                if (subcol.reference.kind === "multiple") {
                  if (subcol.name === "resources") {
                    newObj[col.name][subcol.name] = [{ _id: "", name: "" }];
                  } else {
                    newObj[col.name][subcol.name] = [];
                  }
                } else {
                  newObj[col.name][subcol.name] = { _id: "", name: "" };
                }
              } else if (subcol.type === "boolean") {
                newObj[col.name][subcol.name] = false;
              } else if (subcol.type === "color") {
                newObj[col.name][subcol.name] = "#ffffff";
              } else if (subcol.type === "picturearray") {
                newObj[col.name][subcol.name] = [];
              } else if (subcol.type === "datetime") {
                newObj[col.name][subcol.name] = moment.utc().format("YYYY-MM-DDTHH:mm");
              } else if (subcol.type === "enum" && subcol.multiple === true) {
                newObj[col.name][subcol.name] = [];
              }
              else if (subcol.type === "tree") {
                newObj[col.name][subcol.name] = [];
              }
              else {

                newObj[col.name][subcol.name] = "";
              }

            }

            if (this.data.modalSetting.hasOwnProperty("customSettings")) {
              if (this.data.modalSetting.customSettings.hasOwnProperty(subcol.name)) {
                newObj[col.name][subcol.name] = this.data.modalSetting.customSettings[subcol.name].value;
              }
            }


          }
        }
        else if (col.type === "array") {

          newObj[col.name] = [];
          let arrayObj = {}
          if (col.fields) {
            for (let subcol of col.fields) {
              if ((subcol.editable || !subcol.generated) && subcol.type !== "action") {

                arrayObj[subcol.name] = "";
              }
            }

            col['displayColumn'] = col.fields.filter(col => col.visible).map(col => col['name'])
            col.displayColumn.push('action');
          }
          newObj[col.name][0] = arrayObj;

        }
        else if (col.type === "reference") {
          if (col.reference.kind === "multiple") {
            if (col.name === "resources") {
              newObj[col.name] = [{ _id: "", name: "" }];
            } else {
              newObj[col.name] = [];
            }
          } else {
            newObj[col.name] = { _id: "", name: "" };
          }
        } else if (col.type === "boolean") {
          newObj[col.name] = false;
        } else if (col.type === "number") {
          newObj[col.name] = 0;
        } else if (col.type === "color") {
          newObj[col.name] = "#ffffff";
        } else if (col.type === "picturearray") {
          newObj[col.name] = [];
        } else if (col.type === "datetime") {
          newObj[col.name] = moment.utc().format("YYYY-MM-DDTHH:mm");
        } else {
          newObj[col.name] = "";
        }
        if (this.data.modalSetting.hasOwnProperty("customSettings")) {
          if (col.type !== "object") {
            if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
              newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
            }
          }
        }
      }
    }

    return newObj;
  }
  private isUrlValid(url) {
    var re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
    return re.test(String(url).toLowerCase());
  }
  private isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  public addPictures(id, url) {
    if (this.data.data.hasOwnProperty(id)) {
      this.data.data[id].push({ url: url, type: "link", link: "" });
    } else {
      this.data.data[id] = [{ url: url, type: "link", link: "" }];
    }
  }
  public setPictureArrayType(idx, id, val) {
    this.data.data[id][idx]["type"] = val;
    this.data.data[id][idx]["link"] = "";
  }
  public selectPictureLinkType(idx, id, type) {
    if (type === "tile") {
      this.editTile(idx, id);
    } else if (type === "room") {
      this.editCustomSelectDialog("room", "Room", idx, id);
    }
  }
  editTile(idx, id) {
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: "600px",
      data: {
        title: this.translate.instant("Select") + " " + this.translate.instant("Tile"),
        data: {}
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.hasOwnProperty("_id")) {
          this.data.data[id][idx]["link"] = result["_id"];
        } else {
          this.data.data[id][idx]["link"] = "";
        }
      }
    });
  }
  editCustomSelectDialog(dataType, dataTypeTitle, idx, id) {
    const dialogRef = this.dialog.open(CustomSelectDialogComponent, {
      width: "600px",
      data: {
        title: this.translate.instant("Select") + " " + this.translate.instant("Billboard"),
        dataType: dataType,
        dataTypeTitle: dataTypeTitle,
        data: this.data.data[id][idx]["link"],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.hasOwnProperty("_id")) {
          this.data.data[id][idx]["link"] = result["_id"];
        } else {
          this.data.data[id][idx]["link"] = "";
        }
      }
    });
  }
  public deletePictures(id, index) {
    this.data.data[id].splice(index, 1);
  }
  /**
   *  @param target: trigger event
   *
   *  trigger read files browsed files
   */
  onBrowseFiles(id, target: any): void {
    this.readFiles(id, target.files);
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readFiles(id, files, index = 0): void {
    if (index in files) {
      let currentFile = {
        error: false,
        text: files[index].name,
        id: files[index].id,
        originalFile: files[index],
        source_url: null,
      };
      let fileExt = files[index].name.split(".").pop();
      const max_size = 5000000;
      if (files[index].size > max_size) {
        this.layoutUtilsService.showNotification(
          this.translate.instant("Maximum size allowed is") + " " + max_size / 1000000 + "Mb",
          this.translate.instant("Dismiss")
        );
      } else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
        currentFile.error = true;
      }
    } else {
      this.changeDetectorRefs.detectChanges();
    }
  }
  readFile(file, reader, callback): void {
    reader.onload = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }
  sliderChanged(id, val) {
    this.data.data[id] = val;
  }
  refreshData() {
    console.log("refreshData");
    this.data$.next(this.data);
  }
  deleteRow(colName, indexRow) {
    if (this.data.data[colName])
      this.data.data[colName].splice(indexRow, 1);
    this.data.data[colName] = [...this.data.data[colName]];
  }
}
