<div class='ili-panel start_wrapper_panel'>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex="75">
      <mat-form-field>
        <input matInput placeholder="Title" max="100" maxlength="100" [(ngModel)]="block.data.title" type="text"
          (change)="onChange($event)">
        <mat-hint align="end">{{block.data.title.length}} / 100</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex="25">
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
        <div fxFlex="60">
          <mat-form-field>
            <input matInput max="10" placeholder="Color" [(ngModel)]="block.data.color" type="text"
              (change)="onChange($event)">
          </mat-form-field>
        </div>
        <div fxFlex="40" style="display: flex;">
          <input type="color" style="height: 70%;" placeholder="Color" [(ngModel)]="block.data.color"
            (change)="onChange($event)">
        </div>
      </div>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-checkbox value="true" [checked]="block.data.refresh" [(ngModel)]="block.data.refresh"
        (change)="onChange($event)"
        title="Check this box if this form should be cleared every time the end-user enters this tile.">Clear Fields
        after each submit</mat-checkbox>
    </div>
    <div fxFlex>
      <mat-checkbox value="true" [checked]="block.data.close" [(ngModel)]="block.data.close" (change)="onChange($event)"
        title="Check this box if this form should be cleared every time the end-user enters this tile and it will close the form.">
        Clear and Close the Form</mat-checkbox>
    </div>
    <div fxFlex>
      <mat-checkbox value="true" [checked]="block.data.formCollapse" [(ngModel)]="block.data.formCollapse"
        (change)="onChange($event)">Collapse</mat-checkbox>
    </div>
  </div>
</div>