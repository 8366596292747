<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="form-group" [formGroup]="passwordForm">
			<mat-form-field style="margin-bottom: 5px;" *ngIf="!toVerify">
				<input matInput autocomplete="off" [type]="showOldPassword ? 'text' : 'password'" name="oldpassword"
					formControlName="oldpassword" placeholder="{{'Old Password' | translate}}">
				<button matSuffix mat-icon-button (click)="showOldPassword = !showOldPassword">
					<mat-icon *ngIf="showOldPassword && passwordForm.controls.oldpassword.value.length"
						class="password-eye">
						visibility</mat-icon>
					<mat-icon *ngIf="!showOldPassword && passwordForm.controls.oldpassword.value.length"
						class="password-eye">
						visibility_off</mat-icon>
				</button>
				<mat-error *ngIf="passwordForm.controls.oldpassword.errors?.incorrect">
					The password you entered is incorrect.
				</mat-error>
			</mat-form-field>
			<mat-form-field style="margin-bottom: 5px;" *ngIf="toVerify">
				<input matInput autocomplete="off" type="text" name="oldpassword" formControlName="oldpassword"
					placeholder="{{'Verification Code' | translate}}">
				<mat-error *ngIf="passwordForm.controls.oldpassword.errors?.incorrect">
					The password you entered is incorrect.
				</mat-error>
			</mat-form-field>
			<mat-form-field style="margin-bottom: 65px;">
				<input matInput autocomplete="off" [type]="showNewPassword ? 'text' : 'password'" name="password"
					formControlName="password" placeholder="{{'New Password' | translate}}">
				<button matSuffix mat-icon-button (click)="showNewPassword = !showNewPassword">
					<mat-icon *ngIf="showNewPassword && passwordForm.controls.password.value.length"
						class="password-eye">
						visibility</mat-icon>
					<mat-icon *ngIf="!showNewPassword && passwordForm.controls.password.value.length"
						class="password-eye">
						visibility_off</mat-icon>
				</button>
				<mat-error
					*ngIf="passwordForm.controls.password.errors?.pattern && !(passwordForm.controls.password.errors?.hasOwnProperty('same') && passwordForm.controls.password.errors.same !== null)">
					The password you entered does not meet the minimum security requirements.
				</mat-error>
				<mat-error
					*ngIf="passwordForm.controls.password.errors?.hasOwnProperty('same') && passwordForm.controls.password.errors.same !== null">
					The password you entered is the same as the old password. Please enter a new password.
				</mat-error>
				<mat-hint>Your password must contain at least 8 characters including lower and upper case letters,
					numbers, and special characters.</mat-hint>
			</mat-form-field>
			<mat-form-field>
				<input matInput autocomplete="off" [type]="showConfirmPassword ? 'text' : 'password'"
					name="confirmpassword" formControlName="confirmpassword"
					placeholder="{{'Confirm Password' | translate}}">
				<button matSuffix mat-icon-button (click)="showConfirmPassword = !showConfirmPassword">
					<mat-icon *ngIf="showConfirmPassword && passwordForm.controls.confirmpassword.value.length"
						class="password-eye">
						visibility</mat-icon>
					<mat-icon *ngIf="!showConfirmPassword && passwordForm.controls.confirmpassword.value.length"
						class="password-eye">
						visibility_off</mat-icon>
				</button>
				<mat-error>
					The passwords you entered do not match.
				</mat-error>
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button mat-raised-button color="primary" [disabled]="loading || passwordForm.controls.password.errors?.pattern"
			(click)="saveData()" cdkFocusInitial>{{'Save' |
			translate}}</button>
	</div>
	<br />
</div>